@import "../../../shared/styles/styles.scss";

.authorized-create-edit {
    &__content {
        height: 100%;
        padding: 35px;
    }

    &__box{
        margin-top: 30px;
    }

    &__data-personal{
        @include global-box-shadow(0px, 3px, 20px);
        background: $color-white;
        margin-left: 20px;
        border-radius: 10px;
        height: 332px;
        padding: 20px;

        &__label {
            margin-bottom: 4px;
            @include global-typography($color-astronaut, bold, 12px);
        }

        &__input {

            &.ant-input {
                margin: 0;
                width: 100%;
                height: 30px;
                line-height: 0;
                box-shadow: none;
                border-radius: 5px;
                background: $color-white;
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, normal, 14px);

                &::placeholder {
                    @include global-typography($color-astronaut, normal, 14px);
                }

                &:focus {
                    box-shadow: none;
                    border: 1px solid $color-astronaut;
                }

                &.ant-input-disabled {
                    border: 1px solid $color-mercury;
                    @include global-typography($color-mercury, bold, 10px);

                    &::placeholder {
                        color: $color-mercury
                    }
                }
            }

            &--mod{
                &.ant-input {
                    width: 70%;
                }
            }
        }

        &__content{
            width: 100%;
            height: 25%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &--mod{
                width: 48%;
            }
        }

        .input-price {
            width: 100%;
            margin: 0px;
            height: 30px;
            box-shadow: none;
            border-radius: 5px;
            background: $white;
            border: 1px solid $color-astronaut;
            @include global-typography($color-astronaut, normal, 14px);

            &:focus {
                box-shadow: none;
                border: 1px solid $color-astronaut;
            }

            &::placeholder {
                @include global-typography($color-astronaut, normal, 14px);
            }

            &:disabled {
                background: $white;
                color: $color-mercury;
                border: 1px solid $color-mercury;

                &::placeholder {
                    color: $color-mercury
                }
            }
        }

        .input-price-error {
            @extend .input-price;
            border: 1px solid $alert-colors;

            &:focus {
                box-shadow: none;
                border: 1px solid $alert-colors;
            }
        }

        &__error {
            height: 14px;
            font-size: 10px;
            text-align: end;
            color: $color-carnation;
        }
    }

    &__title{
        @include global-typography($color-astronaut, bold, 16px);
    }

    &__cancel{
        @include global-button-secondary(34px, 150px);
        margin-right: 20px;
    }

    &__save{
        @include global-button-primary(34px, 150px);
    }

    &__buttons{
        margin-top: 20px;
    }

    &__select {

        &.ant-select {
            width: 25%;
            height: 30px;

            &>.ant-select-selector {
                height: 30px;
                background: $color-white;
                border: 1px solid $color-astronaut;

                &>.ant-select-selection-item {
                    @include global-typography($color-astronaut, normal, 14px);
                }

                &>.ant-select-selection-placeholder {
                    @include global-typography($color-astronaut, normal, 14px);
                }
            }

            &>.ant-select-arrow {
                inset-inline-end: 0px;
            }

            &.ant-select-disabled {

                &>.ant-select-selector {
                    height: 30px;
                    background: $color-white;
                    border: 1px solid $color-mercury;

                    &>.ant-select-selection-item {
                        @include global-typography($color-mercury, bold, 10px);
                    }

                    &>.ant-select-selection-placeholder {
                        @include global-typography($color-mercury, normal, 14px);
                    }
                }


                &>.ant-select-arrow {
                    inset-inline-end: 0px;
                }
            }
        }

        &__icon {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &__popup {
            .ant-select-item-option-content {
                @include global-typography($color-astronaut, normal, 14px);
            }
        }
    }
    
}