@import "../../../../../../../shared/styles/styles.scss";

.preview-headquarter {
    &__content {
        @include global-box-shadow(0px, 3px, 20px);
        padding: 32px 20px 25px 20px;
        border-radius: 10px;
    }

    &__title{
        margin-left: 15px;
        @include global-typography($color-astronaut, bold, 14px);
        
        &--subtitle{
            @include global-typography($color-mischka, bold, 14px);
        }
    }

    &__line{
        width: 100%;
        height: 1px;
        background: $color-mischka;
        margin: 20px 0px;
    }

    &__edit{
        @include global-box-shadow(0px, 0px, 10px);
        border-radius: 6px;
        width: 100px;
        height: 40px;
        margin-left: 15px;

        &__text{
            @include global-typography($color-astronaut, bold, 10px);
        }

        &__icon{
            height: 20px;
            width: 20px;
            margin-right: 5px;
        }
    }

    &__text{
        margin-left: 15px;
        @include global-typography($color-astronaut, normal, 14px);

        &--check{
            margin-left: 0px;
        }

        &__disabled{
            color: $color-mischka;
        }
    }

    &__container{
        margin-bottom: 10px;
    }

    &__checkbox {
        margin-right: -10px;
        margin-left: 15px;
        &>.ant-checkbox-wrapper,
        &>.ant-checkbox-wrapper:hover {
            &>.ant-checkbox {
                &>.ant-checkbox-inner {
                    border: 1px solid $primary-colors;
                }

                &.ant-checkbox-disabled{
                    &>.ant-checkbox-inner {
                        border: 0px;
                    }
                }

                &.ant-checkbox-checked {
                    &>span.ant-checkbox-inner {
                        background: $color-dodger-blue;
                        border: 1px solid $color-dodger-blue;
                    }

                    &::after {
                        border-color: transparent;
                    }
                }
            }
        }
    }
}