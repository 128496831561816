@import '../../../shared/styles/styles.scss';

.banks-detail {

    &__container {
        padding: 35px;
    }

    &__title {
        @include global-typography($color-dodger-blue, bold, 14px);
    }

    &__subtitle {
        @include global-typography($color-astronaut, bold, 14px);
    }

    &__icon-arrow {
        margin: 0 10px;
    }

    &__container-box {
        margin-top: 50px;
    }

    &__first-box {
        width: 28%;
        height: 210px;
        padding: 20px 30px;
        margin-right: 30px;
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);

        &__container-title {
            height: 20%;
            width: 100%;
            border-bottom: 1px solid $color-mercury;
        }

        &__container-info {
            height: 80%;
            width: 100%;
        }

        &__title {
            @include global-typography($color-astronaut, bold, 16px);
        }

        &__subtitle {
            @include global-typography($color-astronaut, normal, 12px);
        }

        &__description {
            @include global-typography($color-astronaut, bold, 12px);
        }

        &__content-description {
            height: 20%;
            width: 100%;
        }

    }

    &__content-second-box {
        width: 50%;
    }

    &__second-box {
        width: 100%;
        height: 360px;
        @extend .banks-detail__first-box;

        &__container-title {
            height: 10%;
            width: 100%;
        }

        &__container-info {
            height: 90%;
            width: 100%;
        }

        &__title {
            @include global-typography($color-astronaut, bold, 16px);
        }

        &__content-label {
            width: 48%;
            height: 25%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &__title {
                margin-bottom: 4px;
                @include global-typography($color-astronaut, bold, 12px);
            }

            &__select {

                &.ant-select {
                    width: 100%;
                    height: 30px;

                    &>.ant-select-selector {
                        height: 30px;
                        background: $color-white;
                        border: 1px solid $color-astronaut;

                        &>.ant-select-selection-item {
                            @include global-typography($color-astronaut, bold, 10px);
                        }

                        &>.ant-select-selection-placeholder {
                            @include global-typography($color-astronaut, normal, 14px);
                        }
                    }

                    &>.ant-select-arrow {
                        inset-inline-end: 0px;
                    }

                    &.ant-select-disabled {

                        &>.ant-select-selector {
                            height: 30px;
                            background: $color-white;
                            border: 1px solid $color-mercury;

                            &>.ant-select-selection-item {
                                @include global-typography($color-mercury, bold, 10px);
                            }

                            &>.ant-select-selection-placeholder {
                                @include global-typography($color-mercury, normal, 14px);
                            }
                        }


                        &>.ant-select-arrow {
                            inset-inline-end: 0px;
                        }
                    }
                }

                &__icon {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }

                &__popup {
                    .ant-select-item-option-content {
                        font-size: 10px;
                        font-weight: bold;
                        color: $color-astronaut;
                    }
                }
            }

            &__input {

                &.ant-input {
                    margin: 0;
                    width: 100%;
                    height: 30px;
                    line-height: 0;
                    box-shadow: none;
                    border-radius: 5px;
                    background: $color-white;
                    border: 1px solid $color-astronaut;
                    @include global-typography($color-astronaut, bold, 10px);

                    &::placeholder {
                        @include global-typography($color-astronaut, normal, 14px);
                    }

                    &:focus {
                        box-shadow: none;
                        border: 1px solid $color-astronaut;
                    }

                    &.ant-input-disabled {
                        border: 1px solid $color-mercury;
                        @include global-typography($color-mercury, bold, 10px);

                        &::placeholder {
                            color: $color-mercury
                        }
                    }
                }
            }

            .input-price {
                width: 100%;
                margin: 0px;
                height: 30px;
                box-shadow: none;
                border-radius: 5px;
                background: $white;
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, bold, 10px);

                &:focus {
                    box-shadow: none;
                    border: 1px solid $color-astronaut;
                }

                &::placeholder {
                    @include global-typography($color-astronaut, normal, 14px);
                }

                &:disabled {
                    background: $white;
                    color: $color-mercury;
                    border: 1px solid $color-mercury;

                    &::placeholder {
                        color: $color-mercury
                    }
                }
            }

            .input-price-error {
                @extend .input-price;
                border: 1px solid $alert-colors;

                &:focus {
                    box-shadow: none;
                    border: 1px solid $alert-colors;
                }
            }

            &__content-picker {
                width: 100%;
                height: 30px;
                position: relative;

                &__content-icon {
                    z-index: 1;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    position: absolute;
                    align-items: center;
                    justify-content: center;
                }

                &__picker {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    height: 100%;
                    background: $white;
                    border-radius: 5px;
                    position: relative;
                    border: 1px solid $color-astronaut;

                    &>.ant-picker-input {
                        &>input {
                            height: 20px;
                            cursor: pointer;
                            padding: 0 10px 0 32px;
                            @include global-typography($color-astronaut, bold, 10px);

                            &::placeholder {
                                @include global-typography($color-astronaut, normal, 14px);
                            }
                        }
                    }

                    &.ant-picker-disabled {
                        background: $white;

                        &>.ant-picker-input {
                            &>input {
                                &::placeholder {
                                    color: $color-mercury
                                }
                            }
                        }
                    }
                }
            }

            &__error {
                height: 14px;
                font-size: 10px;
                text-align: end;
                color: $alert-colors;
            }
        }
    }

    &__content-buttons {
        margin-top: 30px;
    }

    &__button {

        &__cancel {
            @include global-button-secondary(34px, 150px);
        }

        &__save {
            margin-left: 20px;
            @include global-button-primary(34px, 150px);

            &:disabled {
                color: $white
            }
        }
    }
}