@import "../../../../../../shared/styles/styles.scss";

.file-upload-util {

    &__container {
        width: 100%;
        height: 130px;
    }

    &__box {
        width: 100%;
        height: 85%;
        border-radius: 5px;
        border: 1px dashed $color-astronaut;

        &__active {
            width: 100%;
            height: 85%;
            border: none;
            display: flex;
            border-radius: 5px;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background: $color-white;
            @include global-box-shadow(0px, 3px, 20px);
        }

        &__visibility {
            display: none;
        }

        &__upload {
            &.ant-upload-wrapper {
                width: 100%;
                height: 100%;

                &>.ant-upload.ant-upload-select {
                    width: 100%;
                    height: 100%;

                    &>.ant-upload {
                        &>button {
                            width: 100%;
                            height: 100%;
                            border: none;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        &__content-text {
            width: 75%;
        }

        &__title {
            text-align: left;
            line-height: 18px;
            overflow: hidden;
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            @include global-typography($color-astronaut, bold, 16px);
        }

        &__text {
            margin-top: 4px;
            text-align: left;
            line-height: 14px;
            @include global-typography($color-astronaut, normal, 12px);
        }

        &__info {
            width: 85%;
            height: 60%;
        }

        &__name-file {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            @include global-typography($color-astronaut, bold, 14px);

            &--mod {
                color: $color-dodger-blue;
                @extend .file-upload-util__box__name-file;
            }
        }

        &__size-file {
            @include global-typography($color-mischka, bold, 10px);
        }

        &__progress {
            &>.ant-progress-inner {
                &>.ant-progress-text {
                    @include global-typography($color-astronaut, bold, 12px);
                }
            }
        }

        &__content-cancel {
            width: 85%;
            height: 30%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            border-top: 1px solid $color-mischka;

            &__button {
                @include global-typography($color-carnation, bold, 12px);
            }
        }
    }

    &__box-disabled {
        width: 100%;
        height: 85%;
        border-radius: 5px;
        border: 1px dashed $color-mischka;

        &__upload {
            &.ant-upload-wrapper {
                width: 100%;
                height: 100%;

                &>.ant-upload.ant-upload-select {
                    width: 100%;
                    height: 100%;

                    &>.ant-upload {
                        &>button {
                            width: 100%;
                            height: 100%;
                            border: none;
                            background: $color-white;
                        }
                    }
                }
            }
        }

        &__title {
            text-align: left;
            line-height: 18px;
            overflow: hidden;
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            @include global-typography($color-mischka, bold, 16px);
        }

        &__text {
            margin-top: 4px;
            text-align: left;
            line-height: 14px;
            @include global-typography($color-mischka, normal, 12px);
        }
    }
}