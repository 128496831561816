@import "../../../shared/styles/styles.scss";

.admin-panel {

    &__container {
        padding: 35px;
    }

    &__content-options {
        width: 100%;
        height: 60px;
        margin-top: 14px;

        &__content-buttons {
            height: 100%;
            padding: 0 20px;
            border-radius: 5px;
            background: $color-athens-gray;
        }

        &__buttons {
            margin: 0 10px;
            width: 160px;
            height: 40px;

            &__active {
                border-radius: 5px;
                background: $color-white;
                @include global-box-shadow(0px, 3px, 20px);
                @extend .admin-panel__content-options__buttons;
            }

            &__text {

                &__active {
                    @include global-typography($color-astronaut, bold, 12px);
                }

                &__inactive {
                    @include global-typography($color-mischka, bold, 12px);
                }

                &__hover {
                    @include global-typography($color-dodger-blue-hover, bold, 12px);
                }
            }
        }
        &__icon-button {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }

    }

    &__content-info {
        width: 100%;
        margin-top: 10px;
    }
}