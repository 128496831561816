@import "../../../../shared/styles/styles.scss";

.cash-closing-dashboard {

    &__container {
        width: 95%;
        height: 100%;
        border-radius: 10px;
        padding: 30px 20px 10px 20px;
        @include global-box-shadow(0px, 3px, 20px);
    }

    &__title {
        @include global-typography($color-astronaut, bold, 16px);
    }

    &__description {
        margin-bottom: 12px;
        @include global-typography($color-astronaut, normal, 12px);
    }

    &__date {
        width: 100%;
        height: 36px;
        padding: 0px 16px;
        border-radius: 5px;
        margin-bottom: 12px;
        background: $color-white;
        border: 1px solid $color-mercury;
    }

    &__arrow {
        transform: rotate(180deg);
    }

    &__month {
        width: 78%;
        &>.ant-picker-input {

            &>input {
                @include global-typography($primary-colors, bold, 12px);
                display: flex;
                cursor: pointer;
                overflow: hidden;
                text-align: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-transform: capitalize;
            }
        }

        &__popup {
            .ant-picker-cell {

                &>div.ant-picker-cell-inner {
                    text-transform: capitalize;
                }
            }
        }
    }

    &__button-next {
        &:disabled {
            cursor: not-allowed;
        }
    }

    &__headquarters {
        overflow: scroll;

        &::-webkit-scrollbar {
            width: 0px;
            display: none;
        }
    
        &__box {
            width: 100%;
            padding: 8px 12px;
            margin-bottom: 16px;
            border-radius: 10px;
            border: 1px solid $color-mercury;
        }

        &__content-title {
            height: 22%;
            margin-bottom: 5px;
        }

        &__icon-cup {
            width: 30px;
            height: 30px;
            border-radius: 5px;
            background: $color-astronaut;
        }

        &__title {
            @include global-typography($color-astronaut, normal, 12px);
        }

        &__content-sales {
            height: 78%;
            width: 100%;
        }

        &__content-info {
            height: 30px;
            margin-bottom: 8px;
        }
        
        &__box-value {
            width: 50%;
            height: 100%;
            border-radius: 3px;
            background: #0E7D801A
        }

        &__value {
            max-width: 74%;
            margin-left: 4px;
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            @include global-typography($color-surfie-green, bold, 10px);
        }

        &__without-content {
            background: $color-alabaster;
            border-radius: 5px;
            min-height: 400px;
            padding: 0 2rem;
            

            &__title {
                @include global-typography($color-astronaut, bold, 15px);
                margin-top: 25px;
                padding: 0 15px;
                line-height: 16px;
            }
            &__first-paragraph {
                @include global-typography($color-astronaut, normal, 12px);
                margin: 15px 0;
                line-height: 14px;
            }
            &__second-paragraph {
                @include global-typography($color-astronaut, normal, 12px);
                line-height: 14px;
            }
        }
    }


}