@import "../../../../../../shared/styles/styles.scss";

.list-of-unidentified-prefixes {
    
    &__container {
        width: 100%;
        padding: 16px 20px;
        border-radius: 10px;
    }

    &__title {
        @include global-typography($color-carnation, bold, 16px);

        &__content {
            width: 100%;
            height: 40px;
            margin: 12px 0;
        }
    }

    &__text {
        width: 80%;
        margin: 10px 0;
        line-height: 18px;
        text-align: center;
        @include global-typography($color-astronaut, normal, 12px);
    }

    &__content-titles {
        width: 90%;
        height: 40px;
    }

    &__names-title {
        width: 35%;
        @include global-typography($color-astronaut, bold, 12px);
    }

    &__discard {
        margin-right: 5px;
        @include global-typography($color-astronaut, bold, 12px);

        &__content {
            width: 30%;
        }

        &__checkbox {
            &>.ant-checkbox-wrapper,
            &>.ant-checkbox-wrapper:hover {
                &>.ant-checkbox {
                    &>.ant-checkbox-inner {
                        border: 1px solid $primary-colors;
                    }

                    &.ant-checkbox-checked {
                        &>span.ant-checkbox-inner {
                            background: $color-dodger-blue;
                            border: 1px solid $color-dodger-blue;
                        }

                        &::after {
                            border-color: transparent;
                        }
                    }
                }
            }
        }
    }

    &__info {

        &__content {
            width: 90%;
            overflow: scroll;
            max-height: 300px;

            &::-webkit-scrollbar {
                width: 0px;
            }
        }

        &__column {
            width: 100%;
            height: 40px;
            border-top: 1px solid $color-mercury;
        }

        &__items {

            &__text {
                width: 35%;
                overflow: hidden;
                line-height: 14px;
                white-space: nowrap;
                text-overflow: ellipsis;
                @include global-typography($color-astronaut, normal, 12px);

                &__headquarter {
                    width: 35%;
                    overflow: hidden;
                    line-height: 14px;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    @include global-typography($color-astronaut, normal, 12px);
                }
            }
        }

        &__discard {
            margin-right: 5px;
            @include global-typography($color-astronaut, bold, 12px);

            &__content {
                width: 30%;
            }

            &__checkbox {
                &>.ant-checkbox-wrapper,
                &>.ant-checkbox-wrapper:hover {
                    &>.ant-checkbox {
                        &>.ant-checkbox-inner {
                            border: 1px solid $primary-colors;
                        }

                        &.ant-checkbox-checked {
                            &>span.ant-checkbox-inner {
                                background: $color-dodger-blue;
                                border: 1px solid $color-dodger-blue;
                            }

                            &::after {
                                border-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }

    &__button {

        &__content {
            width: 100%;
            padding-bottom: 30px;
        }

        &__cancel {
            @include global-button-secondary(34px, 40%);
        }

        &__confirm {
            margin-left: 30px;
            @include global-button-primary(34px, 40%);

            &:disabled {
                color: $color-white;
            }
        }
    }

    &__spinner {
        width: 100%;
        height: 555px;
        border-radius: 8px;
        background: $color-white;
    }
}