@import "../../../../../../shared/styles/styles.scss";

.sites-and-pubs {

    &__content-box {
        width: 38%;
        height: 600px;
        padding: 20px 0;
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);
    }

    &__content-title {
        height: 5%;
        width: 100%;
        display: flex;
        padding-left: 14px;
        align-items: center;
    }

    &__title {
        margin-left: 5px;
        @include global-typography($color-astronaut, bold, 16px);
    }

    &__list-titles {

        &__content {
            width: 100%;
            height: 10%;
            display: flex;
            align-items: flex-end;
            flex-direction: row;
            padding: 0 0 14px 16px;
        }

        &__select-sites {
            width: 40%;

            &__content-selected {
                width: 100%;
                height: 100%;
                cursor: pointer;
            }

            &__text {
                margin-left: 5px;
                @include global-typography($primary-colors, bold, 12px);

                &__hover {
                    color: $color-dodger-blue;
                    @extend .sites-and-pubs__list-titles__select-sites__text;
                }
            }

            &__checkbox {

                &>.ant-checkbox-wrapper,
                &>.ant-checkbox-wrapper:hover {
                    @include global-typography($primary-colors, bold, 12px);

                    &>.ant-checkbox {
                        &>.ant-checkbox-inner {
                            border: 1px solid $primary-colors;
                        }

                        &.ant-checkbox-checked {
                            &>span.ant-checkbox-inner {
                                background: $color-dodger-blue;
                                border: 1px solid $color-dodger-blue;
                            }

                            &::after {
                                border-color: transparent;
                            }
                        }
                    }
                }

            }
        }

        &__title-warehouse {
            width: 20%;
            padding-left: 12px;
            @include global-typography($primary-colors, bold, 12px);
        }

        &__title-total-inventory {
            width: 40%;
            @include global-typography($primary-colors, bold, 12px);
        }
    }

    &__content-info {
        width: 100%;
        height: 75%;
        padding: 0 10px;
        overflow: scroll;
    }

    &__info-row {
        width: 100%;
        padding: 12px 0 12px 14px;
        border-top: 1px solid $color-mercury;

        &__selected {
            background: $color-athens-gray;
            @extend .sites-and-pubs__info-row;
        }

        &__content {

            &__description-site {
                width: 40%;

                &__sub-content {
                    width: 78%;
                }
            }

            &__description-warehouse {
                width: 20%;
                padding-left: 12px;
            }

            &__description-total-inventory {
                width: 40%;

                &__content-value {
                    width: 75%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                }

                &__Content-icon {
                    width: 25%;
                    display: flex;
                    align-items:flex-start;
                    justify-content: center;
                }

                &__icon {
                    width: 16px;
                    height: 16px;

                    &__rotate {
                        transform: rotate(270deg);
                        @extend .sites-and-pubs__info-row__content__description-total-inventory__icon;
                    }
                }
            }
        }

        &__text {
            line-height: 16px;
            @include global-typography($primary-colors, normal, 14px);
        }

        &__text-site {
            line-height: 16px;
            overflow: hidden;
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            @include global-typography($primary-colors, normal, 14px);
        }
    }

    &__total {
        width: 100%;
        height: 10%;
        padding: 0 12px;

        &__content {
            width: 100%;
            height: 100%;
            border-top: 1px solid $color-mercury;
        }

        &__text {
            @include global-typography($primary-colors, bold, 14px);
        }

        &__content-input {
            margin-left: 10px;

            .input-price {
                width: 150px;
                margin: 0px;
                height: 30px;
                text-align: right;
                box-shadow: none;
                border-radius: 5px;
                background: $color-athens-gray;
                @include global-typography($color-astronaut, bold, 14px);

                &:disabled {
                    border: none;
    
                    &::placeholder {
                        @include global-typography($color-astronaut, bold, 14px);
                    }
                }
            }
        }
    }
}