@import "../../../../shared/styles/styles.scss";

.consignments-or-transfers {

    &__container {
        margin: 0px 0px 20px 0px;
        width: 98%;
        height: 465px;
        border-radius: 10px;
        @include global-box-shadow(0px, 3px, 20px);
    }

    &__consignments {
        margin-top: 30px;

        &__content-titles {

            &__primary {
                margin: 20px
            }

            &__second {
                margin-bottom: 10px;
                margin: 0px 20px 10px 20px
            }
        }

        &__titles {
            @include global-typography($color-astronaut, bold, 12px);
            

            &--title-second {
                @include global-typography($color-astronaut, bold, 12px);
                padding-right: 80px;
            }

            &__space {
                margin-left: 10px;

                &--mod {
                    padding-left: 2%;
                }
            }

            &__ellipsis {
                overflow-x: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &--second {
                padding-left: 20px;
            }
        }

        &__info {
            height: 300px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 0px;
            }
        }

        &__table {
            padding: 10px 30px 0px 20px;

            &__content-inputs {
                width: 90%;
                position: relative;

                &--mod {
                    width: 100%;
                    position: relative;

                    .input-price {
                        width: 100%;
                        margin: 0px;
                        height: 30px;
                        box-shadow: none;
                        text-align: right;
                        padding-left: 36px;
                        border-radius: 6px;
                        background: $color-alabaster;
                        border: 1px solid $color-alabaster;

                        &:focus {
                            box-shadow: none;
                            background: $color-alabaster;
                            border: 1px solid $color-alabaster;
                        }

                        &::placeholder {
                            color: $color-astronaut;
                        }

                        &:disabled {
                            background: $color-athens-gray;
                        }
                    }
                }

                &--mod-total-cash {
                    width: 100%;
                    position: relative;

                    .input-price {
                        width: 100%;
                        margin: 0px;
                        height: 30px;
                        box-shadow: none;
                        text-align: right;
                        padding-left: 36px;
                        border-radius: 6px;
                        background: $color-alabaster;
                        border: 1px solid $color-alabaster;
                        @include global-typography($color-surfie-green, bold, 14px);

                        &:focus {
                            box-shadow: none;
                            background: $color-alabaster;
                            border: 1px solid $color-alabaster;
                        }

                        &::placeholder {
                            color: $color-surfie-green;
                        }
                    }

                }
            }

            &__content-icon-price {
                width: 30px;
                height: 30px;
                display: flex;
                position: absolute;
                align-items: center;
                justify-content: center;
            }

            .input-price {
                width: 100%;
                margin: 0px;
                height: 30px;
                box-shadow: none;
                text-align: right;
                padding-left: 36px;
                border-radius: 6px;
                background: $color-white;
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, bold, 12px);
                
                &::placeholder {
                    @include global-typography($color-astronaut, bold, 12px);
                }

                &:disabled {
                    border: 1px dashed $color-mercury;
                    @include global-typography($color-mercury, bold, 12px);

                    &::placeholder {
                        color: $color-mercury
                    }

                    &:hover {
                        border: 1px dashed $color-mercury;
                    }
                }

                &:hover {
                    border: 1px solid #4096ff;
                }
            }
        }

        &__button-delete {
            margin: 0;
            width: 30px;
            height: 30px;
            border-radius: 5px;
            background: $color-alabaster;
            border: 1px solid $color-astronaut;

            &__icon {
                width: 20px;
                height: 20px;
            }

            &:disabled {
                border: none;
                cursor: not-allowed;
            }
        }

        &__line {
            height: 1px;
            width: 100%;
            margin: 10px 0;
            background: $color-mercury;
        }

        &__button {
            padding-left: 18px;
        }

        &__type {
            height: 40px;
            @include global-typography($color-surfie-green, bold, 14px);

            &__name {
                margin-left: 6px;
            }
        }
    }

    &__summary {

        &__details {
            height: 30px;
            width: 150px;
            margin-left: 10px;
            position: relative;

            .input-price {
                width: 100%;
                margin: 0px;
                height: 100%;
                cursor: auto;
                box-shadow: none;
                text-align: right;
                padding-left: 36px;
                border-radius: 6px;
                background: $color-alabaster;
                border: 1px solid $color-alabaster;
                @include global-typography($color-astronaut, bold, 12px);

                &::placeholder {
                    color: $color-astronaut;
                }
            }
        }

        &__price {
            @include global-typography($color-astronaut, bold, 12px);
            padding-right: 5px;

            &__icon {
                width: 80%;
                height: 80%;

                &__rotate {
                    transform: rotate(270deg);
                }
            }
        }
    }

    &__input {
        width: 90%;

        &.ant-input-affix-wrapper {
            height: 30px;
            border: 1px solid $color-astronaut;
            background: $color-white;
            padding: 4px;
            @include global-typography($color-astronaut, normal, 12px);

            &>.ant-input {
                height: 20px;
                margin: 0;
                line-height: 0;
                box-shadow: none;
                background: $color-white;
                @include global-typography($color-astronaut, bold, 12px);

                &::placeholder {
                    @include global-typography($color-astronaut, normal, 12px);
                }
            }
        }

        &.ant-input-affix-wrapper-disabled {

            height: 30px;
            border: 1px dashed $color-mercury;
            background: $color-white;

            &>.ant-input {
                height: 20px;
                margin: 0;
                line-height: 0;
                box-shadow: none;

                &::placeholder {
                    color: $color-mercury
                }
            }
        }

        &__text-right {

            &.ant-input-affix-wrapper {
                &>.ant-input {
                    text-align: right;
                }
            }
        }

        &__solid {
            &.ant-input-affix-wrapper {
                height: 30px;
                border: none;
                background: $color-athens-gray;
                font-size: 10px;
                padding: 4px;

                &>.ant-input {
                    height: 20px;
                    margin: 0;
                    line-height: 0;
                    box-shadow: none;
                }
            }

            &__text-right {

                &.ant-input-affix-wrapper {
                    &>.ant-input {
                        text-align: right;
                        background: $color-athens-gray;
                    }
                }
            }
        }
    }

    &__input-autocomplete{

        .ant-select-item-option-content {
            @include global-typography($color-astronaut, bold, 12px);
        }
    }
}