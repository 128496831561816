@import '../../../../shared/styles/styles.scss';

.modal-download-excel{

    &__container{
        width: 100%;
        height: 100%;
    }

    &__content-modal{
        height: 100px;
        padding: 15px;

        &__title{
            margin-bottom: 10px;
            @include global-typography($color-astronaut, bold, 14px);
        }
    }

    &__button-generate{
        @include global-button-secondary(34px, 150px);
    }

}