@import "../../shared/styles/styles.scss";

.reports {

    &__container {
        width: 100%;
        height: 100%;
        padding: 35px;
    };

    &__content-options {
        width: 100%;
        height: 10%;

        &__container {
            width: 60%;
            height: 60px;;
        }

        &__content-buttons {
            width: 65%;
            height: 100%;
            padding: 0 30px;
            border-radius: 5px;
            background: $color-athens-gray;
        }

        &__content-date {
            width: 35%;
            height: 60px;;
            padding-left: 30px;

            &__content-text {
                margin-left: 6px;
            }

            &__text {
                line-height: 14px;
                @include global-typography($color-astronaut, normal, 12px);
            }

            &__description {
                line-height: 13px;
                @include global-typography($color-astronaut, bold, 12px);
            }
        }

        &__buttons {
            margin: 0;
            width: 33%;
            height: 40px;

            &__active {
                border-radius: 5px;
                background: $color-white;
                @include global-box-shadow(0px, 3px, 20px);
                @extend .reports__content-options__buttons;
            }

            &__text {

                &__active {
                    @include global-typography($color-astronaut, bold, 12px);
                }

                &__inactive {
                    @include global-typography($color-mischka, bold, 12px);
                }

                &__hover {
                    @include global-typography($color-dodger-blue-hover, bold, 12px);
                }
            }
        }

        &__button-save {
            display: flex;
            align-items: center;
            justify-content: center;
            @include global-button-secondary(40px, 190px);
        }

        &__button-publish {
            display: flex;
            margin-left: 10px;
            align-items: center;
            justify-content: center;
            @include global-button-primary(40px, 190px);
        }

        &__icon-button {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }

    };

    &__content-info {
        width: 100%;
        height: 90%;
        margin-top: 40px;
    }

    &__update{

        &__icon{
            transform: rotate(180deg);
        }

        &__text{
            @include global-typography($color-dodger-blue, bold, 16px);
        }

        &__content{
            width: 100%;
            height: 74px;
        }
    }

    &__modal {

        &__content-footer {
            width: 100%;
            padding-bottom: 30px;
        }

        &__button {

            &__cancel {
                @include global-button-secondary(34px, 150px);
            }

            &__confirm {
                margin-left: 30px;
                @include global-button-primary(34px, 150px);

                &:disabled {
                    color: $color-white;
                }

                &__icon {
                    transform: rotate(270deg);
                }
            }
        }

        &__content {
            padding-bottom: 16px;
        }

        &__content-title {
            width: 100%;
            height: 40px;
            margin: 12px 0;
        }

        &__title {
            @include global-typography($color-astronaut, bold, 16px);
        }

        &__content-text {
            margin-bottom: 12px;
        }

        &__text {
            width: 80%;
            line-height: 16px;
            text-align: center;
            @include global-typography($color-astronaut, normal, 12px);

            &__second {
                width: 80%;
                line-height: 16px;
                text-align: center;
                margin-top: 12px;
                @include global-typography($color-astronaut, normal, 12px);
            }
        }

        &__content-button-download {
            width: 100%;
            height: 60px;
        }

        &__button-download {
            @include global-button-secondary(38px, 45%);

            &__icon {
                margin-right: 6px;
            }
        }

        &__content-icon {
            width: 100%;
            height: 210px;
        }
    }

    &__select {
        &.ant-select {
            width: 90%;
            height: 30px;

            &.ant-select {

                &>.ant-select-selector {
                    height: 30px;
                    background: $color-white;
                    border: 1px solid $color-astronaut;
                    font-size: 10px;

                    &>.ant-select-selection-item {
                        @include global-typography($color-astronaut, bold, 10px);
                    }
                }

                &>.ant-select-arrow {
                    inset-inline-end: 0px;
                }
            }

            &>.ant-select-selector {
                &>.ant-select-selection-search{
                    &>.ant-select-selection-search-input{
                        @include global-typography($color-astronaut, bold, 10px);
                        box-shadow: none;
                    }
                }
            }

            &.ant-select-disabled {

                &>.ant-select-selector {
                    height: 30px;
                    background: $color-white;
                    border: 1px dashed $color-mercury;
                    font-size: 10px;
                }

                &>.ant-select-arrow {
                    inset-inline-end: 0px;
                }
            }
        }

        &__icon {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &__popup {
            .ant-select-item-option-content {
                font-size: 10px;
                font-weight: bold;
                color: $color-astronaut;
            }
        }

    }
}