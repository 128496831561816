@import "../../../shared/styles/styles.scss";

.week-selector{

    &__button{
        @include global-button-secondary(34px,110px);
        text-transform: capitalize;

        &__selected{
            @include global-button-primary(34px,110px);
            text-transform: capitalize;
        }
    }

    &__text{
        @include global-typography($primary-colors,bold,12px);
    }

    &__date{
        @include global-box-shadow(0px,3px,10px);
        border: 1px solid $color-mercury;
        border-radius: 5px;
        background: $color-white;
        width: 210px;
        height: 36px;
        padding: 0px 5px;
        margin-top: 10px;
    }

    &__week{
        @include global-box-shadow(0px,3px,10px);
        background: $color-white;
        border-radius: 5px;
        height: 95px;
        padding: 0px 5px 10px 5px;
        width: 80%;

        &__select{
            margin: 5px 0px;
        }
    }

    &__container-date {
        width: 20%;
    }

    &__arrow{
        transform: rotate(180deg);
    }


    &__month{

        &>.ant-picker-input{

            &>input{
                @include global-typography($primary-colors,bold,12px);
                text-align: center;
                display: flex;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                text-transform: capitalize;
            }
        }

        &__popup{
            .ant-picker-cell{

                &>div.ant-picker-cell-inner{
                    text-transform: capitalize;
                }
            }
        }
    }


}