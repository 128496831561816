@import "../../../../../../shared/styles/styles.scss";

.file-upload-to-utilities {

    &__container {
        width: 100%;
    }

    &__description {
        width: 490px;
        @include global-typography($color-astronaut, normal, 14px);
    }

    &__container-upload {
        width: 70%;
        margin-top: 40px;

        &__content {
            width: 48%;
        }

        &__title {
            @include global-typography($color-astronaut, bold, 16px);
        }

        &__button{
            @include global-button-primary(35px, 200px);
            margin-top: 15px;


            &__icon {
                margin-right: 5px;
            }

            &__content{
                width: 60%
            }
        }
    }

    &__button-create-inventory {
        @include global-button-primary(100%, 35%);

        &:disabled {
            @include global-typography($color-white, bold, 12px);
        }

        &__content {
            width: 100%;
            height: 49px;
            margin-top: 25px;
        }
    }

    &__modal {

        &>.ant-modal-content {
            padding: 0;
        }
    }
}