@import "../../../../../../shared/styles/styles.scss";

.table-history-of-sites-and-pubs {

    &__content-box {
        width: 60%;
        height: 600px;
        padding: 30px 20px;
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);
    }

    &__content-search {
        width: 100%;
        height: 10%;

        &__input {
            width: 40%;

            &.ant-input-affix-wrapper {
                height: 30px;
                padding: 4px;
                box-shadow: none;
                background: $color-white;
                border: 1px solid $color-mischka;

                &>.ant-input {
                    margin: 0;
                    height: 20px;
                    box-shadow: none;
                    background: $color-white;
                    @include global-typography($color-astronaut, bold, 10px);

                    &::placeholder {
                        color: $color-astronaut;
                    }
                }
            }
        }
    }

    &__content-titles {
        height: 7%;
        width: 100%;

        &__first {
            width: 15%;
        }

        &__second {
            width: 30%;
        }

        &__third {
            width: 10%;
        }

        &__fourth {
            width: 20%;
        }

        &__title {
            @include global-typography($color-astronaut, bold, 12px);

            &__active {
                color: $color-dodger-blue;
                @extend .table-history-of-sites-and-pubs__content-titles__title;
            }
        } 
        
        &__button {
            margin: 0;

            &__icon {
                width: 15px;
                height: 15px;
                margin-right: 5px;

                &__active {
                    transform: rotate(180deg);
                    @extend .table-history-of-sites-and-pubs__content-titles__button__icon;
                }
            }
        }
    }

    &__content-info {
        width: 100%;
        height: 95%;
        overflow: scroll;
    }

    &__content-collapse {
        width: 100%;
        margin-bottom: 8px;
    }

    &__collapse {
        width: 100%;
        background: $color-white;

        &__active {
            background: $color-alabaster;
            @extend .table-history-of-sites-and-pubs__collapse;
        }

        &__label {

            &__content {
                width: 100%;
                height: 100%;
            }

            &__first {
                width: 15%;
            }

            &__second {
                width: 30%;
            }

            &__third {
                width: 10%;
            }

            &__fourth {
                width: 20%;
            }

            &__sub-fourth {
                width: 80%;
                height: 100%;
            }

            &__sub-fourth-aux {
                width: 20%;
                height: 100%;
            }

            &__icon-arrow {
                width: 15px;
                height: 15px;
                transform: rotate(90deg);

                &__rotate {
                    transform: rotate(270deg);
                    @extend .table-history-of-sites-and-pubs__collapse__label__icon-arrow;
                }
            }

            &__title {
                @include global-typography($color-astronaut, bold, 12px);
            }

            &__title-aux {
                line-height: 16px;
                overflow: hidden;
                white-space: normal;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                -webkit-box-orient: vertical;
                @include global-typography($color-astronaut, normal, 12px);
            }
        }

        &__children {

            &__content {
                width: 100%;


                &__date {
                    width: 20%;
                }

                &__unit-cost {
                    width: 20%;
                }
            }

            &__title {
                @include global-typography($color-astronaut, normal, 12px);
            }

            &__text {
                @include global-typography($color-astronaut, bold, 12px);
            }
        }

        &>.ant-collapse-item {
            &>.ant-collapse-header {
                padding: 8px 0;

                &>.ant-collapse-expand-icon{
                    display: none;
                }
            }

            &>.ant-collapse-content{

                &.ant-collapse-content-active {
                    border-top: none;
                    &>.ant-collapse-content-box {
                        padding: 10px 0 10px 15%;
                    }
                }

                &.ant-collapse-content-inactive {
                    &>.ant-collapse-content-box {
                        padding: 10px 0 10px 15%;
                    }
                }
            }
        }
    }

    &__spinner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    &__content-selected {
        width: 100%;
        height: 100%;
        cursor: pointer;

        &__checkbox {

            &>.ant-checkbox-wrapper,
            &>.ant-checkbox-wrapper:hover {
                @include global-typography($primary-colors, bold, 12px);

                &>.ant-checkbox {
                    &>.ant-checkbox-inner {
                        border: 1px solid $primary-colors;
                    }

                    &.ant-checkbox-checked {
                        &>span.ant-checkbox-inner {
                            background: $color-dodger-blue;
                            border: 1px solid $color-dodger-blue;
                        }

                        &::after {
                            border-color: transparent;
                        }
                    }
                }
            }

        }
    }
}