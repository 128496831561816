@import "../../../../shared/styles/styles.scss";

.record-keepers {

   &__container {
     width: 100%;
     height: 530px;
     padding: 1px 30px;
     border-radius: 5px;
     @include global-box-shadow(0px, 3px, 20px); 
   }

   &__content-info {
      margin-top: 15px;
   }

   &__Secondary-container{
      margin-top: 15px;

      &__content-buttons {
       padding: 12px 15px 12px 15px;
       border-radius: 5px;
       background: $color-athens-gray;
      }  
   }


   &__content-options {
      width: 100%;
      height: 60px;
      margin-top: 14px;

      &__content-buttons {
          width: 510px;
          height: 100%;
          padding: 0 30px;
          border-radius: 5px;
          background: $color-athens-gray;

      }

      &__buttons {
          margin: 0;
          width: 145px;
          height: 40px;

          &__active {
              border-radius: 5px;
              background: $color-white;
              @include global-box-shadow(0px, 3px, 20px);
              @extend .record-keepers__content-options__buttons;
          }

          &__text {

              &__active {
                  @include global-typography($color-astronaut, bold, 12px);
              }

              &__inactive {
                  @include global-typography($color-mischka, bold, 12px);
              }

              &__hover {
                  @include global-typography($color-dodger-blue-hover, bold, 12px);
              }
          }
      }

      &__icon-button {
          width: 20px;
          height: 20px;
          margin-right: 5px;
      }

  }

  &__summary {

     &__box {
        @include global-box-shadow(0px, 3px, 20px);
        background: $color-white;
        padding: 10px;
        border-radius: 5px;
        width: 95%;
     }

     &__details {
      
        &--mod {
           width: 150px;
        }
     }

     &__view-cash-closing {
        @include global-typography($color-dodger-blue, bold, 14px);
     }
  }

}