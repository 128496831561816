@import '../../../shared/styles/styles.scss';

.portfolio-detail-page {

    &__container {
        padding: 35px;
    }

    &__name{
        margin-left: 10px;
        @include global-typography($color-astronaut, bold, 14px);
    }

    &__content-header {
        height: 76px;

        &__title {
            width: 30%;
        }

        &__link {
            margin-left: 6px;
            @include global-typography($color-dodger-blue, bold, 16px);
        }

        &__totalizers {
            margin-left: 40px;
        }

        &__box {
            padding: 10px;
            border-radius: 5px;
            background: $color-white;
            @include global-box-shadow(0px, 3px, 20px);

            &:last-of-type {
                margin-left: 10px;
            }

            &__padding-box {
                padding: 0 10px;
            }
        }

        &__text {
            @include global-typography($color-astronaut, bold, 10px);
        }

        &__details {
            height: 30px;
            border-radius: 5px;
            background: $color-athens-gray;

            .input-price {
                width: 100%;
                margin: 0px;
                height: 100%;
                cursor: auto;
                box-shadow: none;
                text-align: right;
                border-radius: 5px;
                background: $color-athens-gray;
                border: 1px solid $color-athens-gray;
                @include global-typography($color-astronaut, bold, 14px);

                &::placeholder {
                    color: $color-astronaut;
                }
            }
            &__button-download{
                @include global-button-primary(34px,130px);
            }
        }
    }

    &__content-date-picker {
        padding: 20px 20px;
        margin-top: 20px;
        border-radius: 5px;
        background: $color-athens-gray;

        &__title {
            @include global-typography($color-astronaut, bold, 16px);
        }

        &__date {
            width: 240px;
            height: 36px;
            padding: 0px 16px;
            margin-left: 30px;
            border-radius: 5px;
            background: $color-white;
            @include global-box-shadow(0px, 3px, 20px);
        }

        &__arrow {
            transform: rotate(180deg);
        }

        &__month {
            &>.ant-picker-input {
                &>input {
                    @include global-typography($primary-colors, bold, 12px);
                    display: flex;
                    cursor: pointer;
                    overflow: hidden;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    text-transform: capitalize;
                }
            }
        }

        &__button-next {
            &:disabled {
                cursor: not-allowed;
            }
        }
    }

    &__summary {
        height: 500px;

        &__line {
            height: 1px;
            width: 100%;
            background: $color-astronaut;
        }

        &__content {
            overflow-y: scroll;
            max-height: 400px;

            &::-webkit-scrollbar {
                width: 0px;
            }
        }
    }

    &__content-tables {
        margin-top: 50px;

        &__table {
            width: 59%;
            height: 500px;
            padding: 15px 12px;
            border-radius: 10px;
            @include global-box-shadow(0px, 3px, 20px);
        }

        &__table_fertilizers{
            width: 39%;
            height: 500px;
            padding: 15px 12px;
            border-radius: 10px;
            @include global-box-shadow(0px, 3px, 20px);
        }

        &__content-values {
            height: 8%;
        }

        &__title {
            margin: 0 12px;
            @include global-typography($color-astronaut, bold, 14px);
        }

        &__values {
            width: 150px;
            height: 30px;
            position: relative;

            &__content-icon {
                width: 30px;
                height: 30px;
                display: flex;
                position: absolute;
                align-items: center;
                justify-content: center;

                &__icon {
                    width: 80%;
                    height: 80%;
                }
            }

            .input-price {
                width: 100%;
                margin: 0px;
                height: 100%;
                cursor: auto;
                box-shadow: none;
                text-align: right;
                padding-left: 36px;
                border-radius: 6px;
                background: $color-athens-gray;
                border: 1px solid $color-surfie-green;
                @include global-typography($color-surfie-green, bold, 14px);

                &::placeholder {
                    color: $color-surfie-green;
                }
            }

            &--mod {
                @extend .portfolio-detail-page__content-tables__values;

                .input-price {
                    border: 1px solid $color-carnation;
                    @include global-typography($color-carnation, bold, 14px);

                    &::placeholder {
                        color: $color-carnation;
                    }
                }
            }
        }

        &__content-info {
            height: 92%;

            &__content-title {
                height: 8%;
                width: 100%;
                padding-bottom: 4px;
            }

            &__title {
                @include global-typography($color-astronaut, bold, 12px);
            }

            &__content-scroll {
                width: 100%;
                height: 92%;
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    width: 0px;
                }
            }

            &__information-row {
                width: 100%;
                height: 45px;
            }
        }
        
        &__content-input {
    
            &>.ant-picker {
                width: 95%;
                margin: 0;
                padding: 0;
                height: 30px;
                border: none;
                border-radius: 5px;
                background: $color-athens-gray;
    
                &>.ant-picker-input {
                    &>input {
                        padding: 0 10px;
                        @include global-typography($color-astronaut, bold, 12px);
                    }
                }
            }
    
            &>.ant-input {
                width: 95%;
                margin: 0;
                height: 30px;
                border: none;
                padding: 0 10px;
                box-shadow: none;
                border-radius: 5px;
                background: $color-athens-gray;
                @include global-typography($color-astronaut, bold, 12px);
            }
    
            .input-price {
                margin: 0;
                width: 100%;
                height: 30px;
                border: none;
                box-shadow: none;
                text-align: right;
                border-radius: 5px;
                cursor: not-allowed;
                background: $color-athens-gray;
                @include global-typography($color-astronaut, bold, 12px);
            }

            &__disabled {
                @extend .portfolio-detail-page__content-tables__content-input;

                &>.ant-picker {
                    background: $color-white;
                    border: 1px dashed $color-mercury;
        
                    &>.ant-picker-input {
                        &>input {
                            color: $color-mercury;
    
                            &::placeholder {
                                color: $color-mercury;
                            }
                        }
                    }
                }

                &>.ant-input {
                    color: $color-mercury;
                    background: $color-white;
                    border: 1px dashed $color-mercury;

                    &::placeholder {
                        color: $color-mercury;
                    }
                }

                .input-price {
                    color: $color-mercury;
                    background: $color-white;
                    border: 1px dashed $color-mercury;

                    &::placeholder {
                        color: $color-mercury;
                    }
                }
            }
        }

        &__content-select{

            &>.ant-select{
                width: 95%;
                height: 100%;

                &>.ant-select-selector{
                    background: $color-athens-gray;
                    border: none;
                    margin: 0;

                    &>.ant-select-selection-item{
                        @include global-typography($color-astronaut, bold, 12px);
                    }

                    &>.ant-select-selection-placeholder{
                        @include global-typography($color-astronaut, bold, 12px);
                    }
                }
            }

            &__disabled{
                @extend .portfolio-detail-page__content-tables__content-select;

                &>.ant-select.ant-select-disabled{
                    &>.ant-select-selector{
                        background: $color-white;
                        border: 1px dashed $color-mercury;

                        &>.ant-select-selection-placeholder{
                            @include global-typography($color-mercury, bold, 12px);
                        }
                    }
                }
            }

            &__icon {
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__popup {
                .ant-select-item-option-content {
                    font-size: 10px;
                    font-weight: bold;
                    color: $color-astronaut;
                }
            }
        }
    }
}