@import '../../../shared/styles/styles.scss';


.pagination-component {

    &.ant-pagination {
        &>.ant-pagination-item {
            height: 27px;
            min-width: 30px;
            line-height: inherit;
            border: 2px solid $primary-colors;
            @include global-typography($primary-colors, bold, 14px);

            &.ant-pagination-item-active {
                color: $white;
                background: $color-dodger-blue;
                border: 2px solid $color-dodger-blue;
            }
        }

        &>.ant-pagination-prev,
        .ant-pagination-next {
            background: $color-dodger-blue;

            &>.ant-pagination-item-link {
                &>.anticon {
                    color: $white;
                }
            }
        }

        &>.ant-pagination-prev,
        .ant-pagination-next,
        .ant-pagination-jump-prev,
        .ant-pagination-jump-next {
            height: 25px;
            min-width: 25px;
            border-radius: 25px;
        }

    }
}