@import "../../../../../shared/styles/styles.scss";

.credits-or-we-owe-inventory {

    &__container {
        width: 98%;
        margin-top: 20px;
        padding: 30px 20px;
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);
    }

    &__table {
        width: 100%;
        margin-top: 16px;
        border-radius: 5px;
        padding: 0 4px 4px 4px;
        background: $color-athens-gray;

        &__title {
            @include global-typography($color-astronaut, bold, 18px);

            &__content {
                width: 100%;
                height: 50px;
                padding: 0 6px 0 16px
            }
        }

        &__icon {
            width: 20px;
            height: 20px;
            transition: transform 0.5s ease;

            &.rotate{
                transform: rotate(180deg);
                transition: transform 0.5s ease;
            }
        }

        &__box {
            width: 100%;
            border-radius: 5px;
            background: $color-white;
            padding: 20px 15px 10px 15px;
            max-height: 530px;
            overflow: scroll;
            transition: max-height 0.5s ease;

            &.collapsed{
                max-height: 0;
                padding: 1px 15px 1px 15px;
                transition: max-height 0.5s ease;
            }
        }

        &__subtitle {
            padding-left: 5px;
            @include global-typography($color-astronaut, bold, 12px);

            &__content {
                width: 100%;
                height: 30px;
            }

        }

        &__container-subtitle {

            &__first {
                width: 50%;
            }

            &__second {
                width: 50%;
            }

            &__third {
                width: 30%;
            }
        }

        &__content-info-scroll {
            width: 100%;
            max-height: 390px;
            overflow-y: scroll;
            transition: max-height 0.5s ease;

            &::-webkit-scrollbar {
                width: 0px;
                display: none;
            }

            &--mod {
                max-height: 390px;
                margin-bottom: 10px;
                @extend .credits-or-we-owe-inventory__table__content-info-scroll
            }

            &.collapsed{
                max-height: 0;
                transition: max-height 0.5s ease;
            }
        }

        &__container-primary{
            max-height: 390px;
            margin-bottom: 10px;
            @extend .credits-or-we-owe-inventory__table__content-info-scroll;

            &.collapsed{
                max-height: 0;
                transition: max-height 0.5s ease;
            }
                   
        }

        &__content-label {
            width: 100%;
            height: 38px;
        }

        &__input {
            width: 45%;

            &.ant-input-affix-wrapper {
                height: 30px;
                padding: 4px;
                font-size: 10px;
                box-shadow: none;
                background: $color-white;
                border: 1px solid $color-mercury;

                &>.ant-input {
                    margin: 0;
                    height: 20px;
                    line-height: 0;
                    box-shadow: none;
                    @include global-typography($color-astronaut, bold, 10px);

                    &::placeholder {
                        @include global-typography($color-mercury, bold, 10px);
                    }
                }
            }

            &.ant-input-affix-wrapper-disabled {
                height: 30px;
                padding: 4px;
                border: none;
                font-size: 10px;
                box-shadow: none;
                border-radius: 5px;
                background: $color-athens-gray;

                &>.ant-input {
                    margin: 0;
                    height: 20px;
                    line-height: 0;
                    box-shadow: none;
                    @include global-typography($color-astronaut, bold, 10px);
                }
            }

            &__concept {
                width: 45%;

                &.ant-input-affix-wrapper {
                    height: 30px;
                    padding: 4px;
                    border: none;
                    font-size: 10px;
                    box-shadow: none;
                    border-radius: 5px;
                    background: $color-white;
                    border: 1px solid $color-mercury;
    
                    &>.ant-input {
                        margin: 0;
                        height: 20px;
                        line-height: 0;
                        box-shadow: none;
                        background: $color-white;
                        @include global-typography($color-astronaut, bold, 10px);

                        &::placeholder {
                            @include global-typography($color-astronaut, bold, 10px);
                        }
                        &:hover {
                            background: $color-white;
                        }
                    }
                    &:hover {
                        background: $color-white;
                    }
                }
                &.ant-input-affix-wrapper-disabled {
                    height: 30px;
                    padding: 4px;
                    font-size: 10px;
                    box-shadow: none;
                    background: $color-white;
                    border: 1px dashed $color-mercury;
    
                    &>.ant-input {
                        margin: 0;
                        height: 20px;
                        line-height: 0;
                        box-shadow: none;
                        background: $color-white;
                        @include global-typography($color-astronaut, bold, 10px);
    
                        &::placeholder {
                            @include global-typography($color-mercury, bold, 10px);
                        }
                    }

                    &:hover {
                        background: $color-white;
                    }
                }
            }

            &__concept-input-price {
                width: 45%;
                height: 30px;
                position: relative;

                .input-price {
                    width: 100%;
                    margin: 0px;
                    height: 30px;
                    border: none;
                    box-shadow: none;
                    text-align: right;
                    border-radius: 5px;
                    padding-left: 36px;
                    background: $color-white;
                    border: 1px solid $color-mercury;
                    @include global-typography($color-astronaut, bold, 12px);
                    &::placeholder {
                        @include global-typography($color-astronaut, bold, 12px);
                    }
    
                    &:focus {
                        border: 1px solid $color-mercury;
                        box-shadow: none;
                        background: $color-white;
                    }

                    &:disabled {
                        width: 100%;
                        height: 30px;
                        margin: 0px;
                        box-shadow: none;
                        text-align: right;
                        border-radius: 5px;
                        background: $color-white;
                        border: 1px dashed $color-mercury;
                        @include global-typography($color-astronaut, bold, 12px);
    
                        &::placeholder {
                            @include global-typography($color-mercury, bold, 12px);
                        }
                    }
                }
            }
        }

        &__input-credits {
            width: 50%;

            &__input {
                width: 96%;

                &.ant-input-affix-wrapper-disabled {
                    height: 30px;
                    padding: 4px;
                    border: none;
                    font-size: 10px;
                    box-shadow: none;
                    border-radius: 5px;
                    background: $color-athens-gray;

                    &>.ant-input {
                        margin: 0;
                        height: 20px;
                        line-height: 0;
                        box-shadow: none;
                        @include global-typography($color-astronaut, bold, 10px);

                        &::placeholder {
                            @include global-typography($color-astronaut, bold, 10px);
                        }
                    }
                }
            } 
        }

        &__input-price-disabled {
            width: 45%;
            height: 30px;

            .input-price {

                &:disabled {
                    width: 100%;
                    height: 30px;
                    margin: 0px;
                    border: none;
                    box-shadow: none;
                    text-align: right;
                    border-radius: 5px;
                    background: $color-athens-gray;
                    @include global-typography($color-astronaut, bold, 12px);

                    &::placeholder {
                        @include global-typography($color-astronaut, bold, 12px);
                    }
                }
            }

            &--mod {
                width: 50%;
                height: 30px;
                @extend .credits-or-we-owe-inventory__table__input-price-disabled;
            }
        }

        &__input-price-enabled {
            width: 45%;
            position: relative;

            .input-price {
                width: 100%;
                margin: 0px;
                height: 30px;
                box-shadow: none;
                text-align: right;
                border-radius: 5px;
                padding-left: 36px;
                background: $color-white;
                border: 1px dashed $color-mercury;
                @include global-typography($color-astronaut, bold, 12px);

                &::placeholder {
                    @include global-typography($color-mercury, bold, 12px);
                }

                &:focus {
                    box-shadow: none;
                    border: 1px dashed $color-mercury;
                }
            }

            &__icon {
                width: 30px;
                height: 30px;
                display: flex;
                position: absolute;
                align-items: center;
                justify-content: center;
            }
        }

        &__picker {

            &__content {
                width: 20%;
                height: 30px;

                &>.ant-picker {

                    &.ant-picker-disabled {
                        margin: 0;
                        padding: 0;
                        width: 96%;
                        height: 30px;
                        border: none;
                        box-shadow: none;
                        border-radius: 5px;
                        background: $color-athens-gray;
                    }

                    &>.ant-picker-input {
                        &>input {
                            padding: 0 10px;
                            @include global-typography($color-astronaut, bold, 10px);

                            &::placeholder {
                                @include global-typography($color-astronaut, bold, 10px);
                            }
                        }
                    }
                }
            }

        }

        &__total {

            &__content {
                width: 100%;
                height: 38px;

                &--mod {
                    width: 100%;
                    height: 50px;
                    padding-top: 10px;
                    border-top: 1px solid $color-mercury;
                }
            }

            &__text {
                @include global-typography($color-astronaut, bold, 14px);

                &__content {
                    width: 49%;
                    height: 30px;
                }

                &--mod {
                    margin-left: 8px;
                    color: $color-dodger-blue;
                    @extend .credits-or-we-owe-inventory__table__total__text;
                }
            }

            &__content-value {
                width: 49%;
                height: 30px;

                .input-price {

                    &:disabled {
                        width: 100%;
                        height: 30px;
                        margin: 0px;
                        border: none;
                        box-shadow: none;
                        text-align: right;
                        border-radius: 5px;
                        background: $color-athens-gray;
                        @include global-typography($color-astronaut, bold, 12px);

                        &::placeholder {
                            @include global-typography($color-astronaut, bold, 12px);
                        }
                    }
                }
            }

            &__icon {
                width: 25px;
                height: 25px;
                transform: rotate(270deg);
            }
        }
    }

    &__real-cash-content {
        width: 100%;
        margin: 20px 0;

        &__title{
            @include global-typography($color-astronaut, bold, 18px);

            &__content {
                width: 49%;
                padding-left: 20px;
            }
        }

        &__input {
            width: 49%;
            height: 30px;
            padding-right: 20px;

            .input-price {
                width: 100%;
                margin: 0px;
                height: 30px;
                text-align: right;
                border-radius: 5px;
                padding-left: 36px;
                border: none;
                box-shadow: none;
                background: $color-athens-gray;
                @include global-typography($color-astronaut, bold, 12px);
    
                &::placeholder {
                    @include global-typography($color-astronaut, bold, 12px);
                }
            }
        }
    }

    &__button-delete {
        margin: 0;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background: $color-alabaster;
        border: 1px solid $color-astronaut;

        &__icon {
            width: 20px;
            height: 20px;
        }

        &:disabled {
            border: none;
            cursor: not-allowed;
        }
    }

    &__input_number{
        width: 98%;

        &>.ant-input-number {
            &>.ant-input-number-input-wrap{
                &>.ant-input-number-input{
                    text-align: end;
                    padding-right: 5px;
                    @include global-typography($color-astronaut, bold, 12px);
                }
            }

            &:hover{
                background: transparent;
            }
        }
    }
}