@import "../../../../../shared/styles/styles.scss";

.projections{

    &__container{
        background: $color-white;
        @include global-box-shadow(0px, 0px, 20px);
        border-radius: 10px;
        padding: 20px;
        height: 300px;
    }

    &__content{
        width: 95%;
    }

    &__box{
        margin-bottom: 10px;
    }

    &__content-percentage {
        width: 68px;
        border-radius: 5px;
        border: 1px solid $color-surfie-green;
        background-color: $color-surfie-green-light;
    }

    &__text-percentage{
        @include global-typography($color-surfie-green, bold, 14px);
    }

    &__input-percentage{
        
        &.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled{

            cursor: initial;
            height: 30px;
            border: 1px solid $color-carnation;
            background-color: $color-carnation-light;
            color: $color-carnation;
            width: 85px;
            font-weight: bold;
    
            &>.ant-input.ant-input-disabled{
                cursor: initial;
                height: 20px;
                color: $color-carnation;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: center;
                font-weight: bold;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
            }
    
            &:hover{
                cursor: initial;
                height: 30px;
                border: 1px solid $color-carnation;
                background-color: $color-carnation-light;
                color: $color-carnation;
                width: 85px;
                font-weight: bold;
    
                &>.ant-input{
                    cursor: initial;
                    height: 20px;
                    color: $color-carnation;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    text-align: center;
                    font-weight: bold;
                }
            }
        }
    }

    &__text-compliance{
        @include global-typography($color-carnation, bold, 14px);
    }

    &__text{
        @include global-typography($color-astronaut, bold, 12px);

        &--mod{
            width: 70%;
        }
    }

    &__line{
       width: 100%;
       height: 1px;
       background: $color-mischka;
       margin-top: 30px;
       margin-bottom: 10px;
    }

    &__icon{
        height: 40px;
        width: 40px;
        margin-right: 10px;
    }

    &__target{
        margin-bottom: 10px;
    }

    &__title{
        @include global-typography($color-astronaut, normal, 16px);
        line-height: 18px;
        width: 130px;
    }

    &__payment{

        &__content{
            margin-bottom: 15px;
        }
        
        &__content-input-price {
            width: 100%;
            position: relative;
    
            .input-price {
                width: 100%;
                margin: 0px;
                height: 30px;
                box-shadow: none;
                text-align: right;
                padding-left: 36px;
                border-radius: 6px;
                background: $color-white;
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, bold, 14px);
    
                &:focus {
                    box-shadow: none;
                    border: 1px solid $color-astronaut;
                }
    
                &::placeholder {
                    color: $color-astronaut;
                }
    
                &:disabled {
                    border: 1px dashed $color-mercury;
    
                    &::placeholder {
                        color: $color-mercury;
                    }
                }
            }

            &--green{

                .input-price:disabled::placeholder {
                    color: $color-surfie-green;
                }

                .input-price,
                .input-price:disabled {
                    border: 1px solid $color-surfie-green;
                    @include global-typography($color-surfie-green, bold, 14px);
                    background: $color-athens-gray;
                }

            }

            &--red{

                .input-price:disabled::placeholder {
                    color: $color-carnation;
                }

                .input-price,
                .input-price:disabled {
                    border: 1px solid $color-carnation;
                    @include global-typography($color-carnation, bold, 14px);
                    background: $color-athens-gray;
                }
            }

            &--disabled{
                .input-price:disabled::placeholder {
                    color: $color-astronaut;
                }

                .input-price,
                .input-price:disabled {
                    border: 0;
                    @include global-typography($color-surfie-green, bold, 14px);
                    background: $color-athens-gray;
                }
            }
        }

        &__content-icon-price {
            width: 30px;
            height: 30px;
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;

        }
        
        &__icon {
            width: 20px;
            height: 20px;
        }
    }
}