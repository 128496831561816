@import "../../../../shared/styles/styles.scss";

.daily-cash-closing {

    &__container {
        width: 380px;
        max-height: 88vh;
        border-radius: 10px;
        padding: 20px;
        @include global-box-shadow(0px, 3px, 20px);
    }
    &__container-daily-movements{
        overflow: scroll;
    }
    &__title {
        @include global-typography($color-astronaut, bold, 16px);
    }

    &__content {
        flex: 1;
        width: 100%;
    }

    &__subtitle {
        @include global-typography($color-astronaut, bold, 20px);
    }

    &__button {
        @include global-button-primary(39px, 100%);
        @include global-typography($color-white,bold,18px);
        background-color: $color-dodger-blue;

        &:disabled {
            color: $color-white;
        }
    }
    &__cash-title {
        @include global-typography($color-astronaut,bold,18px);
    }
    &__text{
        @include global-typography($color-astronaut,normal,14px);
    }
    &__container-totalizer {
        border: 1px solid $color-mercury;
        border-radius: 5px;
        padding: 10px;
        margin: 15px 0;
    }
    &__container-cash-received {
        border-right: 1px solid $color-mercury;
    }
    &__icon-income, &__icon_egress{
        margin-right: 5px;
    }
    &__content-cash-received {
        line-height: 18px;
    }
    &__cash-received{
        @include global-typography($color-surfie-green,normal,12px);
    }
    &__currency-received{
        @include global-typography($color-surfie-green,bold,16px);
    }
    &__container-cash-outflows {
        border-left: 1px solid $color-mercury;
    }
    &__content-cash-outflows {
        line-height: 18px;
    }
    &__cash-outflows{
        @include global-typography($color-carnation,normal,12px);
    }
    &__currency-outflows{
        @include global-typography($color-carnation,bold,16px);
    }
    &__container-collapse{
        border: 2px solid $color-athens-gray;
        border-radius: 10px;
        margin-top: 15px;
        width: 100%;

        .ant-collapse, .ant-collapse-content, .ant-collapse-item {
            border: none;
        }
        .ant-collapse>.ant-collapse-item >.ant-collapse-header {
            background-color: $color-athens-gray;
            @include global-typography($color-astronaut,bold,14px);
        }
        .ant-collapse-content>.ant-collapse-content-box {
            padding: 0;
        }
    }
    &__container-total { 
        margin: 15px 15px 25px 15px;
    }
    &__cash-headquarter{
        @include global-typography($color-astronaut,bold,14px);
    }
    &__total-cash-received{
        background-color: $color-athens-gray;
        width: 150px;
        position: relative;
        border-radius: 5px;
        margin-left: 15px;
    }
    &__content-icon-price {
        width: 30px;
        height: 30px;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        color: $color-astronaut;
    }
    &__input-price {
        margin: 0px;
        height: 30px;
        @include global-typography($color-astronaut, bold, 14px);
    }
    &__container-button-historical{
        margin: 30px 0 10px 0;
    }
    &__date {
        width: 80%;
        height: 36px;
        padding: 0px 16px;
        border-radius: 5px;
        margin: 15px 0;
        background: $color-white;
        border: 1px solid $color-mercury;
        @include global-box-shadow(0px, 3px, 10px);
    }
    &__arrow {
        transform: rotate(180deg);
    }

    &__month {
        width: 78%;
        &>.ant-picker-input {

            &>input {
                @include global-typography($primary-colors, bold, 12px);
                display: flex;
                cursor: pointer;
                overflow: hidden;
                text-align: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-transform: capitalize;
            }
        }

        &__popup {
            .ant-picker-cell {

                &>div.ant-picker-cell-inner {
                    text-transform: capitalize;
                }
            }
        }
    }

    &__button-next {
        &:disabled {
            cursor: not-allowed;
        }
    }

    &__not-data{
        height: 100%;

        &__text {
            @include global-typography($primary-colors, bold, 16px);
        }
    }
}