@import "../../shared/styles/styles.scss";

.daily-movements {

    &__container {
        padding: 35px;
        height: 100%;
    }

    &__title {
        @include global-typography($color-astronaut,bold,18px);

        &__info{
            @include global-typography($color-astronaut,normal,12px);
        }
    }

    &__box{
        padding: 20px 0;
    }

    &__summary{

        &__box{
            @include global-box-shadow(0px,3px,20px);
            background: $color-white;
            padding: 10px;
            border-radius: 5px;
            width: 95%;
        }

        &__details{
            background: $color-alabaster;
            border-radius: 5px;
            height: 30px;

            &--mod{
                width: 150px;
            }
        }

        &__text{
            @include global-typography($color-astronaut,bold,10px);
        }

        &__price{
            @include global-typography($color-astronaut,bold,14px);
            padding-right: 5px;
        }
        &__view-cash-closing{
            @include global-typography($color-dodger-blue,bold,14px);
        }

        &__line{
            height: 1px;
            width: 100%;
            background: $color-mischka;

            &--mod{
                margin: 10px 0;
            }
        }

        &__content{
            overflow-y: scroll;
            height: 75%;

            &::-webkit-scrollbar{
                width: 0px;
            }
        }
    }

    &__input-width{
        width: 58%;

        &__type{
            width: 30%;
        }

        &__report{
            width: 12%;
        }
    }

    &__payments{

        &__content{
            @include global-box-shadow(0px,3px,20px);
            height: 500px;
            width: 100%;
            border-radius: 10px;
            padding: 15px 20px;
        }

        &__container{
            padding-top: 10px
        }

        &__input {

            &.ant-input-affix-wrapper{
                height: 30px;
                background: $color-white;
                border: 1px solid $color-astronaut;
                
                &>.ant-input {
                    margin: 0;
                    height: 20px;
                    line-height: 0;
                    text-align: end;
                    box-shadow: none;
                    @include global-typography($color-astronaut,bold,10px);

                    &::placeholder {
                        color: $color-astronaut;
                    }
                }
            }

            &.ant-input-affix-wrapper-disabled{

                height: 30px;
                background: $color-white;
                border: 1px dashed $color-mercury;
    
                &>.ant-input {
                    margin: 0;
                    height: 20px;
                    line-height: 0;
                    box-shadow: none;
                    @include global-typography($color-mercury, bold, 10px);

                    &::placeholder {
                        color: $color-mercury
                    }
                }
                
                &:hover{
                    background: $color-white;
                }
            }


            &__content{
                padding-right: 5px
            }
        }

        &__select{
            &.ant-select{
                width: 97%;
                height: 30px;

                &>.ant-select-selector{
                    height: 30px;
                    background: $color-white;
                    border: 1px solid $color-astronaut;

                    & .ant-select-selection-item{
                        @include global-typography($color-astronaut,bold,10px);
                    }
                    
                    &>.ant-select-selection-placeholder {
                        @include global-typography($color-astronaut,bold,10px);
                    }
                }

                &>.ant-select-arrow{
                    inset-inline-end: 0px;
                }

                &.ant-select-disabled{
    
                    &>.ant-select-selector{
                        height: 30px;
                        background: $color-white;
                        border: 1px dashed $color-mercury;

                        &>.ant-select-selection-item {
                            @include global-typography($color-mercury, bold, 10px);
                        }

                        &>.ant-select-selection-placeholder {
                            @include global-typography($color-mercury, bold, 10px);
                        }
                    }

                    &>.ant-select-arrow{
                        inset-inline-end: 0px;
                    }
                }
                
            }

            &__icon{
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;

                &__active{
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }

            &__icon-option-render {
                width: 20px;
                height: 20px;
                margin-right: 4px;
            }

            &__popup {

                .ant-select-item-option-content {
                    font-size: 10px;
                    font-weight: bold;
                    color: $color-astronaut;
                }
            }
        }

        &__icon-filter{
            margin-right: 10px;
        }

        &__content-input-price {
            width: 100%;
            position: relative;

            .input-price {
                width: 100%;
                margin: 0px;
                height: 30px;
                box-shadow: none;
                text-align: right;
                padding-left: 36px;
                border-radius: 6px;
                background: $color-white;
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, bold, 11px);
    
                &:focus {
                    box-shadow: none;
                    border: 1px solid $color-astronaut;
                }

                &::placeholder {
                    color: $color-astronaut;
                }

                &:disabled {
                    border: 1px dashed $color-mercury;

                    &::placeholder {
                        color: $color-mercury;
                    }
                }
            }
        }

        &__content-icon-price {
            width: 30px;
            height: 30px;
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;

        }
        
        &__icon {
            width: 20px;
            height: 20px;
        }
        
        &__checkbox {
            &>.ant-checkbox-wrapper,
            &>.ant-checkbox-wrapper:hover {
                @include global-typography($primary-colors, bold, 12px);
                
                &>.ant-checkbox {
                    &>.ant-checkbox-inner {
                        border: 1px solid $primary-colors;
                        width: 20px;
                        height: 20px;
                    }

                    &.ant-checkbox-checked {
                        &>span.ant-checkbox-inner {
                            background: $color-dodger-blue;
                            border: 1px solid $color-dodger-blue;
                            width: 20px;
                            height: 20px;
                        }

                        &::after {
                            border-color: transparent;
                        }
                    }
                }
            }

            &>.ant-checkbox-wrapper-disabled,
            &>.ant-checkbox-wrapper-disabled:hover {
                &>.ant-checkbox {
                    &>.ant-checkbox-inner {
                        background: $white;
                        border: 1px dashed $color-mercury;
                    }
                }
            }
        }

        &__button-delete {
            margin: 0;
            width: 30px;
            height: 30px;
            border-radius: 5px;
            background: $color-alabaster;
            border: 1px solid $color-astronaut;

            &__icon {
                width: 20px;
                height: 20px;
            }

            &:disabled {
                border: none;
                cursor: not-allowed;
            }
        }
    }

    &__subtitle{
        @include global-typography($color-astronaut,bold,12px);

        &__content{
            padding-top: 10px;
        }
    }

    &__button-income{
        @include global-typography($color-surfie-green,bold,14px);
        margin-left: 10px;

        &--mod{
            color: $color-carnation;
        }
    }

    &__button {

        &__publish {
            display: flex;
            align-items: center;  
            justify-content: center;
            @include global-button-primary(35px, 48%);

            &__icon-publish {
                height: 20px;
                margin-left: 4px;
            }
        }
    
        &__save {
            display: flex;
            margin-right: 10px;
            align-items: center;
            justify-content: center;
            @include global-button-secondary(35px, 48%);

            &__icon-save {
                height: 20px;
                margin-right: 3px;
            }
        }
    }

    &__button-download {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include global-button-primary(35px, 48%);

        &__icon-publish {
            height: 20px;
            margin-left: 4px;
        }
    }
    
}