@import '../../../shared/styles/styles.scss';

.validate-user {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../../assets/shared/icons/icon_logo.png");

    &__main-container {
        padding: 5em;
        height: 100vh;
        display: flex;
        background: $white;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        &__welcome-text {
            width: 400px;
            padding: 40px 0;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: $primary-colors;
            font-weight: $global-weight-bold;
        }
    }

    &__container {
        width: 450px;
        height: 400px;
        display: flex;
        background: $white;
        padding: 30px 30px;
        border-radius: 10px;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0px 3px 20px #0000001A;
    }

    &__title {
        font-size: 28px;
        color: $primary-colors;
        font-weight: $global-weight-bold;
    }

    &__text {
        width: 80%;
        font-size: 14px;
        margin-top: 10px;
        line-height: 18px;
        text-align: center;
        margin-bottom: 22px;
        color: $primary-colors;
        font-weight: $global-weight-normal;
    }

    &__input {
        width: 392px;
        height: 50px;
        border: none;
        border-radius: 5px;
        padding: 4px 0px 4px 15px;
        background: $color-athens-gray;
        box-shadow: inset 0px 2px 10px #0000000A;

        &__container {
            height: 80px;
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        &.ant-input-affix-wrapper.ant-input-outlined.validate-user__input {
            border: none;
            box-shadow: none;
            background: $color-athens-gray;
            @include global-typography($color-astronaut, bold, 14px);
        }
        
        &>input {
            border: none;
            margin: 0 8px;
            box-shadow: none;
            color: $primary-colors;
            background: $color-athens-gray;
            @include global-typography($color-astronaut, bold, 14px);

            &:focus {
                border: none;
                background: $color-athens-gray;
                @include global-typography($color-astronaut, bold, 14px);
            }

            &::placeholder {
                @include global-typography($color-astronaut, bold, 14px);
            }
        }

        &__content-icon {
            width: 30px;
            height: 30px;
            display: flex;
            border-radius: 5px;
            align-items: center;
            justify-content: center;
            background: $color-white;
        }

        &__error {
            font-size: 10px;
            text-align: center;
            color: $alert-colors;
        }
    }

    &__content-button {
        
        &>button {
            margin: 0;
            width: 100%;
            height: 49px;
            border-radius: 5px;
            background: $color-dodger-blue;
            @include global-typography($white, bold, 16px);
        }

        &__link {
            margin-top: 20px;
            @include global-typography($color-dodger-blue, bold, 14px);
        }
    }
}