@import '../../../shared/styles/styles.scss';

.no-information-results-component {

    &__container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title {
        @include global-typography($color-astronaut, bold, 30px);
    }

    &__text {
        @include global-typography($color-astronaut, normal, 18px);
    }

}