@import "../../../shared/styles/styles.scss";

.quotation-opl-details {

    &__container {
        padding: 35px;
    }

    &__content-header {
        height: 76px;

        &__link {
            margin-left: 6px;
            @include global-typography($color-dodger-blue, bold, 14px);

            &__icon {
                margin-left: 10px;
            }

            &__name {
                margin-left: 10px;
                @include global-typography($color-astronaut, bold, 14px);
            }
        }

        &__date {

            &__text {
                @include global-typography($color-astronaut, bold, 14px);
            }

            &__picker {
                height: 36px;
                padding: 0px 16px;
                margin-left: 20px;
                border-radius: 5px;
                background: $color-white;
                @include global-box-shadow(0px, 3px, 20px);

                &__arrow {
                    transform: rotate(180deg);
                }

                &__month {
                    &>.ant-picker-input {
                        &>input {
                            width: 140px;
                            display: flex;
                            cursor: pointer;
                            overflow: hidden;
                            text-align: center;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            text-transform: capitalize;
                            @include global-typography($primary-colors, bold, 12px);
                        }
                    }
                }

                &__button-next {
                    &:disabled {
                        cursor: not-allowed;
                    }
                }
            }

        }
    }

    &__content-options {
        width: 100%;
        height: 60px;
        margin-top: 14px;

        &__content-buttons {
            height: 100%;
            padding: 0 30px;
            border-radius: 5px;
            background: $color-athens-gray;

        }

        &__buttons {
            margin: 0;
            width: 145px;
            height: 40px;

            &__active {
                border-radius: 5px;
                background: $color-white;
                @include global-box-shadow(0px, 3px, 20px);
                @extend .quotation-opl-details__content-options__buttons;
            }

            &__text {

                &__active {
                    @include global-typography($color-astronaut, bold, 12px);
                }

                &__inactive {
                    @include global-typography($color-mischka, bold, 12px);
                }

                &__hover {
                    @include global-typography($color-dodger-blue-hover, bold, 12px);
                }
            }
        }

        &__button-save {
            display: flex;
            align-items: center;
            justify-content: center;
            @include global-button-secondary(40px, 190px);
        }

        &__button-publish {
            display: flex;
            align-items: center;
            justify-content: center;
            @include global-button-primary(40px, 190px);
        }

        &__icon-button {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }

    }

    &__content-info {
        width: 100%;
        margin-top: 40px;
    }

    &__modal {

        &__content {
            padding-bottom: 16px;
        }

        &__title {
            margin-top: 18px;
            margin-bottom: 8px;
            @include global-typography($color-astronaut, bold, 18px);
        }

        &__text {
            width: 85%;
            line-height: 18px;
            text-align: center;
            margin-bottom: 18px;
            @include global-typography($color-astronaut, normal, 14px);
        }

        &__content-footer {
            width: 100%;
            padding-bottom: 30px;
        }

        &__button {

            &__cancel {
                @include global-button-secondary(34px, 150px);
            }
    
            &__confirm {
                margin-left: 30px;
                @include global-button-primary(34px, 150px);
            }
        }


    }
}