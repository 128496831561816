@import "../../../shared/styles/styles.scss";

.authorization-history {
    &__container {
        height: 100%;
        padding: 35px;
    }

    &__box{
        margin-top: 30px;
    }

    &__title-number{
        width: 27%;
    }

    &__loans{
        @include global-box-shadow(0px, 3px, 20px);
        border-radius: 10px;
        margin-left: 20px;
        height: 550px;
        padding: 20px 40px;

        &__title{
            @include global-typography($color-astronaut, bold, 18px);

            &--subtitle{
                font-size: 12px;
            }

            &--red{
                width: 27%;
                color: $color-carnation;
            }

            &--green{
                width: 27%;
                color: $color-surfie-green;
            }
        }

        &__text{
            @include global-typography($color-astronaut, normal, 12px);
            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;
            max-width: 80%;
        }

        &__table{
            margin-top: 30px;
            border-bottom: 1px solid $color-mischka;
            padding-bottom: 5px;
        }
    }

    &__inventory-adjustment{
        @include global-box-shadow(0px, 3px, 20px);
        border-radius: 10px;
        margin-left: 20px;
        height: 550px;
        padding: 20px 40px;

        &__content-item{
            width: 100%;
            height: 50px;
            border-bottom: 1px solid $color-mischka;
        }

        &__title{
            @include global-typography($color-astronaut, bold, 18px);

            &--subtitle{
                font-size: 12px;
            }

            &--red{
                color: $color-carnation;
            }

            &--green{
                color: $color-surfie-green;
            }
        }

        &__text{
            @include global-typography($color-astronaut, normal, 12px);
            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;
            max-width: 80%;
        }

        &__table{
            margin-top: 30px;
            border-bottom: 1px solid $color-mischka;
            padding-bottom: 5px;
        }
    }

    &__container-inv-adjust{
        max-height: 80%;
        overflow-y: scroll;
    }

    &__content-columns{
        width: 50%;
    }

    &__registration{

        &__container{
            padding-top: 10px;
            max-height: 80%;
            overflow-y: scroll;

            &>.ant-collapse{
                &>.ant-collapse-item{
                    &>.ant-collapse-header{
                        padding-left: 10px;
                    }
                }
            }

            &::-webkit-scrollbar{
                display: none;
                width: 0px;
            }
        }

        &--mod{
            border-bottom: 0px;
        }

        &__children{
            width: 100%;
            height: auto;

            &__titles{

                &__title-date{
                    width: 35%;
                    @include global-typography($color-astronaut, bold, 12px);

                    &--mod{
                        @include global-typography($color-astronaut, normal, 12px);
                    }
                }

                &__title-cost{
                    width: 45%;
                    @include global-typography($color-astronaut, bold, 12px);

                    &--mod{
                        @include global-typography($color-astronaut, normal, 12px);
                    }
                }
            }

            &__loan{
                display: flex;
                flex-direction: column;
                width: 50%;
            }

            &__content-titles{
                display: flex;
                justify-content: center;
            }

            &__content-info{
                width: 100%;
                height: auto;
            }
        }
    }

    &__total{
        @include global-box-shadow(0px, 3px, 20px);
        border-radius: 5px;
        width: 45%;
        height: 52px;
        padding: 10px;
        
        &__content{
            margin-left: 20px;
            margin-top: 10px;
        }

        &__text{
            @include global-typography($color-astronaut, bold, 14px);

            &--red{
                color: $color-carnation;
            }
        }

        &__box{
           background: $color-athens-gray;
           border-radius: 5px;
           width: 150px;
           padding-right: 5px;
           height: 30px;

           &--mod{
            border: 1px solid $color-carnation;
           }
        }

        &__icon{
            width: 20px;
            height: 20px;
            margin-left: 5px;

            &--mod{
                margin-left: 0px;
                margin-right: 5px;
            }
        }
    }

    &__totals-inv-adjust{
        @include global-box-shadow(0px, 3px, 20px);
        border-radius: 5px;
        width: 24%;
        height: 15%;
        padding: 10px;

        &__content{
            margin-left: 20px;
            margin-top: 10px;
        }

        &__text{
            @include global-typography($color-astronaut, bold, 14px);

            &--red{
                color: $color-carnation;
            }

            &--green{
                color: $color-surfie-green
            }
        }

        &__box{
           background: $color-athens-gray;
           border-radius: 5px;
           width: 100%;
           padding-right: 5px;

           &--mod{
            border: 1px solid $color-carnation;
           }

           &--green{
            border: 1px solid $color-surfie-green;
           }
        }
    }

    &__title {
        @include global-typography($color-dodger-blue, bold, 14px);
    }

    &__subtitle {
        @include global-typography($color-astronaut, bold, 14px);
    }

    &__icon-arrow {
        margin: 0 10px;
    }


}