@import '../../../shared/styles/styles.scss';

.portfolio-page {

    &__container {
        padding: 35px;

        &-headquarters {
            height: 550px;
            margin-top: 30px;
            padding: 20px 14px;
            border-radius: 10px;
            background: $color-white;
            @include global-box-shadow(0px, 3px, 20px);
        }
    }
}