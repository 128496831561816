@import "../../../../../shared/styles/styles.scss";

.general-summary {

    &__general-summary {

        &__container {
            width: 25%;
        }

        &__table {
            width: 60%;
            padding: 16px;
            height: 200px;
            border-radius: 5px;
            background: $color-white;
            @include global-box-shadow(0px, 3px, 20px);
        }

        &__title {
            @include global-typography($color-astronaut, bold, 14px);
            margin-left: 1%;

            &__content {
                width: 100%;
                height: 20%;
            }
        }

        &__content-box {
            width: 100%;
            height: 80%;
        }

        &__box {
            width: 92%;
            height: 60px;
            padding: 10px;
            display: flex;
            border-radius: 5px;
            flex-direction: column;
            align-items: flex-start;
            border: 2px solid $color-surfie-green;

            &--mod {
                border: 2px solid $color-carnation;
                @extend .general-summary__general-summary__box;
            }
        }

        &__value {
            margin-left: 5px;
            white-space: nowrap;
            overflow-x: auto;
            max-width: 77%;
            padding-bottom: 3px;
            scrollbar-color: $color-surfie-green $color-titan-white;
            @include global-typography($color-surfie-green, bold, 16px);

            &--mod {
                margin-left: 5px;
                @include global-typography($color-carnation, bold, 16px);
            }
        }

        &__text {
            line-height: 14px;
            width: 100%;
            @include global-typography($color-surfie-green, normal, 12px);

            &--mod {
                color: $color-carnation;
                @extend .general-summary__general-summary__text;
            }
        }
    }

    &__final-balance {

        &__table {
            width: 38%;
            padding: 16px;
            border-radius: 5px;
            background: $color-white;
            @include global-box-shadow(0px, 3px, 20px);
        }

        &__title {
            @include global-typography($color-astronaut, bold, 14px);

            &__content {
                width: 100%;
                height: 20%;
            }
        }

        &__content-info {
            width: 100%;
            height: 80%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &__content-label {
            width: 100%;
            height: 40px;

            &__title {
                margin-right: 10px;
                @include global-typography($color-astronaut, bold, 14px);
            }

            .input-price {
                width: 160px;
                margin: 0px;
                height: 30px;
                border: none;
                box-shadow: none;
                text-align: right;
                border-radius: 5px;
                background: $color-athens-gray;
                @include global-typography($color-astronaut, bold, 14px);

                &::placeholder {
                    @include global-typography($color-astronaut, bold, 14px);
                }
            }
        }
    }

    &__content-tables {
        width: 100%;
        margin-top: 20px;
    }

    &__content-title-table {
        width: 98%;
        height: 40px;
        border-radius: 5px;
        background: $color-athens-gray;
    }

    &__title-table {
        @include global-typography($color-astronaut, bold, 18px);
    }
}