@import '../../../shared/styles/styles.scss';

.notification-alert {
    &__success.ant-notification-notice.ant-notification-notice-success {
        background-color: $color-iceberg;
        border-radius: 10px;

        & .ant-notification-notice-message {
            @include global-typography($color-surfie-green, bold, 16px);
        }
        & .ant-notification-notice-description{
            @include global-typography($color-surfie-green, bold, 14px);
        }
        & .ant-notification-notice-message{
            margin-top: 0;
            margin-bottom: 0;
        }
        & .ant-notification-notice-icon{
            top: 16px;
        }

    }
    &__error.ant-notification-notice.ant-notification-notice-error {
        background-color: $color-pink;
        border-radius: 10px;

        & .ant-notification-notice-message {
            @include global-typography($color-scorpion, bold, 16px);
        }
        & .ant-notification-notice-description{
            @include global-typography($color-scorpion, bold, 14px);
        }
        & .ant-notification-notice-message{
            margin-top: 0;
            margin-bottom: 0;
        }
        & .ant-notification-notice-icon{
            top: 16px;
        }

    }

    &__info.ant-notification-notice.ant-notification-notice-info {
        background-color: $color-titan-white;
        border-radius: 10px;

        & .ant-notification-notice-message {
            @include global-typography($color-scorpion, bold, 16px);
        }
        & .ant-notification-notice-description{
            @include global-typography($color-scorpion, bold, 14px);
        }
        & .ant-notification-notice-message{
            margin-top: 0;
            margin-bottom: 0;
        }
        & .ant-notification-notice-icon{
            top: 16px;
        }

    }
}