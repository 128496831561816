@import "../../../shared/styles/styles.scss";

.headquarter-create-edit {
    &__content {
        height: 100%;
        padding: 35px;
    }

    &__box{
        margin-top: 30px;
    }

    &__data-headquarter{
        @include global-box-shadow(0px, 3px, 20px);
        background: $color-white;
        margin-left: 20px;
        border-radius: 10px;
        padding: 20px;

        &__label {
            margin-bottom: 4px;
            @include global-typography($color-astronaut, normal, 14px);
        }

        &__input {

            &.ant-input {
                margin: 0;
                width: 100%;
                height: 30px;
                line-height: 0;
                box-shadow: none;
                border-radius: 5px;
                background: $color-white;
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, normal, 14px);

                &::placeholder {
                    @include global-typography($color-astronaut, normal, 14px);
                    opacity: 0.3;
                }

                &:focus {
                    box-shadow: none;
                    border: 1px solid $color-astronaut;
                }

                &.ant-input-disabled {
                    border: 1px solid $color-mercury;
                    @include global-typography($color-mercury, bold, 10px);

                    &::placeholder {
                        color: $color-mercury
                    }
                }
            }
        }

        &__content{
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;

            &--mod{
                width: 48%;
                margin-bottom: 10px;
            }
        }

        .input-price {
            width: 100%;
            margin: 0px;
            height: 30px;
            box-shadow: none;
            border-radius: 5px;
            background: $white;
            border: 1px solid $color-astronaut;
            @include global-typography($color-astronaut, normal, 14px);

            &:focus {
                box-shadow: none;
                border: 1px solid $color-astronaut;
            }

            &::placeholder {
                @include global-typography($color-astronaut, normal, 14px);
                opacity: 0.3;
            }

            &:disabled {
                background: $white;
                color: $color-mercury;
                border: 1px solid $color-mercury;

                &::placeholder {
                    color: $color-mercury
                }
            }
        }

        .input-price-error {
            @extend .input-price;
            border: 1px solid $color-sunset-orange;

            &:focus {
                box-shadow: none;
                border: 1px solid $color-sunset-orange;
            }
        }

        &__error {
            height: 14px;
            font-size: 10px;
            color: $color-carnation;
        }
        &__space{

            & .ant-space-item{
                margin-right: 5px;
            }
        }
        &__tag{
            height: 30px;
            display: flex;
            align-items: center;
            border-radius: 5px;
            background: $color-white;
            border: 1px solid $color-astronaut;
            margin: 0;
            @include global-typography($color-astronaut, normal, 14px);

            & .anticon-close{
                color: $color-astronaut;
            }
        }
        &__add-tag{
            height: 30px;
            background: $color-mischka;
            display: flex;
            align-items: center;
            border: 1px dashed;
            cursor: pointer;
            @include global-typography($color-astronaut, bold, 12px);

        }
    }

    &__title{
        @include global-typography($color-astronaut, bold, 16px);
        margin-bottom: 15px;
    }

    &__cancel{
        @include global-button-secondary(34px, 150px);
        margin-right: 20px;
    }

    &__save{
        @include global-button-primary(34px, 150px);
    }

    &__buttons{
        margin-top: 20px;
        height: 25vh;
        align-items: flex-end;
        margin-bottom: 20px;
    }

    &__select {

        &.ant-select {
            height: 30px;

            &>.ant-select-selector {
                height: 30px;
                background: $color-white;
                border: 1px solid $color-astronaut;

                &>.ant-select-selection-item {
                    @include global-typography($color-astronaut, bold, 10px);
                }

                &>.ant-select-selection-placeholder {
                    @include global-typography($color-astronaut, normal, 14px);
                }
            }

            &>.ant-select-arrow {
                inset-inline-end: 0px;
            }

            &.ant-select-disabled {

                &>.ant-select-selector {
                    height: 30px;
                    background: $color-white;
                    border: 1px solid $color-mercury;

                    &>.ant-select-selection-item {
                        @include global-typography($color-mercury, bold, 10px);
                    }

                    &>.ant-select-selection-placeholder {
                        @include global-typography($color-mercury, normal, 14px);
                    }
                }


                &>.ant-select-arrow {
                    inset-inline-end: 0px;
                }
            }
        }

        &__icon {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &__popup {
            .ant-select-item-option-content {
                font-size: 10px;
                font-weight: bold;
                color: $color-astronaut;
            }
        }
    }

    &__select-multiple {

        &.ant-select {

            &>.ant-select-selector {
                font-size: 14px;
                border-radius: 5px;
                background: $color-white;
                border: 1px solid $color-astronaut;

                &>.ant-select-selection-overflow {
                    &>.ant-select-selection-overflow-item {
                        &>.ant-select-selection-item {
                            background: $color-white;
                            border: 1px solid $color-astronaut;
                            @include global-typography($color-astronaut, bold, 12px);

                            &>.ant-select-selection-item-remove {
                                color: $color-astronaut;
                            }
                        }
                    }
                }
            }
        }

        &__popup {
            .ant-select-item-option-content {
                font-size: 13px;
                font-weight: bold;
                color: $color-astronaut;
            }
        }
    }
    
}