@import "../../../../../shared/styles/styles.scss";

.provision-for-consumption {
    &__content-tables {
        margin-top: 50px;

        &__table {
            width: 100%;
            height: 500px;
            padding: 15px 12px;
            border-radius: 10px;
            @include global-box-shadow(0px, 3px, 20px);
            margin-top: 10px;
        }

        &__content-values {
            height: 8%;
        }

        &__title {
            margin: 0 12px;
            @include global-typography($color-astronaut, bold, 14px);
        }

        &__values {
            width: 150px;
            height: 30px;
            position: relative;

            &__content-icon {
                width: 30px;
                height: 30px;
                display: flex;
                position: absolute;
                align-items: center;
                justify-content: center;

                &__icon {
                    width: 80%;
                    height: 80%;
                    transform: rotate(180deg);
                }
            }

            .input-price {
                width: 100%;
                margin: 0px;
                height: 100%;
                cursor: auto;
                box-shadow: none;
                text-align: right;
                padding-left: 36px;
                border-radius: 6px;
                background: $color-athens-gray;
                border: 1px solid $color-surfie-green;
                @include global-typography($color-surfie-green, bold, 14px);

                &::placeholder {
                    color: $color-surfie-green;
                }
            }

            &--mod {
                @extend .provision-for-consumption__content-tables__values;

                .input-price {
                    border: 0px;
                    @include global-typography($color-astronaut, bold, 14px);

                    &::placeholder {
                        color: $color-astronaut;
                    }
                }
            }
        }

        &__content-info {
            height: 92%;

            &__content-title {
                height: 8%;
                width: 100%;
                padding-bottom: 4px;
            }

            &__title {
                @include global-typography($color-astronaut, bold, 12px);
            }

            &__content-scroll {
                width: 100%;
                height: 92%;
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    width: 0px;
                }
            }

            &__information-row {
                width: 100%;
                height: 45px;
            }
        }
        
        &__content-input {
    
            &>.ant-input {
                width: 95%;
                margin: 0;
                height: 30px;
                border: none;
                padding: 0 10px;
                box-shadow: none;
                border-radius: 5px;
                background: $color-athens-gray;
                @include global-typography($color-astronaut, bold, 12px);
            }
    
            &>.provision-for-consumption__table__content-inputs{
                .input-price {
                    margin: 0;
                    width: 100%;
                    height: 30px;
                    box-shadow: none;
                    text-align: right;
                    border-radius: 5px;
                    background: $color-white;
                    border: 1px solid $color-astronaut;
                    @include global-typography($color-astronaut, bold, 12px);

                    &::placeholder {
                        color: $color-astronaut;
                    }
                }
            }

            &__disabled {

                &>.provision-for-consumption__table__content-inputs{

                    .input-price {
                        margin: 0;
                        width: 100%;
                        border: none;
                        height: 30px;
                        box-shadow: none;
                        text-align: right;
                        border-radius: 5px;
                        background: $color-white;
                        border: 1px solid $color-astronaut;
                        @include global-typography($color-astronaut, bold, 12px);
                    }
                }
            }
        }
    }

    &__table {
        padding-top: 10px;

        &__content-inputs {
            width: 100%;
            position: relative;

            &--mod {
                width: 100%;
                position: relative;

                .input-price {
                    width: 100%;
                    margin: 0px;
                    height: 30px;
                    box-shadow: none;
                    text-align: right;
                    padding-left: 36px;
                    border-radius: 6px;
                    background: $color-alabaster;
                    border: 1px solid $color-alabaster;
                    @include global-typography($color-astronaut, bold, 14px);

                    &:focus {
                        box-shadow: none;
                        background: $color-alabaster;
                        border: 1px solid $color-alabaster;
                    }

                    &::placeholder {
                        color: $color-astronaut;
                    }
                    &:disabled {
                        background: $color-athens-gray;
                    }
                }
            }

            &--mod-total-cash {
                width: 100%;
                position: relative;

                .input-price {
                    width: 100%;
                    margin: 0px;
                    height: 30px;
                    box-shadow: none;
                    text-align: right;
                    padding-left: 36px;
                    border-radius: 6px;
                    background: $color-alabaster;
                    border: 1px solid $color-alabaster;
                    @include global-typography($color-surfie-green, bold, 14px);

                    &:focus {
                        box-shadow: none;
                        background: $color-alabaster;
                        border: 1px solid $color-alabaster;
                    }

                    &::placeholder {
                        color: $color-surfie-green;
                    }
                }
                
            }
        }

        &__content-icon-price {
            width: 30px;
            height: 30px;
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;
        }

        .input-price {
            width: 100%;
            margin: 0px;
            height: 30px;
            box-shadow: none;
            text-align: right;
            padding-left: 36px;
            border-radius: 6px;
            background: $color-white;
            border: 1px solid $color-astronaut;
            @include global-typography($color-astronaut, bold, 11px);

            &:disabled {
                border: 1px dashed $color-mercury;

                &:hover {
                    border: 1px dashed $color-mercury;
                }
            }

            &:hover {
                border: 1px solid #4096ff;
            }
        }
    }
}