@import "../../../../../shared/styles/styles.scss";

.industry-and-commerce {
    &__content-tables {
        margin-top: 50px;

        &__table {
            width: 100%;
            height: 500px;
            padding: 15px 12px;
            border-radius: 10px;
            @include global-box-shadow(0px, 3px, 20px);
            margin-top: 10px;
        }

        &__content-values {
            height: 8%;
        }

        &__title {
            margin: 0 12px;
            @include global-typography($color-astronaut, bold, 14px);
        }

        &__values {
            width: 150px;
            height: 30px;
            position: relative;

            &__content-icon {
                width: 30px;
                height: 30px;
                display: flex;
                position: absolute;
                align-items: center;
                justify-content: center;

                &__icon {
                    width: 80%;
                    height: 80%;
                    transform: rotate(180deg);
                }
            }

            .input-price {
                width: 100%;
                margin: 0px;
                height: 100%;
                cursor: auto;
                box-shadow: none;
                text-align: right;
                padding-left: 36px;
                border-radius: 6px;
                background: $color-athens-gray;
                border: 1px solid $color-surfie-green;
                @include global-typography($color-surfie-green, bold, 14px);

                &::placeholder {
                    color: $color-surfie-green;
                }
            }

            &--mod {
                @extend .industry-and-commerce__content-tables__values;

                .input-price {
                    border: 0px;
                    @include global-typography($color-astronaut, bold, 14px);

                    &::placeholder {
                        color: $color-astronaut;
                    }
                }
            }
        }

        &__content-info {
            height: 92%;

            &__content-title {
                height: 8%;
                width: 100%;
                padding-bottom: 4px;
            }

            &__title {
                @include global-typography($color-astronaut, bold, 12px);
            }

            &__content-scroll {
                width: 100%;
                height: 92%;
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    width: 0px;
                }
            }

            &__information-row {
                width: 100%;
                height: 45px;
            }
        }
        
        &__content-input {
    
            &>.ant-input {
                width: 95%;
                margin: 0;
                height: 30px;
                border: none;
                padding: 0 10px;
                box-shadow: none;
                border-radius: 5px;
                background: $color-athens-gray;
                @include global-typography($color-astronaut, bold, 12px);
            }
    
            .input-price {
                margin: 0;
                width: 100%;
                height: 30px;
                border: none;
                box-shadow: none;
                text-align: right;
                border-radius: 5px;
                cursor: not-allowed;
                background: $color-athens-gray;
                @include global-typography($color-astronaut, bold, 12px);
            }

            &__disabled {

                &>.ant-input {
                    width: 95%;
                    margin: 0;
                    height: 30px;
                    border: none;
                    padding: 0 10px;
                    box-shadow: none;
                    border-radius: 5px;
                    background: $color-athens-gray;
                    @include global-typography($color-astronaut, bold, 12px);

                    &:focus {
                        border: none;
                        box-shadow: none;
                        background: $color-athens-gray;
                    }
                }
            }
        }
    }
}