@import '../../../../../shared/styles/styles.scss';

.business-headquarters {

    &__title {
        @include global-typography($color-astronaut, bold, 18px);
    }

    &__content-search {
        width: 100%;
        height: 70px;

        &__input {
            width: 30%;
            margin-right: 20px;

            &.ant-input-affix-wrapper {
                height: 40px;
                padding: 4px;
                box-shadow: none;
                background: $color-white;
                border: 1px solid $color-mischka;

                &>.ant-input {
                    margin: 0;
                    height: 30px;
                    box-shadow: none;
                    background: $color-white;
                    @include global-typography($color-astronaut, bold, 10px);

                    &::placeholder {
                        color: $color-astronaut;
                    }
                }
            }
        }

        &__select-headquarter {
            width: 140px;
            height: 40px;
            margin-right: 20px;
            border-radius: 6px;
            border: 1px solid $color-mischka;

            &__content-selected {
                width: 100%;
                height: 100%;
                cursor: pointer;
                border-radius: 6px;
            }

            &__text {
                margin-left: 10px;
                @include global-typography($primary-colors, bold, 12px);

                &__hover {
                    color: $color-dodger-blue;
                    @extend .business-headquarters__content-search__select-headquarter__text;
                }
            }

            &__checkbox {
                &>.ant-checkbox-wrapper,
                &>.ant-checkbox-wrapper:hover {
                    @include global-typography($primary-colors, bold, 12px);
                    
                    &>.ant-checkbox {
                        &>.ant-checkbox-inner {
                            border: 1px solid $primary-colors;
                        }

                        &.ant-checkbox-checked {
                            &>span.ant-checkbox-inner {
                                background: $color-dodger-blue;
                                border: 1px solid $color-dodger-blue;
                            }

                            &::after {
                                border-color: transparent;
                            }
                        }
                    }
                }

            }
        }

        &__button {
            @include global-button-primary(40px, 175px);

            &__text {
                margin-left: 6px;
            }
        }

    }

    &__table {
        height: 485px;
        margin-top: 20px;
        padding: 0 14px;
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);

        &__container{
            height: 90%;
            width: 100%;
            overflow-y: scroll;
            padding-bottom: 20px;
        }

        &__container-title {
            height: 10%;
            width: 100%;
        }

        &__title {
            @include global-typography($color-astronaut, bold, 12px);
        }

        &__width-column {

            &__initial {
                width: 22%;
                padding-left: 5%;
            }

            &__first {
                width: 5%;
                text-align: center;
            }

            &__second {
                width: 17%;
            }

            &__third {
                width: 17%;
                justify-content: center;
                text-align: center;
            }

            &__fourth {
                width: 17%;
                text-align: center;
                justify-content: center;
            }

            &__fifth {
                width: 10%;
            }
        }

        &__information-row {
            width: 100%;
            height: 40px;
            border-top: 1px solid $color-mercury;

            &__width-row {

                &__first {
                    width: 25%;
                }
            }

            &__selected {
                background: $color-athens-gray;
                @extend .business-headquarters__table__information-row
            }
            &:hover{
                background-color: $color-alabaster;
            }
            &:active{
                background-color: $color-athens-gray;
            }
        }

        &__checkbox {
            &>.ant-checkbox-wrapper,
            &>.ant-checkbox-wrapper:hover {
                &>.ant-checkbox {
                    &>.ant-checkbox-inner {
                        border: 1px solid $primary-colors;
                    }

                    &.ant-checkbox-checked {
                        &>span.ant-checkbox-inner {
                            background: $color-dodger-blue;
                            border: 1px solid $color-dodger-blue;
                        }

                        &::after {
                            border-color: transparent;
                        }
                    }
                }
            }
        }

        &__description {
            overflow-x: hidden;
            white-space: break-spaces;
            text-overflow: ellipsis;
            @include global-typography($color-astronaut, normal, 12px);

            &--mod {
                font-weight: bold;
                @extend .business-headquarters__table__description;
            }

            &--unassigned{
                @extend .business-headquarters__table__description;
                color: $color-mischka;
            }

        }

        &__button {
            margin: 0;
            @include global-typography($color-dodger-blue, bold, 12px);
        }
        &__icon-arrow{
            width: 15px;
        }
    }

    &__content-pagination {
        margin-top: 30px;

        &__text {
            @include global-typography($color-astronaut, normal, 14px);
            &--mod{
                font-weight: bold;
            }
        }
    }
}