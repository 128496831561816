@import "../../../../shared/styles/styles.scss";

.table-other-opl {

    &__payments {
        margin-top: 30px;

        &__box-padding {
            padding-right: 10px;
        }

        &__container {
            @include global-box-shadow(0px, 3px, 20px);
            width: 49%;
            border-radius: 10px;
            margin-bottom: 15px;
        }

        &__content {
            padding: 15px 30px;
        }

        &__content-titles {

            &__fist {
                margin: 10px 0 10px 0
            }

            &__second {
                margin-bottom: 10px
            }

        }

        &__titles {
            @include global-typography($color-astronaut, bold, 12px);

            &__space {
                margin-left: 10px;

                &--mod {
                    padding-left: 2%;
                }
            }

            &__ellipsis {
                overflow-x: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &__info {
            height: 300px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 0px;
            }
        }

        &__table {
            padding-top: 10px;

            &__content-inputs {
                width: 90%;
                position: relative;

                &--mod {
                    width: 100%;
                    position: relative;

                    .input-price {
                        width: 100%;
                        margin: 0px;
                        height: 30px;
                        box-shadow: none;
                        text-align: right;
                        padding-left: 36px;
                        border-radius: 6px;
                        background: $color-alabaster;
                        border: 1px solid $color-alabaster;
                        @include global-typography($color-astronaut, bold, 14px);

                        &:focus {
                            box-shadow: none;
                            background: $color-alabaster;
                            border: 1px solid $color-alabaster;
                        }

                        &::placeholder {
                            color: $color-astronaut;
                        }
                        &:disabled {
                            background: $color-athens-gray;
                        }
                    }
                }

                &--mod-total-cash {
                    width: 100%;
                    position: relative;

                    .input-price {
                        width: 100%;
                        margin: 0px;
                        height: 30px;
                        box-shadow: none;
                        text-align: right;
                        padding-left: 36px;
                        border-radius: 6px;
                        background: $color-alabaster;
                        border: 1px solid $color-alabaster;
                        @include global-typography($color-surfie-green, bold, 14px);

                        &:focus {
                            box-shadow: none;
                            background: $color-alabaster;
                            border: 1px solid $color-alabaster;
                        }

                        &::placeholder {
                            color: $color-surfie-green;
                        }
                    }
                    
                }
            }

            &__content-icon-price {
                width: 30px;
                height: 30px;
                display: flex;
                position: absolute;
                align-items: center;
                justify-content: center;
            }

            .input-price {
                width: 100%;
                margin: 0px;
                height: 30px;
                box-shadow: none;
                text-align: right;
                padding-left: 36px;
                border-radius: 6px;
                background: $color-white;
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, bold, 11px);

                &:disabled {
                    border: 1px dashed $color-mercury;

                    &:hover {
                        border: 1px dashed $color-mercury;
                    }
                }

                &:hover {
                    border: 1px solid #4096ff;
                }
            }
        }

        &__select {
            &.ant-select {
                width: 90%;
                height: 30px;

                &.ant-select {

                    &>.ant-select-selector {
                        height: 30px;
                        background: $color-white;
                        border: 1px solid $color-astronaut;
                        font-size: 10px;

                        &>.ant-select-selection-item {
                            @include global-typography($color-astronaut, bold, 10px);
                        }
                    }

                    &>.ant-select-arrow {
                        inset-inline-end: 0px;
                    }
                }

                &.ant-select-disabled {

                    &>.ant-select-selector {
                        height: 30px;
                        background: $color-white;
                        border: 1px dashed $color-mercury;
                        font-size: 10px;
                    }

                    &>.ant-select-arrow {
                        inset-inline-end: 0px;
                    }
                }
            }

            &__icon {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            &__popup {
                .ant-select-item-option-content {
                    font-size: 10px;
                    font-weight: bold;
                    color: $color-astronaut;
                }
            }

        }

        &__input {
            width: 90%;

            &.ant-input-affix-wrapper {
                height: 30px;
                border: 1px solid $color-astronaut;
                background: $color-white;
                font-size: 10px;
                padding: 4px;

                &>.ant-input {
                    height: 20px;
                    margin: 0;
                    line-height: 0;
                    box-shadow: none;
                    @include global-typography($color-astronaut, bold, 10px);
                }
            }

            &.ant-input-affix-wrapper-disabled {

                height: 30px;
                border: 1px dashed $color-mercury;
                background: $color-white;

                &>.ant-input {
                    height: 20px;
                    margin: 0;
                    line-height: 0;
                    box-shadow: none;
                }
            }

            &__text-right {

                &.ant-input-affix-wrapper {
                    &>.ant-input {
                        text-align: right;
                    }
                }
            }

            &__solid {
                &.ant-input-affix-wrapper {
                    height: 30px;
                    border: none;
                    background: $color-athens-gray;
                    font-size: 10px;
                    padding: 4px;

                    &>.ant-input {
                        height: 20px;
                        margin: 0;
                        line-height: 0;
                        box-shadow: none;
                    }
                }


                &__text-right {

                    &.ant-input-affix-wrapper {
                        &>.ant-input {
                            text-align: right;
                            background: $color-athens-gray;
                        }
                    }
                }
            }
        }

        &__time {
            width: 90%;
            height: 30px;
            cursor: pointer;
            box-shadow: none;
            border-radius: 5px;
            border: 1px solid $color-astronaut;

            &:hover {
                border: 1px solid $color-astronaut;
            }

            &.ant-picker-focused {
                border: 1px solid $color-astronaut;
            }

            &>.ant-picker-input {
                &>input {
                    @include global-typography($color-astronaut, bold, 10px);
                }
            }

            &.ant-picker-disabled {
                background: $color-white;
                border: 1px dashed $color-mercury;
            }

            &__select {
                &>.ant-picker-panel-container {
                    &>.ant-picker-panel-layout {
                        &>.ant-picker-panel {
                            &>.ant-picker-footer {
                                border-top: 1px solid $color-nobel;

                                &>.ant-picker-ranges {
                                    margin-left: 0;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    &>.ant-picker-ok {
                                        &>button {
                                            @include global-button-primary(26px, 100%);
                                        }
                                    }

                                    &>.ant-picker-now {
                                        @include global-button-secondary(26px);
                                        display: flex;
                                        align-items: center;
                                        border: 1px solid $color-astronaut;

                                        &:hover {
                                            border: 1px solid $color-astronaut;
                                        }

                                        &>a {
                                            padding: 10px;
                                            @include global-typography($color-astronaut, bold, 12px);
                                        }
                                    }
                                }
                            }

                            &>.ant-picker-time-panel {
                                &>.ant-picker-content {
                                    &>.ant-picker-time-panel-column {
                                        &>.ant-picker-time-panel-cell-selected {
                                            &>.ant-picker-time-panel-cell-inner {
                                                background: $color-dodger-blue;
                                                @include global-typography($color-white, bold, 14px);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &__line {
            height: 1px;
            width: 100%;
            margin: 10px 0;
            background: $color-mercury;
        }

        &__type {
            height: 40px;
            @include global-typography($color-dodger-blue, bold, 14px);

            &__name {
                margin-left: 6px;
            }

            &:disabled {
                cursor: not-allowed;
            }
        }

        &__text-area {
            width: 100%;

            &.ant-input {
                resize: none;
                padding: 20px;
                margin-top: 10px;
                box-shadow: none;
                background: $color-athens-gray;
                border: 2px solid $color-athens-gray;
                @include global-typography(#5C5C5C, normal, 16px);

                &:hover {
                    border: 2px solid $color-dodger-blue;
                }

                &:focus {
                    border: 2px solid $color-astronaut;
                }
            }

            &--mod {
                @extend .table-other-opl__payments__text-area;

                &.ant-input {
                    color: $color-astronaut;
                    border: 2px solid $color-astronaut;
                }
            }
        }

        &__button-delete {
            margin: 0;
            width: 30px;
            height: 30px;
            border-radius: 5px;
            background: $color-alabaster;
            border: 1px solid $color-astronaut;

            &__icon {
                width: 20px;
                height: 20px;
            }

            &:disabled {
                border: none;
                cursor: not-allowed;
            }
        }
    }

    &__summary {

        &__box {
            width: 42%;
            padding: 8px;
            border-radius: 5px;
            @include global-box-shadow(0px, 3px, 20px);

            &--mod {
                width: 32%;
                @extend .table-other-opl__summary__box;
            }

            &--mod-initial-balance {
                width: 15%;
                @extend .table-other-opl__summary__box;
            }

            &--mod-real-cash {
                width: 32%;
                @extend .table-other-opl__summary__box;
            }

            &__sub-box {
                width: 32%;
            }
        }

        &__total-cash {
            width: 41%
        }

        &__text {
            padding-left: 5px;
            @include global-typography($color-astronaut, bold, 10px);
        }

        &__details {
            height: 30px;
            width: 150px;
            margin-left: 10px;
            position: relative;

            .input-price {
                width: 100%;
                margin: 0px;
                height: 100%;
                cursor: auto;
                box-shadow: none;
                text-align: right;
                padding-left: 36px;
                border-radius: 6px;
                background: $color-alabaster;
                border: 1px solid $color-alabaster;
                @include global-typography($color-astronaut, bold, 14px);

                &::placeholder {
                    color: $color-astronaut;
                }
            }
        }

        &__price {
            @include global-typography($color-astronaut, bold, 14px);
            padding-right: 5px;

            &__icon {
                width: 80%;
                height: 80%;

                &__rotate {
                    transform: rotate(270deg);
                }
            }            
        }
    }


    &__content{
        width: 20%;

        &__buttons{
            width: 40%;
        }
    }
}