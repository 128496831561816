@import "../../../../shared/styles/styles.scss";

.provisions-opl {

    &__container {
        gap: 20px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        justify-content: space-between;
    }

    &__container-table {
        width: 49%;
        height: 450px;
        padding: 20px 20px 10px 20px; 
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);
    }

    &__text-table {
        margin-left: 6px;
        @include global-typography($color-astronaut, bold, 12px);
    }

    &__content-header-table {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__content-total-table {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__total-table {
        width: 150px;
        height: 30px;
        margin-left: 10PX;

        .input-price {

            &:disabled {
                width: 100%;
                margin: 0px;
                height: 30px;
                border: none;
                box-shadow: none;
                text-align: right;
                border-radius: 4px;
                background: $color-athens-gray;
                @include global-typography($color-astronaut, bold, 12px);

                &::placeholder {
                    @include global-typography($color-astronaut, bold, 12px);
                }
            }
        }
    }

    &__titles-table {

        &__text {
            @include global-typography($color-astronaut, bold, 12px);
        }

        &__container {
            width: 100%;
            height: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    &__container-by-item {

        &__first {

            &--isChecked {
                width: 4%;
                height: 100%;
                display: flex;
                align-items: center;
            }

            &--operation {
                width: 15%;
                height: 100%;
                display: flex;
                align-items: center;
            }

            &--date {
                width: 11%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--base {
                width: 18%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--percentage {
                width: 5%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--description {
                width: 19%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--total {
                width: 28%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }

        &__second {

            &--isChecked {
                width: 4%;
                height: 100%;
                display: flex;
                align-items: center;
            }

            &--operation {
                width: 20%;
                height: 100%;
                display: flex;
                align-items: center;
            }

            &--date {
                width: 15%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--base {
                width: 25%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--percentage {
                width: 6%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--total {
                width: 30%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }

        &__third {

            &--isChecked {
                width: 5%;
                height: 100%;
                display: flex;
                align-items: center;
            }

            &--date {
                width: 15%;
                height: 100%;
                display: flex;
                align-items: center;
            }

            &--base {
                width: 22%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--percentage {
                width: 6%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--description {
                width: 22%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--total {
                width: 30%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }

        &__fourth {

            &--isChecked {
                width: 5%;
                height: 100%;
                display: flex;
                align-items: center;
            }

            &--date {
                width: 15%;
                height: 100%;
                display: flex;
                align-items: center;
            }

            &--base {
                width: 32%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--percentage {
                width: 8%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--total {
                width: 40%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }

        &__fifth {

            &--date {
                width: 15%;
                height: 100%;
                display: flex;
                align-items: center;
            }

            &--description {
                width: 40%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &--total {
                width: 45%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }

    &__container-info {
        width: 100%;
        overflow-y: scroll;
        height: calc(100% - 110px);

        &::-webkit-scrollbar {
            width: 0px;
        }

        &__content-item-row {
            width: 100%;
            height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &__check {
            width: 20px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            &>.ant-checkbox-wrapper {
                &>.ant-checkbox {
                    &>.ant-checkbox-inner {
                        width: 20px;
                        height: 20px;
                        border: 1px solid $primary-colors;
                    }

                    &.ant-checkbox-checked {
                        &>span.ant-checkbox-inner {
                            width: 20px;
                            height: 20px;
                            background: $color-dodger-blue;
                            border: 1px solid $color-dodger-blue;
                        }

                        &::after {
                            border-color: transparent;
                        }
                    }
                }
            }

            &>.ant-checkbox-wrapper-disabled {
                &>.ant-checkbox {
                    &>.ant-checkbox-inner {
                        background: $white;
                        border: 1px dashed $color-mercury;

                        &::after {
                            border-color: $white;
                        }
                    }
                }
            }
        }

        &__select {

            &.ant-select {
                width: 96%;
                height: 30px;

                &.ant-select {

                    &>.ant-select-selector {
                        height: 30px;
                        font-size: 10px;
                        background: $color-white;
                        border: 1px solid $color-astronaut;

                        &>.ant-select-selection-item {
                            @include global-typography($color-astronaut, bold, 10px);
                        }

                        &>.ant-select-selection-placeholder {
                            @include global-typography($color-mercury, bold, 10px);
                        }
                    }

                    &>.ant-select-arrow {
                        inset-inline-end: 0px;
                    }
                    
                }

                &.ant-select-disabled {

                    &>.ant-select-selector {
                        height: 30px;
                        font-size: 10px;
                        background: $color-white;
                        border: 1px dashed $color-mercury;

                        &>.ant-select-selection-placeholder {
                            @include global-typography($color-mercury, bold, 10px);
                        }
                    }

                    &>.ant-select-arrow {
                        inset-inline-end: 0px;
                    }
                }
            }

            &__icon {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }

            &__popup {
                .ant-select-item-option-content {
                    font-size: 10px;
                    font-weight: bold;
                    color: $color-astronaut;
                }
            }
        }

        &__date {
            width: 96%;
            height: 30px;
            background: $color-white;
            color: $color-athens-gray;
            border: 1px solid $color-astronaut;

            &.ant-picker{
                padding: 0px 5px;

                &.ant-picker-disabled{
                    background: $color-white;
                    border: 1px dashed $color-mercury;
                    
                }
                &>.ant-picker-input{
                    &>input{
                        box-shadow: none;
                        @include global-typography($color-astronaut, bold, 10px);

                        &::placeholder{
                            @include global-typography($color-mercury, bold, 10px);
                        }
                    }
                }
            }
        }

        &__number {
            width: 100%;
            height: 30px;
            display: flex;
            position: relative;
            justify-content: center;

            .input-price {
                width: 96%;
                margin: 0px;
                height: 30px;
                box-shadow: none;
                text-align: right;
                padding-left: 26px;
                border-radius: 6px;
                background: $color-white;
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, bold, 11px);

                &:focus {
                    box-shadow: none;
                    background: $color-white;
                    border: 1px solid $color-astronaut;
                }

                &::placeholder {
                    color: $color-mercury;
                }
                &:disabled {
                    background: $color-white;
                    border: 1px dashed $color-mercury;
                   
                    &::placeholder {
                        @include global-typography($color-mercury, bold, 10px);
                    }
                }
            }
            
            &__icon-price {
                top: 0px;
                left: 8px;
                width: 30px;
                height: 30px;
                display: flex;
                position: absolute;
                align-items: center;
            }
        }
        
        &__input {
            &.ant-input-affix-wrapper {
                width: 94%;
                height: 30px;
                padding: 4px;
                font-size: 12px;
                background: $color-white;
                border: 1px solid $color-astronaut;

                &>.ant-input {
                    margin: 0;
                    height: 20px;
                    line-height: 0;
                    box-shadow: none;
                    text-align: right;
                    @include global-typography($color-astronaut, bold, 11px);

                    &::placeholder {
                        @include global-typography($color-mercury, bold, 11px);
                    }
                }
            }

            &.ant-input-affix-wrapper-disabled {

                height: 30px;
                background: $color-white;
                border: 1px dashed $color-mercury;

                &>.ant-input {
                    margin: 0;
                    height: 20px;
                    line-height: 0;
                    box-shadow: none;

                    &::placeholder {
                        @include global-typography($color-mercury, bold, 12px);
                    }
                }
            }
        }

        &__number-total {
            width: 100%;
            height: 30px;
            display: flex;
            position: relative;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;

            .input-price {
                margin: 0px;
                height: 30px;
                box-shadow: none;
                text-align: right;
                padding-left: 26px;
                border-radius: 6px;
                background: $color-white;
                width: calc(98% - 70px);
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, bold, 11px);

                &:focus {
                    box-shadow: none;
                    background: $color-white;
                    border: 1px solid $color-astronaut;
                }

                &::placeholder {
                    color: $color-mercury;
                }
                
                &:disabled {
                    background: $color-white;
                    border: 1px dashed $color-mercury;
                   
                    &::placeholder {
                        @include global-typography($color-mercury, bold, 10px);
                    }
                }
            }
            
            &__icon-price {
                top: 0px;
                left: 8px;
                width: 30px;
                height: 30px;
                display: flex;
                position: absolute;
                align-items: center;
            }
        }

        &__button {
            margin: 0;
            width: 30px;
            height: 30px;
            margin-left: 5px;
            border-radius: 5px;
            background: $color-alabaster;
            border: 1px solid $color-astronaut;

            &__icon {
                width: 20px;
                height: 20px;
            }

            &:disabled {
                border: none;
                cursor: not-allowed;
            }
        }
    }

    &__container-button {
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        border-top: 1px solid $color-mercury;
    }

    &__button-add {
        @include global-typography($color-dodger-blue, bold, 14px);
    }
}