@import '../../../../../shared/styles/styles.scss';

.constants-panel {

    &__title{
        margin: 15px 0;
        @include global-typography($color-astronaut, bold, 16px);
    }

    &__content-box {
        width: 38%;
        height: 300px;
        padding: 20px;
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);

        &--details{
            width: 60%;
        }
    }

    &__collapse{
        overflow-y: scroll;
        height: 80%;

        &::-webkit-scrollbar{
            width: 0px;
            display: none;
        }

        &__content{
            &.ant-collapse{
                &>.ant-collapse-item-disabled,
                &>.ant-collapse-item{
                    &>.ant-collapse-header{
                        &>.ant-collapse-header-text{
                            color: $color-astronaut;
                        }
                    }
                }
            }
        }

        &__edit{
            @include global-button-secondary(30px, 30px);
            margin-left: 10px;
        }

        &__delete{
            @include global-button-secondary(30px, 30px);
            margin-left: 10px;
        }
    }

    &__info-row {
        width: 100%;
        padding: 12px 12px 12px 14px;
        border-bottom: 1px solid $color-mercury;

        &--selected {
            background: $color-athens-gray;
        }

        &__content {

            &__description-site {
                width: 70%;

                &__sub-content {
                    width: 78%;
                }
            }

            &__description-warehouse {
                width: 20%;
                padding-left: 12px;
            }

            &__description-total-inventory {
                width: 40%;

                &__content-value {
                    width: 75%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                }

                &__Content-icon {
                    width: 25%;
                    display: flex;
                    align-items:flex-start;
                    justify-content: center;
                }

                &__icon {
                    width: 16px;
                    height: 16px;

                    &--rotate {
                        transform: rotate(270deg);
                    }
                }
            }
        }

        &__text {
            line-height: 16px;
            @include global-typography($primary-colors, normal, 14px);
        }

        &__text-site {
            line-height: 16px;
            overflow: hidden;
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            @include global-typography($primary-colors, normal, 14px);
        }
    }

    &__content-input {

        margin-top: 20px;

        &__input {
            
            &.ant-input {
                margin: 0;
                margin-right: 15px;
                width: 150px;
                height: 30px;
                line-height: 0;
                box-shadow: none;
                border-radius: 5px;
                background: $color-white;
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, normal, 14px);

                &::placeholder {
                    @include global-typography($color-mercury, normal, 14px);
                }

                &:focus {
                    box-shadow: none;
                    border: 1px solid $color-astronaut;
                }

                &.ant-input-disabled {
                    border: 1px solid $color-mercury;
                    @include global-typography($color-mercury, normal, 14px);

                    &::placeholder {
                        color: $color-mercury
                    }
                }
            }
        }

        &__delete{
            min-width: 80px;
            margin-right: 10px;
        }
    }

    &__add{
        @include global-button-primary(32px, 100px);
    }

    &__content-picker {
        width: 30%;
        height: 30px;
        position: relative;

        &__content-icon {
            z-index: 1;
            width: 30px;
            height: 30px;
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;
        }

        &__picker {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            background: $white;
            border-radius: 5px;
            position: relative;
            border: 1px solid $color-astronaut;

            &>.ant-picker-input {
                &>input {
                    height: 20px;
                    cursor: pointer;
                    padding: 0 10px 0 32px;
                    @include global-typography($color-astronaut, bold, 10px);

                    &::placeholder {
                        @include global-typography($color-astronaut, normal, 14px);
                    }
                }
            }

            &.ant-picker-disabled {
                background: $white;

                &>.ant-picker-input {
                    &>input {
                        &::placeholder {
                            color: $color-mercury
                        }
                    }
                }
            }
        }
    }
}