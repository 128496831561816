@import "../../../../../../shared/styles/styles.scss";

.file-upload-to-inventory {

    &__container {
        width: 100%;
    }

    &__description {
        width: 500px;
        @include global-typography($color-astronaut, normal, 14px);
    }

    &__box {
        width: 50%;
        padding: 35px;
        margin-top: 17px;
        border-radius: 5px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);

        &__text {
            width: 80%;
            @include global-typography($color-astronaut, normal, 12px);
        }

        &__container {
            width: 80%;
            margin-top: 12px;

            &__text {
                @include global-typography($color-astronaut, normal, 12px);
            }

            &__button {
                @include global-button-primary(35px, 100%);

                &__content {
                    width: 40%;
                }
    
                &__icon {
                    margin-right: 5px;
                }
            }
        }

    }

    &__attach {
        width: 70%;
        margin-top: 25px;
    }

    &__button-create-inventory {
        @include global-button-primary(100%, 35%);

        &:disabled {
            @include global-typography($color-white, bold, 12px);
        }

        &__content {
            width: 100%;
            height: 49px;
            margin-top: 25px;
        }
    }

    &__modal {

        &__content-footer {
            width: 100%;
            padding-bottom: 30px;
        }

        &__button {

            &__cancel {
                @include global-button-secondary(34px, 150px);
            }

            &__confirm {
                margin-left: 30px;
                @include global-button-primary(34px, 150px);

                &:disabled {
                    color: $color-white;
                }
            }
        }

        &__content {
            padding-bottom: 16px;
        }

        &__content-title {
            width: 100%;
            height: 40px;
            margin: 12px 0;
        }

        &__title {
            @include global-typography($color-carnation, bold, 16px);
        }

        &__text {
            width: 80%;
            margin: 10px 0;
            line-height: 18px;
            text-align: center;
            @include global-typography($color-astronaut, normal, 12px);
        }

        &__content-titles {
            width: 100%;
            height: 40px;
        }

        &__name-site {
            width: 60%;
            @include global-typography($color-astronaut, bold, 12px);
        }

        &__discard {
            margin-right: 5px;
            @include global-typography($color-astronaut, bold, 12px);

            &__content {
                width: 40%;
            }

            &__checkbox {
                &>.ant-checkbox-wrapper,
                &>.ant-checkbox-wrapper:hover {
                    &>.ant-checkbox {
                        &>.ant-checkbox-inner {
                            border: 1px solid $primary-colors;
                        }

                        &.ant-checkbox-checked {
                            &>span.ant-checkbox-inner {
                                background: $color-dodger-blue;
                                border: 1px solid $color-dodger-blue;
                            }

                            &::after {
                                border-color: transparent;
                            }
                        }
                    }
                }
            }
        }

        &__info {

            &__content {
                width: 100%;
                overflow: scroll;
                max-height: 200px;

                &::-webkit-scrollbar {
                    width: 0px;
                }
            }

            &__column {
                width: 100%;
                height: 40px;
                border-top: 1px solid $color-mercury;
            }

            &__site {

                &__content {
                    width: 60%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }

                &__text {
                    margin-left: 5px;
                    overflow: hidden;
                    line-height: 14px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    @include global-typography($color-astronaut, normal, 12px);
                }
            }

            &__discard {

                &__content {
                    width: 40%;
                }

                &__text {
                    margin-right: 5px;
                    @include global-typography($color-astronaut, bold, 12px);
                }

                &__checkbox {
                    &>.ant-checkbox-wrapper,
                    &>.ant-checkbox-wrapper:hover {
                        &>.ant-checkbox {
                            &>.ant-checkbox-inner {
                                border: 1px solid $primary-colors;
                            }

                            &.ant-checkbox-checked {
                                &>span.ant-checkbox-inner {
                                    background: $color-dodger-blue;
                                    border: 1px solid $color-dodger-blue;
                                }

                                &::after {
                                    border-color: transparent;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__date{
        width: 150px;
        margin-top: 10px;
        height: 30px;
        border: 1px solid $color-astronaut;
        background: $color-white;
        font-size: 18px;
        color: $color-athens-gray;

        &.ant-picker{
            &.ant-picker-disabled{
                border: 1px dashed $color-mercury;
                background: $color-white;
                
            }
            &>.ant-picker-input{
                &>input{
                    box-shadow: none;
                    @include global-typography($color-astronaut, bold, 10px);
                }
            }
        }
    }
}