@import "../../../../../shared/styles/styles.scss";

.headquarters-expenses {

    &__content-tables {
        margin-top: 50px;

        &__table {
            width: 100%;
            height: auto;
            max-height: 2030px;
            padding: 15px 12px;
            border-radius: 10px;
            @include global-box-shadow(0px, 3px, 20px);
            margin-top: 10px;
        }

        &__content-values {
            height: 35px;
        }

        &__title {
            margin: 0 12px;
            @include global-typography($color-astronaut, bold, 14px);
        }

        &__values {
            width: 150px;
            height: 30px;
            position: relative;

            &__content-icon {
                width: 30px;
                height: 30px;
                display: flex;
                position: absolute;
                align-items: center;
                justify-content: center;

                &__icon {
                    width: 80%;
                    height: 80%;
                    transform: rotate(180deg);
                }
            }

            .input-price {
                width: 100%;
                margin: 0px;
                height: 100%;
                cursor: auto;
                box-shadow: none;
                text-align: right;
                padding-left: 36px;
                border-radius: 6px;
                background: $color-athens-gray;
                border: 1px solid $color-surfie-green;
                @include global-typography($color-surfie-green, bold, 14px);

                &::placeholder {
                    color: $color-surfie-green;
                }
            }

            &--mod {
                @extend .headquarters-expenses__content-tables__values;

                .input-price {
                    border: 0px;
                    @include global-typography($color-astronaut, bold, 14px);

                    &::placeholder {
                        color: $color-astronaut;
                    }
                }
            }
        }

        &__content-info {
            height: 92%;

            &__content-title {
                height: 35px;
                width: 100%;
                padding-bottom: 4px;
            }

            &__title {
                @include global-typography($color-astronaut, bold, 12px);
            }

            &__content-scroll {
                width: 100%;
                height: auto;
                max-height: 1970px;
                overflow-y: scroll;

                &::-webkit-scrollbar {
                    width: 0px;
                }
            }

            &__description{
                @include global-typography($color-astronaut, bold, 12px);
                background-color: $color-athens-gray;
                overflow-x: scroll;
                white-space: nowrap;
                display: flex;
                scrollbar-width: none;
                -ms-overflow-style: none;
                padding: 7px;
                border-radius: 5px;

                &::-webkit-scrollbar {
                    width: 0px;
                }
    
                .input-price {
                    margin: 0;
                    width: 100%;
                    height: 18px;
                    border: none;
                    box-shadow: none;
                    text-align: right;
                    border-radius: 5px;
                    cursor: not-allowed;
                    background: $color-athens-gray;
                    @include global-typography($color-astronaut, bold, 12px);
                }
            }

            &__information-row {
                width: 100%;
                height: 45px;
            }
        }
        
        &__content-input {
    
            &>.ant-picker {
                width: 95%;
                margin: 0;
                padding: 0;
                height: 30px;
                border: none;
                border-radius: 5px;
                background: $color-athens-gray;
    
                &>.ant-picker-input {
                    &>input {
                        padding: 0 10px;
                        @include global-typography($color-astronaut, bold, 12px);
                    }
                }
            }
    
            &>.ant-input {
                width: 95%;
                margin: 0;
                height: 30px;
                border: none;
                padding: 0 10px;
                box-shadow: none;
                border-radius: 5px;
                background: $color-athens-gray;
                @include global-typography($color-astronaut, bold, 12px);
            }
    
            .input-price {
                margin: 0;
                width: 100%;
                height: 30px;
                border: none;
                box-shadow: none;
                text-align: right;
                border-radius: 5px;
                cursor: not-allowed;
                background: $color-athens-gray;
                @include global-typography($color-astronaut, bold, 12px);
            }

            &__disabled {
                @extend .headquarters-expenses__content-tables__content-input;

                &>.ant-picker {
                    background: $color-white;
                    border: 1px dashed $color-mercury;
        
                    &>.ant-picker-input {
                        &>input {
                            color: $color-mercury;
    
                            &::placeholder {
                                color: $color-mercury;
                            }
                        }
                    }
                }

                &>.ant-input {
                    color: $color-mercury;
                    background: $color-white;
                    border: 1px dashed $color-mercury;

                    &::placeholder {
                        color: $color-mercury;
                    }
                }

                .input-price {
                    color: $color-mercury;
                    background: $color-white;
                    border: 1px dashed $color-mercury;

                    &::placeholder {
                        color: $color-mercury;
                    }
                }
            }
        }
    }

    &__icon{
        border: 1px solid $color-astronaut;
        height: 30px;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;

        &--mod{
            border-color: $color-dodger-blue;
        }
    }

    &__table-content{

        &>.ant-spin-nested-loading{
            &>.ant-spin-container{
                &>.ant-table{
                    &>.ant-table-container{
                        &>.ant-table-content{
                            &>table {
                                &>.ant-table-thead{
                                    position: sticky;
                                    top: 0;
                                    z-index: 2;

                                    &>tr{
                                        &>.ant-table-cell{
                                            background-color: white;
                                            padding: 4px;
                                            @include global-typography($color-astronaut, bold, 12px);
                                            border: none;
                                        }
                                    }
                                }

                                &>.ant-table-tbody{
                                    &>.ant-table-row{
                                        &>.ant-table-cell{
                                            padding: 7px 3px;
                                            background-color: white;
                                            border: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
