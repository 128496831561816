@import '../../shared/styles/styles.scss';

.cash-closing-wholesales {

    &__container {
        padding: 35px;
    }

    &__title {
        @include global-typography($color-astronaut, bold, 16px);

        &__content {
            width: 100%;
            height: 76px;
        }
    }

    &__box {
        width: 50%;
        padding: 20px;
        margin-top: 10px;
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);

        &__content-titles {
            width: 100%;
            height: 20px;
        }

        &__button {
            margin: 0;

            &__icon {
                width: 15px;
                height: 15px;
                margin-right: 5px;

                &__active {
                    transform: rotate(180deg);
                    @extend .cash-closing-wholesales__box__button__icon;
                }
            }
        }

        &__title {
            line-height: 16px;
            @include global-typography($color-astronaut, bold, 12px);

            &__active {
                line-height: 16px;
                @include global-typography($color-dodger-blue, bold, 12px);
            }
        }

        &__content-information {
            width: 100%;
            margin-top: 20px;
            overflow: scroll;

            &::-webkit-scrollbar {
                width: 0px;
                display: none;
            }
        }

        &__info {
            width: 100%;
            height: 40px;
            padding: 0 5px;
            border-top: 1px solid $color-mercury;

            &__selected {
                width: 100%;
                height: 40px;
                padding: 0 5px;
                background: $color-athens-gray;
            }

            &__checkbox {
                margin-right: 10px;

                &>.ant-checkbox-wrapper,
                &>.ant-checkbox-wrapper:hover {  
                    &>.ant-checkbox {
                        &>.ant-checkbox-inner {
                            border: 1px solid $primary-colors;
                        }
                        
                        &.ant-checkbox-checked {
                            &>span.ant-checkbox-inner {
                                background: $color-dodger-blue;
                                border: 1px solid $color-dodger-blue;
                            }
    
                            &::after {
                                border-color: transparent;
                            }
                        }
                    }
                }
            }
    
            &__text {
                @include global-typography($color-astronaut, normal, 13px);
            }

            &__button {
                margin: 0;
                @include global-typography($color-dodger-blue, bold, 13px);

                &__icon {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }

}