@import "../../../../shared/styles/styles.scss";

.cash-closing-treasury {

    &__title{
        @include global-typography($color-astronaut,bold,18px);
    }

    &__subtitle{
        @include global-typography($color-astronaut,bold,16px);
    }

    &__text{
        @include global-typography($color-astronaut,normal,14px);
    }
    &__text-headquarter{
        @include global-typography($color-astronaut,bold,12px);
        max-width: 200px
    }
    &__cash-headquarter{
        @include global-typography($color-astronaut,bold,14px);
    }
    &__container-totalizer {
        border: 1px solid $color-mercury;
        border-radius: 5px;
        padding: 10px;
        margin: 15px 0;
    }
    &__container-cash-received {
        border-right: 1px solid $color-mercury;
    }
    &__container-cash-outflows {
        border-left: 1px solid $color-mercury;
    }
    &__content-cash-received {
        line-height: 18px;
    }
    &__content-cash-outflows {
        line-height: 18px;
    }
    &__cash-received{
        @include global-typography($color-surfie-green,normal,12px);
    }
    &__currency-received{
        @include global-typography($color-surfie-green,bold,16px);
    }
    &__cash-outflows{
        @include global-typography($color-carnation,normal,12px);
    }
    &__currency-outflows{
        @include global-typography($color-carnation,bold,16px);
    }
    &__icon-income, &__icon_egress{
        margin-right: 5px;
    }
    &__container-collapse{
        border: 2px solid $color-athens-gray;
        border-radius: 10px;
        margin-top: 15px;

        .ant-collapse, .ant-collapse-content, .ant-collapse-item {
            border: none;
        }
        .ant-collapse>.ant-collapse-item >.ant-collapse-header {
            background-color: $color-athens-gray;
            @include global-typography($color-astronaut,bold,14px);
        }
        .ant-collapse-content>.ant-collapse-content-box {
            padding: 0;
        }
    }
    &__total-cash-received{
        background-color: $color-athens-gray;
        width: 150px;
        position: relative;
        border-radius: 5px;
        margin-left: 15px;
    }

    &__content-icon-price {
        width: 30px;
        height: 30px;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        color: $color-astronaut;
    }

    &__input-price {
        margin: 0px;
        height: 30px;
        @include global-typography($color-astronaut, bold, 14px);
    }

    &__content-cash-headquearter {
        border-bottom: 1px solid $color-athens-gray;
        padding: 10px;
    }
    &__container-total { 
        margin: 15px 15px 25px 15px;
    }
    &.ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }
    & .ant-drawer-header {
        border: none;
    }
    & .ant-drawer-body {
        padding: 5px 24px 24px 24px;
    }
    & .ant-drawer-mask{
        z-index: 1;
    }
    & .ant-drawer-close {
        position: absolute;
        left: -12%;
        background-color: #FFFFFF;
        border-radius: 50%;
        padding: 8px;
        display: flex;
    }
    
}