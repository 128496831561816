@import "../../../../shared/styles/styles.scss";

.general-summary-by-headquarters {

    &__container {
        width: 100%;
        height: 460px;
        margin-bottom: 20px;
    }

    &__content-date {
        height: 15%;
        width: 100%;
    }

    &__text{
        @include global-typography($primary-colors, bold, 16px);
    }

    &__date {
        @include global-box-shadow(0px, 3px, 10px);
        width: 210px;
        height: 36px;
        padding: 0px 5px;
        margin-left: 10px;
        border-radius: 5px;
        background: $color-white;
        border: 1px solid $color-mercury;
    }

    &__arrow {
        transform: rotate(180deg);
    }

    &__month {
        &>.ant-picker-input {

            &>input {
                @include global-typography($primary-colors, bold, 12px);
                display: flex;
                cursor: pointer;
                overflow: hidden;
                text-align: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-transform: capitalize;
            }
        }

        &__popup {
            .ant-picker-cell {

                &>div.ant-picker-cell-inner {
                    text-transform: capitalize;
                }
            }
        }
    }

    &__table {

        &__content {
            height: 85%;
            width: 100%;
            padding: 10px 20px;
            border-radius: 10px;
            @include global-box-shadow(0px, 3px, 20px);
        }

        &__title {
            @include global-typography($primary-colors, bold, 16px);
        }

        &__subtitle {
            @include global-typography($primary-colors, normal, 12px);
        }

        &__content-info {
            flex: 1;
            width: 100%
        }
    }

    &__button-next {
        &:disabled {
            cursor: not-allowed;
        }
    }

    &__percentage {
        
        &__label{
            @include global-typography($color-astronaut, normal, 12px);

            &--mod{
                width: 80px;
            }
        }

        &__circle{
            border-radius: 100%;
            background: $color-carnation;
            height: 10px;
            width: 10px;
            margin: 5px 5px 0px 10px;

            &--green{
                background: $color-surfie-green;
            }

            &--blue{
                background: $color-dodger-blue;
            }
        }
    }

    &__select.ant-select{

        .ant-select-selector>.ant-select-selection-item{
            @include global-typography($color-dodger-blue, bold, 16px);
        }
    }

    &__button-text{
        @include global-typography($color-dodger-blue, bold, 12px);

        &__icon{
            height: 14px;
            width: 14px;

            &--mod{
                transform: rotate(180deg);
            }
        }

        &__content{
            height: 20px;

            &:disabled>span{
                color: $color-mischka;
            }
        }
    }

    &__graph{
        width: 100%;
        height: 20%;
    }

    &__tooltip{
        @include global-typography($color-surfie-green, bold, 14px);
        margin: 0;

        &__description{
            @include global-typography($color-surfie-green, normal, 8px);
            margin: 0;
        }

        &__content{
            width: 100px;
            height: 90px;
            @include global-box-shadow(0px, 3px, 20px);
            background: $color-white;
            border-radius: 5px;
        }
    }

}