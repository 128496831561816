@import '../../../../shared/styles/styles.scss';

.credits-we-owe-final-reconciliation {

    &__title {
        @include global-typography($color-astronaut, bold, 18px);

        &__content {
            width: 98%;
            height: 40px;
            border-radius: 5px;
            background: $color-athens-gray;
        }
    }

    &__box {
        width: 98%;
        margin: 20px 0px;
        padding: 30px 20px;
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);

        &__title {
            @include global-typography($color-astronaut, bold, 16px);
        }

        &__content-label {
            width: 100%;
            height: 38px;
        }

        &__input-concept {
            width: 49%;

            &.ant-input-affix-wrapper {
                height: 30px;
                padding: 4px;
                border: none;
                font-size: 10px;
                box-shadow: none;
                border-radius: 5px;
                background: $color-athens-gray;

                &>.ant-input {
                    margin: 0;
                    height: 20px;
                    line-height: 0;
                    box-shadow: none;
                    background: $color-athens-gray;
                    @include global-typography($color-astronaut, bold, 12px);

                    &::placeholder {
                        @include global-typography($color-astronaut, bold, 12px);
                    }
                }
            }

            &.ant-input-affix-wrapper-disabled {
                height: 30px;
                padding: 4px;
                border: none;
                font-size: 10px;
                box-shadow: none;
                background: $color-athens-gray;

                &.ant-input-affix-wrapper-disabled:hover {
                    background: $color-athens-gray;
                }

                &>.ant-input {
                    margin: 0;
                    height: 20px;
                    line-height: 0;
                    box-shadow: none;
                    background: $color-athens-gray;
                    @include global-typography($color-astronaut, bold, 12px);

                }
            }

            &__disabled {
                width: 49%;

                &.ant-input-affix-wrapper-disabled {
                    height: 30px;
                    padding: 4px;
                    font-size: 12px;
                    box-shadow: none;
                    background: $color-white;
                    border: 1px dashed $color-mercury;

                    &.ant-input-affix-wrapper-disabled:hover {
                        background: $color-white;
                    }

                    &>.ant-input {
                        margin: 0;
                        height: 20px;
                        line-height: 0;
                        box-shadow: none;
                        background: $color-white;
                        @include global-typography($color-astronaut, bold, 12px);

                        &::placeholder {
                            @include global-typography($color-mercury, bold, 12px);
                        }
                    }
                }
            }
        }

        &__input-price {
            width: 49%;
            height: 30px;

            .input-price {
                width: 100%;
                margin: 0px;
                height: 30px;
                box-shadow: none;
                text-align: right;
                border-radius: 5px;
                background: $white;
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, bold, 12px);

                &::placeholder {
                    @include global-typography($color-astronaut, bold, 12px);
                }

                &:focus {
                    box-shadow: none;
                    background: $white;
                    border: 1px solid $color-astronaut;
                }

                &:disabled {
                    margin: 0px;
                    width: 100%;
                    height: 30px;
                    border: none;
                    box-shadow: none;
                    text-align: right;
                    border-radius: 5px;
                    background: $color-athens-gray;
                    @include global-typography($color-astronaut, bold, 12px);

                    &::placeholder {
                        @include global-typography($color-astronaut, bold, 12px);
                    }
                }
            }

            &__optional {
                width: 86%;
                height: 30px;
                display: flex;
                position: relative;

                .input-price {
                    margin: 0px;
                    width: 100%;
                    height: 30px;
                    border: none;
                    box-shadow: none;
                    text-align: right;
                    border-radius: 5px;
                    padding-left: 36px;
                    background: $color-athens-gray;
                    @include global-typography($color-astronaut, bold, 12px);

                    &:focus {
                        border: none;
                        box-shadow: none;
                        background: $color-athens-gray;
                    }

                    &:disabled {
                        background: $color-white;
                        border: 1px dashed $color-mercury;
                        @include global-typography($color-mercury, bold, 12px);

                        &::placeholder {
                            @include global-typography($color-mercury, bold, 12px);
                        }
                    }
                }

            }
        }

        // Totalizers
        &__list-totalizers {
            width: 100%;
            margin-top: 10px;
            max-height: 230px;
            overflow-y: scroll;
            margin-bottom: 20px;

            &::-webkit-scrollbar {
                width: 0px;
                display: none;
            }
        }

        // Constants
        &__list-constants {
            width: 100%;
            max-height: 230px;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 0px;
                display: none;
            }
        }

        &__title-total {
            @include global-typography($color-astronaut, bold, 14px);

            &__content {
                width: 49%;
            }
        }

        // Optional
        &__aggregates-list {
            width: 100%;
            height: 280px;
            margin-top: 20px;
            overflow-y: scroll;
            border-bottom: 1px solid $color-mercury;

            &::-webkit-scrollbar {
                width: 0px;
                display: none;
            }
        }

        &__content-input-optional {
            width: 49%;
            height: 30px;
        }

        &__icon {
            width: 30px;
            height: 30px;
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;
        }

        &__button-deleted {
            margin: 0;
            width: 30px;
            height: 100%;
            border-radius: 5px;
            background: $color-alabaster;
            border: 1px solid $color-astronaut;

            &__container {
                width: 12%;
                height: 30px;
            }

            &__icon {
                width: 20px;
                height: 20px;
            }

            &:disabled {
                border: none;
                cursor: not-allowed;
            }
        }

        &__button-add {
            height: 40px;
            margin-top: 10px;
            @include global-typography($color-surfie-green, bold, 14px);

            &__text {
                margin-left: 6px;
            }

            &:disabled {
                cursor: not-allowed;
            }
        }

    }

    &__checkbox {
        &.ant-checkbox-wrapper,
        &.ant-checkbox-wrapper:hover {
            
            @include global-typography($primary-colors, bold, 12px);
            
            &>.ant-checkbox {
                &>.ant-checkbox-inner {
                    border: 1px solid $primary-colors;
                    width: 30px;
                    height: 30px;

                    &::after {
                        left: 35%;
                    }
                }

                &.ant-checkbox-checked {
                    &>span.ant-checkbox-inner {
                        background: $color-dodger-blue;
                        border: 1px solid $color-dodger-blue;
                        width: 30px;
                        height: 30px;
                    }

                    &::after {
                        border-color: transparent;
                    }
                }
            }
        }

        &.ant-checkbox-wrapper-disabled,
        &.ant-checkbox-wrapper-disabled:hover {
            &>.ant-checkbox.ant-checkbox-disabled {
                &>.ant-checkbox-inner {
                    border: none;
                }
            }
        }
    }
}