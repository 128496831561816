@import "../../../../../shared/styles/styles.scss";

.expense-account {

    &__container{
        width: 100%;
        height: 100%;
        margin-top: 40px;
        display: flex;
        justify-content: space-between;
    }

    &__content-box-subCategory{
        width: 60%;
        height: 550px;
        padding: 20px 5px;
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);
    }

    &__content-box-category{
        width: 38%;
        height: 550px;
        padding: 20px 5px;
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);
    }
    
    &__content-title {
        height: 5%;
        width: 100%;
        display: flex;
        padding-left: 14px;
        align-items: center;
    }

    &__title {
        margin-left: 5px;
        @include global-typography($color-astronaut, bold, 16px);
    }

    &__list-titles{

        &__content{
            width: 100%;
            height: 10%;
            display: flex;
            align-items: flex-end;
            flex-direction: row;
            padding: 0 0 14px 16px;
            margin-top: 10px;
        }

        &__content-check{
            display: flex;
            justify-content: space-around;
            text-align: center;
            width: 40%;
        }

        &__title-name {

            &__title-category{
                width: 100%;
                @include global-typography($primary-colors, bold, 12px);
            }

            &__title-subcategory{
                width: 50%;
                @include global-typography($primary-colors, bold, 12px);
            }
        }
    }

    &__content-info {
        width: 100%;
        height: 75%;
        padding: 0 10px;
        overflow: scroll;
    }

    &__span-selected{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        @include global-typography($primary-colors, bold, 18px);
    }

    &__info-row {
        width: 100%;
        padding: 12px 0 12px 5px;
        border-top: 1px solid $color-mercury;

        &__selected {
            background: $color-athens-gray;
            @extend .expense-account__info-row;
        }

        &__content-title{
            display: flex;
            align-items: center;
            width: 76%;
        }

        &__content-title-subcategory{
            display: flex;
            align-items: center;
            width: 50%;
        }

        &__content-check-subcategory{
            display: flex;
            justify-content: space-around;
            text-align: center;
            width: 40%;
            
            &>.ant-checkbox-wrapper{
                &>.ant-checkbox {
                    &>.ant-checkbox-inner {
                        border: 1px solid $primary-colors;
                    }
                }
            }
        }

        &__opl{
            width: 30%;
            height: 20px;

            &>.ant-checkbox-wrapper{
                &>.ant-checkbox {
                    &>.ant-checkbox-inner {
                        border: 1px solid $primary-colors;
                    }
                }
            }

            &__title{
                @include global-typography($primary-colors, bold, 12px);
            }

            &__content{
                width: 100%;
                height: 10%;
                display: flex;
                align-items: flex-end;
                flex-direction: row;
                padding: 0 10px 14px 16px;
                margin-top: 10px;
            }

            &__icon{
                width: 20px;
                height: 20px;
            }

            &__button-cancel{
                @include global-button-secondary(100%, 30px);
            }
    
            &__button-accept{
                @include global-button-primary(100%, 30px);
            }

            &__input{
                &.ant-input{
                    width: 30%;
                    height: 100%;
                    border-radius: 5px;
                    border: 1px solid $color-astronaut;
                    @include global-typography($color-astronaut, normal, 13px);
                }
            }

            &__select{
                width: 30%;

                &.ant-select{

                    &.ant-select-focused{
                        &>.ant-select-selector{
                            border: 1px solid $color-astronaut;
                        }
                    }
                    &>.ant-select-selector{
                        border: 1px solid $color-astronaut;

                        &>.ant-select-selection-item{
                            @include global-typography($color-astronaut, normal, 13px);
                        }
                    }
                }
            }

            &__check{
                width: 25%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 1.5%;

                &>.ant-checkbox-wrapper{
                    &>.ant-checkbox {
                        &>.ant-checkbox-inner {
                            border: 1px solid $primary-colors;
                        }
                    }
                }
            }

            &__buttons{
                width: 12%;
                display: flex;
                justify-content: space-between;
            }
        }

        &__content-edit{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10%;

            &__edit{
                width: 20px;
                height: 20px;
            }
        }

        &__content-icon{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10%;

            &__icon {
                width: 20px;
                height: 20px;
            }
        }

        &__text-site {
            line-height: 16px;
            overflow: hidden;
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            text-align: left;
            @include global-typography($primary-colors, normal, 13px);
        }
    }

    &__content-button-add{
        width: 100%;
        height: 10%;
        padding: 10px;
        justify-content: center;
    }

    &__content-input-add{
        width: 100%;
        height: 10%;
        padding: 10px;
        display: flex;
        justify-content: space-between;

        &__input{

            &.ant-input{
                width: 50%;
                height: 100%;
                border-radius: 5px;
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, normal, 13px);
            }
        }

        &__content-button{
            display: flex;
            justify-content: space-between;
            width: 45%;
        }

        &__button-cancel{
            @include global-button-secondary(100%, 45%);
        }

        &__button-accept{
            @include global-button-primary(100%, 45%);
        }
    }

    &__content-input-add-subCategory{
        width: 100%;
        height: 10%;
        padding: 10px;
        display: flex;
        justify-content: space-between;

        &__input{

            &.ant-input{
                width: 50%;
                height: 100%;
                border-radius: 5px;
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, normal, 13px);
            }
        }

        &__content-button{
            display: flex;
            justify-content: space-between;
            width: 45%;
        }

        &__button-cancel{
            @include global-button-secondary(100%, 45%);
        }

        &__button-accept{
            @include global-button-primary(100%, 45%);
        }
    }

    &__title-name-add{
        margin-right: 10px;
        @include global-typography($primary-colors, bold, 12px);
    }

    &__title-name-add-disabled{
        margin-right: 10px;
        @include global-typography($color-nobel, bold, 12px);
    }

    &__rules-provisions {

        &__box-rules-provisions {
            width: 60%;
            height: 550px;
            padding: 20px 5px;
            border-radius: 10px;
            background: $color-white;
            @include global-box-shadow(0px, 3px, 20px);
        }

        &__content-title {
            height: 5%;
            width: 100%;
            display: flex;
            padding-left: 14px;
            align-items: center;
        }
    
        &__title {
            margin-left: 5px;
            @include global-typography($color-astronaut, bold, 16px);
        }

        &__content-titles-box {
            width: 100%;
            height: 10%;
            display: flex;
            margin-top: 10px;
            flex-direction: row;
            align-items: flex-end;
            padding: 0 10px 14px 16px;
        }

        &__title-items {
            @include global-typography($primary-colors, bold, 12px);
        }

        &__content-row-items {
            width: 100%;
            padding: 12px 0 12px 5px;
            border-top: 1px solid $color-mercury;
        }

        &__text-items-rows {
            overflow: hidden;
            line-height: 14px;
            white-space: normal;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            @include global-typography($primary-colors, normal, 12px);
        }

        &__content-edit {
            width: 10%;
            display: flex;
            align-items: center;
            justify-content: center;

            &__button {
                width: 20px;
                height: 20px;
            }
        }

        &__content-row-add-rules-provisions {
            width: 100%;
            height: 10%;
            padding: 10px;
            display: flex;
            flex-direction: row;
        }

        &__content-category {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: flex-start
        }

        &__content-operation {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: center
        }

        &__content-constants {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: center
        }

        &__content-type {
            width: 15%;
            display: flex;
            align-items: center;
            justify-content: center
        }

        &__autocomplete{
            width: 100%;

            &.ant-select{
                &>.ant-select-selector{
                    height: 100%;
                }
            }
        }

        &__input-autocomplete{

            &.ant-select-dropdown{
                &>div{
                    &>.rc-virtual-list{
                        &>.rc-virtual-list-holder{
                            &>div{
                                &>.rc-virtual-list-holder-inner{
                                    &>.ant-select-item{
                                        align-items: center;
                                        &>.ant-select-item-option-content{
                                            @include global-typography($color-astronaut, bold, 10px);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &__input {

            &.ant-input{
                width: 100%;
                margin: 0px;
                height: 100%;
                border-radius: 5px;
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, normal, 13px);
            }
        }

        &__select{
            width: 100%;

            &.ant-select{

                &.ant-select-focused{
                    &>.ant-select-selector{
                        border: 1px solid $color-astronaut;
                    }
                }
                &>.ant-select-selector{
                    border: 1px solid $color-astronaut;

                    &>.ant-select-selection-item{
                        @include global-typography($color-astronaut, normal, 13px);
                    }
                }
            }
        }
        
        &__buttons {
            width: 10%;
            display: flex;
            padding-left: 5px;
            justify-content: space-between;
        }

        &__button-cancel {
            @include global-button-secondary(100%, 30px);
        }

        &__button-accept {
            @include global-button-primary(100%, 30px);
        }

        &__icon{
            width: 20px;
            height: 20px;
        }

        &__padding {
            padding: 0px 5px;

            &--first {
                padding-right: 5px;
            }
        }

    }
}