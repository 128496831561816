@import "../../shared/styles/styles.scss";

.dashboard {

    &__container {
        height: 100%;
        padding:  35px 35px 10px 46px;
    }

    &__content {
        height: 100%;
        padding: 0 16px 2px 16px;
        overflow: scroll;
        width: calc(100% - 380px);
        
        &::-webkit-scrollbar {
            width: 0px;
            display: none;
        }
    }

    &__box {
        height: 550px;
    } 
}