@import "../../../../../../shared/styles/styles.scss";

.valuation-of-cloud {

    &__container {
        width: 54%;
        height: 96%;
        padding: 20px;
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);
    }

    &__title {
        margin-left: 5px;
        @include global-typography($color-astronaut, bold, 16px);

        &__content {
            height: 6%;
            width: 100%;
        }
    }

    &__content-title-items {
        height: 8%;
        width: 100%;
    }

    &__title-items {
        line-height: 16px;
        @include global-typography($color-astronaut, bold, 12px);

        &__content {
            width: 34%;

            &__second {
                width: 22%;
            }
        }

        &__active {
            line-height: 16px;
            @include global-typography($color-dodger-blue, bold, 12px);
        }

        &__button {
            margin: 0;

            &__icon {
                width: 15px;
                height: 15px;
                margin-right: 5px;

                &__active {
                    transform: rotate(180deg);
                    @extend .valuation-of-cloud__title-items__button__icon;
                }
            }
        }
    }

    &__content-info {
        width: 100%;
        height: 76%;
        overflow: scroll;

        &::-webkit-scrollbar {
            width: 0px;
            display: none;
        }
    }

    &__info {
        width: 100%;
        height: 40px;
        border-top: 1px solid $color-mercury;

        &__text {
            @include global-typography($color-astronaut, normal, 12px);

            &__first-content {
                width: 34%;
            }

            &__second-content {
                width: 22%;
            }
        }
    }

    &__totalizers {

        &__content {
            width: 100%;
            height: 10%;
            border-top: 1px solid $color-mercury;
        }

        &__content-box {

            &__first {
                width: 24%;
                height: 30px;

                &__text {
                    @include global-typography($color-astronaut, bold, 14px);
                }
            }

            &__second {
                width: 23%;
                height: 30px;

                .input-price {

                    &:disabled {
                        width: 100%;
                        margin: 0px;
                        height: 100%;
                        border: none;
                        box-shadow: none;
                        text-align: left;
                        border-radius: 5px;
                        background: $color-athens-gray;
                        @include global-typography($color-astronaut, bold, 12px);

                        &::placeholder {
                            @include global-typography($color-astronaut, bold, 12px);
                        }
                    }
                }
            }
                
        }
    }
}