@import "../../../shared/styles/styles.scss";

.authorized-management {
    &__container {
        height: 100%;
        padding: 35px;
        width: 100%;
    }

    &__content-search {
        width: 100%;
        height: 70px;

        &__input {
            width: 30%;
            margin-right: 20px;

            &.ant-input-affix-wrapper {
                height: 40px;
                padding: 4px;
                box-shadow: none;
                background: $color-white;
                border: 1px solid $color-mischka;
                width: 70%;

                &>.ant-input {
                    margin: 0;
                    height: 30px;
                    box-shadow: none;
                    background: $color-white;
                    @include global-typography($color-astronaut, bold, 10px);

                    &::placeholder {
                        color: $color-astronaut;
                    }
                }
            }
        }

        &__select-bank {
            width: 140px;
            height: 40px;
            margin-right: 20px;
            border-radius: 6px;
            border: 1px solid $color-mischka;

            &__content-selected {
                width: 100%;
                height: 100%;
                cursor: pointer;
                border-radius: 6px;
            }

            &__text {
                margin-left: 10px;
                @include global-typography($primary-colors, bold, 12px);

                &__hover {
                    color: $color-dodger-blue;
                }
            }

            &__checkbox {
                &>.ant-checkbox-wrapper,
                &>.ant-checkbox-wrapper:hover {
                    @include global-typography($primary-colors, bold, 12px);
                    
                    &>.ant-checkbox {
                        &>.ant-checkbox-inner {
                            border: 1px solid $primary-colors;
                        }

                        &.ant-checkbox-checked {
                            &>span.ant-checkbox-inner {
                                background: $color-dodger-blue;
                                border: 1px solid $color-dodger-blue;
                            }

                            &::after {
                                border-color: transparent;
                            }
                        }
                    }
                }

            }
        }

        &__button {
            @include global-button-primary(40px, 200px);

            &__text {
                margin-left: 6px;
            }
        }

    }

    &__title{
      @include global-typography($color-astronaut, bold, 18px);
      margin-right: 20px; 
    }

    &__table{
        height: 550px;
        margin-top: 20px;
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);

        &__content{
            height: 50px;
            border-bottom: 1px solid $color-mischka;
        }

        &__title{
            @include global-typography($color-astronaut, bold, 12px);
        }

        &__text{
            @include global-typography($color-astronaut, normal, 12px);

            &__green{
                color: $color-surfie-green;
            }

            &__red{
                color: $color-carnation;
            }

            &__blue{
                color: $color-dodger-blue;
            }
        }

        &__checkbox {
            margin: 5px 20px;
            &>.ant-checkbox-wrapper,
            &>.ant-checkbox-wrapper:hover {
                &>.ant-checkbox {
                    &>.ant-checkbox-inner {
                        border: 1px solid $primary-colors;
                    }

                    &.ant-checkbox-checked {
                        &>span.ant-checkbox-inner {
                            background: $color-dodger-blue;
                            border: 1px solid $color-dodger-blue;
                        }

                        &::after {
                            border-color: transparent;
                        }
                    }
                }
            }
        }

        &__details{
            margin-left: 5px;
            height: 10px;
            width: 10px;
        }

        &__box{
            height: 90%;
            overflow-y: scroll;

            &::-webkit-scrollbar{
                width: 0px;
                display: none;
            }
        }
    }

    &__footer{
        margin-top: 20px;
    }
}