@import '../../../../shared/styles/styles.scss';

.table-opl {
    &__container {
        padding: 35px;

        &-headquarters {
            height: 540px;
            margin-top: 30px;
            padding: 20px 14px;
            border-radius: 10px;
            background: $color-white;
            @include global-box-shadow(0px, 3px, 20px);
        }
    }

    &__content-search {
        height: 60px;
        align-items: center;
        padding: 0 10px;

        &__title {
            @include global-typography($color-astronaut, bold, 16px);
        }

        &__input {
            width: 30%;
            margin-left: 20px;

            &.ant-input-affix-wrapper {
                height: 30px;
                padding: 4px;
                border: none;
                box-shadow: none;
                background: $color-athens-gray;

                &>.ant-input {
                    margin: 0;
                    height: 20px;
                    box-shadow: none;
                    background: $color-athens-gray;
                    @include global-typography($color-astronaut, bold, 10px);

                    &::placeholder {
                        color: $color-astronaut;
                    }
                }
            }
        }
    }

    &__content-result {
        height: calc(100% - 60px);
        padding: 0 10px;

        &__container-title {
            height: 30px;
            width: 100%;
        }

        &__title {
            @include global-typography($color-astronaut, bold, 12px);

            &__content{
                width: 14%;

                &--mod {
                    width: 26%;
                }
            }

            &--mod {
                width: 80%;
                @extend .table-opl__content-result__title;
            }
        }

        &__content-info {
            height: calc(100% - 30px);
            width: 100%;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 0px;
            }

            &__information-row {
                width: 100%;
                height: 40px;
                border-top: 1px solid $color-mercury;

                &__selected {
                    background: $color-athens-gray;
                    @extend .table-opl__content-result__content-info__information-row
                }
            }

            &__content-fragment {
                width: 14%;
                padding-right: 6px;

                &--mod {
                    width: 26%;
                }

                &--mod-second {
                    width: 18%;
                }
            }

            &__content-checkbox {
                width: 20%;
            }

            &__checkbox {
                &>.ant-checkbox-wrapper,
                &>.ant-checkbox-wrapper:hover {  
                    &>.ant-checkbox {
                        &>.ant-checkbox-inner {
                            border: 1px solid $primary-colors;
                        }
                        
                        &.ant-checkbox-checked {
                            &>span.ant-checkbox-inner {
                                background: $color-dodger-blue;
                                border: 1px solid $color-dodger-blue;
                            }

                            &::after {
                                border-color: transparent;
                            }
                        }
                    }
                }
            }

            &__content-location {
                width: 70%;

            }

            &__description {
                overflow-x: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @include global-typography($color-astronaut, bold, 12px);
            }

            &__content-status {
                height: 70%;
            }

            &__box-status {
                width: 70%;
                height: 100%;
                border-radius: 5px;

                &__active {
                    background: #0E7D801A;
                    @include global-typography($color-surfie-green, bold, 12px);
                    @extend .table-opl__content-result__content-info__box-status;
                }

                &__inactive {
                    background: #F85F611A;
                    @include global-typography($color-carnation, bold, 12px);
                    @extend .table-opl__content-result__content-info__box-status;
                }

                &__pin{
                    width: 10px;
                    height: 10px;
                    margin-right: 5px;
                    border-radius: 10px;

                    &__active{
                        background: $color-surfie-green;
                        @extend .table-opl__content-result__content-info__box-status__pin;
                    }

                    &__inactive{
                        background: $color-carnation;
                        @extend .table-opl__content-result__content-info__box-status__pin;
                    }
                }
            }

            &__button {
                margin: 0;
                @include global-typography($color-dodger-blue, bold, 12px);
            }
        }
    }
}