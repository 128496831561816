@import "../../../../shared/styles/styles.scss";

.transfers {

    &__download {
        margin-top: 35px;

        &__description {
            @include global-typography($color-astronaut, normal, 14px);
        }

        &__box {
            padding: 25px;
            margin-top: 17px;
            border-radius: 5px;
            background: $color-white;
            @include global-box-shadow(0px, 3px, 20px);
            height: 180px;
        }

        &__text {
            @include global-typography($color-astronaut, normal, 12px);
        }

        &__button {
            @include global-button-primary(35px, 100%);

            &__icon {
                margin-right: 5px;
            }

            &__text {
                @include global-typography($color-white, bold, 12px);
            }
        }

        &__button-create-inventory {
            @include global-button-primary(49px, 390px);
        }

        &__container {
            margin-top: 20px;
        }
    }

    &__container {
        margin-top: 30px;
    }

    &__button-add {
        @include global-typography($color-white, bold, 16px);
    }

    &__attach {
        margin-top: 35px;
    }

    &__modal {

        &__content {
            padding-bottom: 16px;
        }

        &__content-title {
            width: 100%;
            height: 40px;
            margin: 12px 0;
        }

        &__title {
            @include global-typography($color-carnation, bold, 16px);
        }

        &__text {
            width: 80%;
            line-height: 18px;
            text-align: center;
            margin: 10px 0;
            @include global-typography($color-astronaut, normal, 12px);
        }

        &__content-titles {
            width: 100%;
            height: 40px;
            border-bottom: 1px solid $color-mercury;
        }

        &__name-site {
            @include global-typography($color-astronaut, bold, 12px);
        }

        &__discard {
            margin-right: 5px;
            @include global-typography($color-astronaut, bold, 12px);

            &__checkbox {

                &>.ant-checkbox-wrapper,
                &>.ant-checkbox-wrapper:hover {
                    &>.ant-checkbox {
                        &>.ant-checkbox-inner {
                            border: 1px solid $primary-colors;
                        }

                        &.ant-checkbox-checked {
                            &>span.ant-checkbox-inner {
                                background: $color-dodger-blue;
                                border: 1px solid $color-dodger-blue;
                            }

                            &::after {
                                border-color: transparent;
                            }
                        }
                    }
                }
            }
        }

        &__info {

            &__content {
                width: 100%;
                overflow: scroll;
                max-height: 200px;

                &::-webkit-scrollbar {
                    width: 0px;
                }
            }

            &__column {
                width: 100%;
                height: 40px;
                border-bottom: 1px solid $color-mercury;

                &--mod{
                    border-bottom: 0px;
                }
            }

            &__site {

                &__content {
                    width: 60%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                }

                &__text {
                    margin-left: 5px;
                    overflow: hidden;
                    line-height: 14px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    @include global-typography($color-astronaut, normal, 12px);
                }
            }

            &__discard {

                &__content {
                    width: 40%;
                }

                &__text {
                    margin-right: 5px;
                    @include global-typography($color-astronaut, bold, 12px);
                }

                &__checkbox {

                    &>.ant-checkbox-wrapper,
                    &>.ant-checkbox-wrapper:hover {
                        &>.ant-checkbox {
                            &>.ant-checkbox-inner {
                                border: 1px solid $primary-colors;
                            }

                            &.ant-checkbox-checked {
                                &>span.ant-checkbox-inner {
                                    background: $color-dodger-blue;
                                    border: 1px solid $color-dodger-blue;
                                }

                                &::after {
                                    border-color: transparent;
                                }
                            }
                        }
                    }
                }
            }


        }

        &__content-footer {
            width: 100%;
            padding-bottom: 30px;
        }

        &__button {

            &__cancel {
                @include global-button-secondary(34px, 150px);
            }

            &__confirm {
                margin-left: 30px;
                @include global-button-primary(34px, 150px);

                &:disabled {
                    color: $color-white;
                }
            }
        }
    }

    &__payment{

        &__content{
            margin-bottom: 15px;
        }
        
        &__content-input-price {
            width: 100%;
            position: relative;
    
            .input-price {
                width: 100%;
                margin: 0px;
                height: 30px;
                box-shadow: none;
                text-align: right;
                padding-left: 36px;
                border-radius: 6px;
                background: $color-white;
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, bold, 14px);
    
                &:focus {
                    box-shadow: none;
                    border: 1px solid $color-astronaut;
                }
    
                &::placeholder {
                    color: $color-astronaut;
                }
    
                &:disabled {
                    border: 1px dashed $color-mercury;
    
                    &::placeholder {
                        color: $color-mercury;
                    }
                }
            }

            &--green{

                .input-price:disabled::placeholder {
                    color: $color-surfie-green;
                }

                .input-price,
                .input-price:disabled {
                    border: 1px solid $color-surfie-green;
                    @include global-typography($color-surfie-green, bold, 14px);
                    background: $color-athens-gray;
                }

            }

            &--red{

                .input-price:disabled::placeholder {
                    color: $color-carnation;
                }

                .input-price,
                .input-price:disabled {
                    border: 1px solid $color-carnation;
                    @include global-typography($color-carnation, bold, 14px);
                    background: $color-athens-gray;
                }
            }

            &--disabled{
                .input-price:disabled::placeholder {
                    color: $color-astronaut;
                }

                .input-price,
                .input-price:disabled {
                    border: 0;
                    @include global-typography($color-surfie-green, bold, 14px);
                    background: $color-athens-gray;
                }
            }
        }

        &__content-icon-price {
            width: 30px;
            height: 30px;
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;

        }
        
        &__icon {
            width: 20px;
            height: 20px;
        }
    }

    &__loading {
        width: 100%;
        height: 100%;

        &__text {
            margin-top: 10px;
            @include global-typography($color-dodger-blue, bold, 16px);
        }
    }
}
