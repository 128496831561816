@import "../../../../shared/styles/styles.scss";

.quotation-by-headquarter {

    &__container {
        width: 100%;
        height: 100%;
        padding: 10px 20px;
        border-radius: 10px;
        @include global-box-shadow(0px, 3px, 20px);
    }

    &__content {
        width: 100%;
        height: 10%;
    }

    &__title {
        @include global-typography($primary-colors, bold, 16px);
    }

    &__subtitle {
        @include global-typography($primary-colors, normal, 12px);
    }

    &__select {

        &.ant-select {
            width: 145px;
            height: 30px;

            &>.ant-select-selector {
                height: 30px;
                background: $color-white;
                border: 1px solid $color-astronaut;
                font-size: 10px;

                &>.ant-select-selection-item {
                    @include global-typography($color-astronaut, bold, 10px);
                }

                &>.ant-select-selection-placeholder {
                    @include global-typography($color-astronaut, bold, 10px);
                }
                &:hover {
                    border: 1px solid $color-astronaut;
                }
            }

            &>.ant-select-arrow {
                inset-inline-end: 0px;
            }

        }

        &__icon {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }

        &__popup {
            .ant-select-item-option-content {
                font-size: 10px;
                font-weight: bold;
                color: $color-astronaut;
            }
        }

    }

    &__content-graph-register {
        width: 100%;
        height: 37%;
        margin-left: 12px;
        overflow-y: scroll;
    }

    &__box {
        width: 30%;
        height: 55px;
        padding: 10px;
        display: flex;
        margin: 5px 5px 5px 0;
        border-radius: 5px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        border: 2px solid $color-surfie-green;

        &--mod {
            border: 2px solid $color-carnation;
            @extend .quotation-by-headquarter__box;
        }
    }

    &__value {
        margin-left: 5px;
        @include global-typography($color-surfie-green, bold, 14px);

        &--mod {
            margin-left: 5px;
            @include global-typography($color-carnation, bold, 14px);
        }
    }

    &__text {
        line-height: 14px;
        @include global-typography($color-surfie-green, normal, 12px);

        &--mod {
            @include global-typography($color-carnation, normal, 12px);
        }
    }
    
    &__content-text-area {
        width: 100%;
        height: 53%;
        padding-top: 10px;

        &__title {
            @include global-typography($color-astronaut, bold, 16px);
        }

        &__input {
            width: 100%;
            height: 100%;

            &.ant-input {
                height: 85%;
                width: 100%;
                resize: none;
                padding: 10px;
                margin-top: 6px;
                box-shadow: none;
                background: $color-white;
                border: 2px solid $color-astronaut;
                @include global-typography($color-astronaut, normal, 16px);

                &:hover {
                    border: 2px solid $color-dodger-blue;
                }

                &:focus {
                    border: 2px solid $color-astronaut;
                }

                &::placeholder {
                    @include global-typography($color-astronaut, normal, 14px);
                }
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .quotation-by-headquarter {

        &__box {
            width: 48%;
        }
    }
}