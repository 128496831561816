@import "../../../styles/styles.scss";

.navbar-main{

    &__container-welcome {
        justify-content: center;
        vertical-align: middle;
        margin-left: 50px;
    }

    &__title {
        @include global-typography($color-astronaut,bold,18px);

        &__info{
            @include global-typography($color-astronaut,normal,12px);
        }
    }

    &__content{
        height: 60px;
        @include global-box-shadow(3px, 3px, 20px);
        background: $color-white;
        width: calc(100% - 200px);
        @include global-typography($color-astronaut,bold,12px);
    }

    &__button{
        
        &__sidebar{
            width: 200px;
            padding: 0px 25px;

            &__text{
                @include global-typography($color-astronaut, bold, 18px);
            }
        }
    }

    &__user{
        padding: 0px 25px;

        &__name{
            margin-left: 10px;
        }

        &__content{
            margin-left: 20px;
        }
    }
}