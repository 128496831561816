@import "../../../../shared/styles/styles.scss";

.monthly-closing-wholesaler {

    &__title{
        @include global-typography($color-astronaut,bold,18px);
    }
    &__subtitle{
        @include global-typography($color-astronaut,bold,16px);
    }
    &__text{
        @include global-typography($color-astronaut,normal,14px);
    }
    &__text-wholesaler{
        @include global-typography($color-astronaut,bold,12px);
        max-width: 200px
    }
    &__cash-wholesaler{
        @include global-typography($color-astronaut,bold,14px);
    }
    &__content-final-cash{
        margin-bottom: 15px;
    }
    &__container-totalizer {
        border: 1px solid $color-mercury;
        border-radius: 5px;
        padding: 10px;
        margin: 15px 0;
    }
    &__container-cash-received {
        border-right: 1px solid $color-mercury;
    }
    &__container-cash-expenses {
        border-left: 1px solid $color-mercury;
    }
    &__content-cash-received {
        line-height: 18px;
    }
    &__content-cash-expenses {
        line-height: 18px;
    }
    &__cash-received{
        @include global-typography($color-surfie-green,normal,12px);
    }
    &__currency-received{
        @include global-typography($color-surfie-green,bold,16px);
    }
    &__cash-expenses{
        @include global-typography($color-carnation,normal,12px);
    }
    &__currency-expenses{
        @include global-typography($color-carnation,bold,16px);
    }
    &__icon-income, &__icon_egress{
        margin-right: 5px;
    }
    &__container-collapse{
        border: 2px solid $color-athens-gray;
        border-radius: 10px;
        margin-top: 15px;

        .ant-collapse, .ant-collapse-content, .ant-collapse-item {
            border: none;
        }
        .ant-collapse>.ant-collapse-item >.ant-collapse-header {
            background-color: $color-athens-gray;
            @include global-typography($color-astronaut,bold,14px);
        }
        .ant-collapse-content>.ant-collapse-content-box {
            padding: 0;
        }
    }
    &__total-cash-received{
        background-color: $color-athens-gray;
        width: 150px;
        position: relative;
        border-radius: 5px;
        margin-left: 15px;
    }
    &__content-icon-price {
        width: 30px;
        height: 30px;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        color: $color-astronaut;
    }
    &__input-price {
        margin: 0px;
        padding: 8px;
        height: 30px;
        @include global-typography($color-astronaut, bold, 14px);
    }

    &__content-cash-wholesaler {
        border-bottom: 1px solid $color-athens-gray;
        padding: 10px;
    }
    &__container-total { 
        margin: 15px 10px 25px 10px;

        &__type {
            @include global-typography($color-surfie-green, bold, 14px);

            &--mod {
                @include global-typography($color-carnation, bold, 14px);
            }
            &--informative {
                @include global-typography($color-dodger-blue-hover, bold, 14px);
            }
    
            &__name{
                margin-left: 6px;
            }
        }
    }
    &.ant-drawer-content {
        border-radius: 10px 0px 0px 10px;
    }
    & .ant-drawer-header {
        border: none;
    }
    & .ant-drawer-body {
        padding: 5px 24px 24px 24px;
        margin-bottom: 45px;
    }
    & .ant-drawer-mask{
        z-index: 1;
    }
    & .ant-drawer-close {
        position: absolute;
        left: -12%;
        background-color: #FFFFFF;
        border-radius: 50%;
        padding: 8px;
        display: flex;
    }
    &__content-info {
        max-height: 300px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 0px;
        }
    }
    &__table {
        padding: 10px 10px 0px 10px;

        &__content-inputs {
            width: 95%;
            position: relative;
        }
        &__content-icon-price {
            width: 30px;
            height: 30px;
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;
        }
        .input-price {
            width: 100%;
            margin: 0px;
            height: 30px;
            box-shadow: none;
            text-align: right;
            padding-left: 36px;
            border-radius: 6px;
            background: $color-white;
            border: 1px solid $color-astronaut;
            @include global-typography($color-astronaut, bold, 12px);

            &::placeholder {
                @include global-typography($color-astronaut, bold, 12px);
            }

            &:disabled {
                border: 1px dashed $color-mercury;
                @include global-typography($color-mercury, bold, 12px);

                &::placeholder {
                    color: $color-mercury
                }

                &:hover {
                    border: 1px dashed $color-mercury;
                }
            }

            &:hover {
                border: 1px solid #4096ff;
            }
        }
    }
    &__button-delete {
        margin: 0;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background: $color-alabaster;
        border: 1px solid $color-astronaut;

        &__icon {
            width: 20px;
            height: 20px;
        }

        &:disabled {
            border: none;
            cursor: not-allowed;
        }
    }
    &__input {
        width: 95%;

        &.ant-input-affix-wrapper {
            height: 30px;
            border: 1px solid $color-astronaut;
            background: $color-white;
            padding: 4px;
            @include global-typography($color-astronaut, normal, 12px);

            &>.ant-input {
                height: 20px;
                margin: 0;
                line-height: 0;
                box-shadow: none;
                background: $color-white;
                @include global-typography($color-astronaut, bold, 12px);

                &::placeholder {
                    @include global-typography($color-astronaut, normal, 12px);
                }
            }
        }

        &.ant-input-affix-wrapper-disabled {

            height: 30px;
            border: 1px dashed $color-mercury;
            background: $color-white;

            &>.ant-input {
                height: 20px;
                margin: 0;
                line-height: 0;
                box-shadow: none;

                &::placeholder {
                    color: $color-mercury
                }
            }
        }

        &__text-right {

            &.ant-input-affix-wrapper {
                &>.ant-input {
                    text-align: right;
                }
            }
        }

        &__solid {
            &.ant-input-affix-wrapper {
                height: 30px;
                border: none;
                background: $color-athens-gray;
                font-size: 10px;
                padding: 4px;

                &>.ant-input {
                    height: 20px;
                    margin: 0;
                    line-height: 0;
                    box-shadow: none;
                }
            }

            &__text-right {

                &.ant-input-affix-wrapper {
                    &>.ant-input {
                        text-align: right;
                        background: $color-athens-gray;
                    }
                }
            }
        }
    }
    &__container-button {
        position: absolute;
        bottom: 0;
        background: $color-white;
        width: 100%;
        left: 0;

        &__save {
            @include global-button-primary(35px, 48%);
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 15px 0;
        }
    }
    &__checkbox {
        &>.ant-checkbox-wrapper,
        &>.ant-checkbox-wrapper:hover {
            @include global-typography($primary-colors, bold, 12px);
            
            &>.ant-checkbox {
                &>.ant-checkbox-inner {
                    border: 1px solid $primary-colors;
                    width: 20px;
                    height: 20px;
                }

                &.ant-checkbox-checked {
                    &>span.ant-checkbox-inner {
                        background: $color-dodger-blue;
                        border: 1px solid $color-dodger-blue;
                        width: 20px;
                        height: 20px;
                    }

                    &::after {
                        border-color: transparent;
                    }
                }
            }
        }

        &>.ant-checkbox-wrapper-disabled,
        &>.ant-checkbox-wrapper-disabled:hover {
            &>.ant-checkbox {
                &>.ant-checkbox-inner {
                    background: $white;
                    border: 1px dashed $color-mercury;
                }
            }
        }
    }

}