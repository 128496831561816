@import "../../../../shared/styles/styles.scss";

.transfer-valuation {

    &__box{
        @include global-box-shadow(0px, 3px, 20px);
        background: $color-white;
        border-radius: 10px;
    }

    &__totals{
        padding: 20px;
        width: 410px;

        &__table{
            border-radius: 8px;
            border: 1px solid $color-mischka;
            height: 70px;
        }

        &__title{
            width: calc(50% - 0.5px);
            border-bottom: 0.5px solid $color-mischka;
            text-align: center;
            @include global-typography($color-astronaut, bold, 16px);
            
            &__right{
                border-right: 0.5px solid $color-mischka;
            }

            &__left{
                border-left: 0.5px solid $color-mischka;
            }
        }

        &__info{
            width: calc(50% - 0.5px);
            border-top: 0.5px solid $color-mischka;
            text-align: center;
            @include global-typography($color-astronaut, normal, 12px);

            &__right{
                border-right: 0.5px solid $color-mischka;
            }

            &__left{
                border-left: 0.5px solid $color-mischka;
            }
        }

        &__label{
            @include global-typography($color-astronaut, bold, 16px);
            margin-bottom: 15px;
        }
    }

    &__list{
        margin-left: 20px;
        height: 550px;

        &__title{
            @include global-typography($color-astronaut, bold, 16px);
            margin-left: 5px;

            &__content{
                margin-bottom: 12px;
            }
        }

        &__label{
            @include global-typography($color-astronaut, bold, 12px);
            
            &--filter{
                margin-left: 5px;
            }

            &--name{
                font-weight: normal;
            }
        }

        &__content{
            border-bottom: 1px solid $color-mischka;
            padding-bottom: 10px;
        }

        &__item{
            padding: 10px 0;
            border-bottom: 1px solid $color-mischka;
        }

        &__container{
            height: 80%;
            overflow-y: scroll;

            &::-webkit-scrollbar{
                width: 0px;
                overflow: hidden;
            }
        }

        &__filter{
            transform: rotate(180deg);
        }
    }
    

    &__results{

        &__label{
            @include global-typography($color-astronaut, bold, 14px);
        }

        &__number{
            @include global-typography($color-astronaut, bold, 12px);
            background: $color-athens-gray;
            height: 30px;
            border-radius: 5px;
        }

        &__content{
            margin-top: 10px;
        }
    }
}