@import '../../shared/styles/styles.scss';

.final-reconciliation {
    
    &__container {
        width: 100%;
        height: 100%;
        padding: 35px;
    }

    &__content-tables {
        width: 100%;
        margin-top: 40px;
    }

    &__content-loading-view {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        &__text {
            margin-top: 10px;
            @include global-typography($color-dodger-blue, bold, 16px);
        }
    }
}