@import "../../../../shared/styles/styles.scss";

.file-upload {

    &__content {
        width: 45%;
        height: 190px;
    }

    &__box{
        height: 100%;
        border-radius: 5px;
        border: 1px dashed $color-astronaut;

        &__active{
            height: 100%;
            border: none;
            display: flex;
            padding-top: 20px;
            border-radius: 5px;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background: $color-white;
            @include global-box-shadow(0px, 3px, 20px);
        }

        &__visibility {
            display: none;
        }

        &__upload {
            &.ant-upload-wrapper {
                width: 100%;
                height: 100%;

                &>.ant-upload.ant-upload-select {
                    width: 100%;
                    height: 100%;

                    &>.ant-upload {
                        &>button {
                            width: 100%;
                            height: 100%;
                            border: none;
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        &__title{
            @include global-typography($color-astronaut, bold, 16px);
        }

        &__text{
            @include global-typography($color-astronaut, normal, 12px);
        }

        &__info{
            width: 85%;
            height: 50%;
        }

        &__name-file{
            @include global-typography($color-astronaut, bold, 14px);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            
            &--mod{
                color: $color-dodger-blue;
                @extend .file-upload__box__name-file;
            }
        }

        &__size-file{
            @include global-typography($color-mischka, bold, 10px);
        }

        &__progress{
            &>.ant-progress-inner{
                &>.ant-progress-text{
                    @include global-typography($color-astronaut, bold, 12px);
                }
            }
        }

        &__content-cancel{
            width: 85%;
            height: 25%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            border-top: 1px solid $color-mischka;

            &__button{
                @include global-typography($color-carnation, bold, 12px);
            }
        }
    }

    &__box-disabled {
        height: 100%;
        border-radius: 5px;
        border: 1px dashed $color-mischka;

        &__upload {
            &.ant-upload-wrapper {
                width: 100%;
                height: 100%;

                &>.ant-upload.ant-upload-select {
                    width: 100%;
                    height: 100%;

                    &>.ant-upload {
                        &>button {
                            width: 100%;
                            height: 100%;
                            border: none;
                            background: $color-white;
                        }
                    }
                }
            }
        }

        &__title{
            @include global-typography($color-mischka, bold, 16px);
        }

        &__text{
            @include global-typography($color-mischka, normal, 12px);
        }

    }
}