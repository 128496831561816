//COLORS
$color-box-shadow: #0000001A;
$color-white: #FFFFFF;
$color-astronaut: #265575;
$color-dodger-blue: #1499F2;
$color-dodger-blue-hover: #2EABFF;
$color-bahama-blue: #0766A6;
$color-mischka: #D6D8E2;
$color-mercury: #E2E2E2;
$color-alabaster:#FAFAFA;
$color-surfie-green: #0E7D80;
$color-surfie-green-light: #0E7D8034;
$color-carnation :#F85F61;
$color-carnation-light :#F85F6134;
$color-nobel: #B7B7B7;
$color-athens-gray: #F3F4F6;
$color-iceberg: #CFF1E6;
$color-pink: #FFC3D0;
$color-scorpion: #5C5C5C;
$color-titan-white: #F0F0FF;
$color-sunset-orange :#F85F61;



//FUNCTIONS
@mixin global-box-shadow($offsetX , $offsetY, $intensity) {
    box-shadow: $offsetX  $offsetY $intensity $color-box-shadow;
}

// TYPOGRAPHY
@mixin global-typography($color:null,$weight:null,$size:null) {
    font-style: normal;
    font-variant: normal;
    @if $weight {
        font-weight: $weight;
    }
    @if $size {
        font-size: $size;
    }
    @if $color {
        color: $color;
    }
}

// BUTTONS

@mixin global-button-primary($height:auto,$width:auto) {
    background: $color-dodger-blue;
    border-radius: 6px;
    height: $height;
    width: $width;
    @include global-typography($color-white,bold,12px);

    
    &:hover{
        background: $color-dodger-blue-hover;
    }
    
    &:active{
        background: $color-bahama-blue;
    }
    
    &:disabled{
        cursor: not-allowed;
        color: $color-astronaut;
        background: $color-mischka;
    }
}
@mixin global-button-secondary($height:auto,$width:auto) {
    background: $color-white;
    border-radius: 6px;
    height: $height;
    width: $width;
    border: 2px solid $color-astronaut;
    @include global-typography($color-astronaut,bold,12px);

    
    &:hover{
        border-color: $color-dodger-blue;
        color: $color-dodger-blue;
    }
    
    &:active{
        background: $color-dodger-blue;
        color: $white;
    }
    
    &:disabled{
        cursor: not-allowed;
        color: $color-mischka;
        background: $color-white;
        border-color: $color-mischka;
    }
    

}

//Global

$global-font-size: 100%;
$global-width: rem-calc(1200);
$global-lineheight: 1.5;
$global-margin: 1rem;
$global-padding: 1rem;
$global-position: 1rem;
$global-weight-normal: normal;
$global-weight-bold: bold;
$global-radius: 0;
$global-menu-padding: 0.7rem 1rem;
$global-menu-nested-margin: 1rem;
$global-text-direction: ltr;
$global-button-cursor: auto;
$global-color-pick-contrast-tolerance: 0;

//Colors

$primary-colors: #214C69;
$secondary-colors: #767676;
$success-colors: #3adb76;
$warning-colors: #ffae00;
$alert-colors: #cc4b37;
$light-gray: #e6e6e6;
$medium-gray: #cacaca;
$dark-gray: #8a8a8a;
$black: #0a0a0a;
$white: #fefefe;

//Body

$body-background: $white;
$body-font-color: $black;
$body-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;


//Accordion

$accordion-background: $white;
$accordion-title-font-size: rem-calc(12);
$accordion-item-color: $primary-colors;
$accordion-item-background-hover: $light-gray;
$accordion-item-padding: 1.25rem 1rem;
$accordion-content-background: $white;
$accordion-content-border: 1px solid $light-gray;
$accordion-content-color: $body-font-color;
$accordion-content-padding: 1rem;

//Badge

$badge-background: $primary-colors;
$badge-color: $white;
$badge-color-alt: $black;
$badge-padding: 0.3em;
$badge-minwidth: 2.1em;
$badge-font-size: 0.6rem;

//Button

$button-font-family: inherit;
$button-padding: 0.85em 1em;
$button-margin: 0 0 $global-margin 0;
$button-fill: solid;
$button-background: $primary-colors;
$button-background-hover: scale-color($button-background, $lightness: -15%);
$button-color: $white;
$button-color-alt: $black;
$button-radius: $global-radius;
$button-border: 1px solid transparent;
$button-hollow-border-width: 1px;
$button-opacity-disabled: 0.25;
$button-background-hover-lightness: -20%;
$button-hollow-hover-lightness: -50%;
$button-transition: background-color 0.25s ease-out, color 0.25s ease-out;

//Card

$card-background: $white;
$card-font-color: $body-font-color;
$card-divider-background: $light-gray;
$card-border: 1px solid $light-gray;
$card-shadow: none;
$card-border-radius: $global-radius;
$card-padding: $global-padding;
$card-margin-bottom: $global-margin;


//Dropdown

$dropdown-padding: 1rem;
$dropdown-background: $body-background;
$dropdown-border: 1px solid $medium-gray;
$dropdown-font-size: 1rem;
$dropdown-width: 300px;
$dropdown-radius: $global-radius;


//Label

$label-background: $primary-colors;
$label-color: $white;
$label-color-alt: $black;
$label-font-size: 0.8rem;
$label-padding: 0.33333rem 0.5rem;
$label-radius: $global-radius;
