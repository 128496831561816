@import '../../../../shared/styles/styles.scss';

.header-control {

    &__container {
        width: 100%;
        margin-bottom: 20px;
    }

    &__link-and-calendar {
        width: 100%;
        height: 36px;

        &__link {
            @include global-typography($color-dodger-blue, bold, 12px);
        }

        &__icon {
            margin-left: 5px;
        }

        &__name {
            margin-left: 5px;
            @include global-typography($color-astronaut, bold, 12px);
        }

        &__name-calendar {
            @include global-typography($color-astronaut, bold, 12px);
        }

        &__calendar {
            width: 240px;
            height: 36px;
            padding: 0px 16px;
            margin-left: 30px;
            border-radius: 5px;
            background: $color-white;
            @include global-box-shadow(0px, 3px, 20px);
        }

        &__arrow {
            transform: rotate(180deg);
        }

        &__month {
            &>.ant-picker-input {
                &>input {
                    @include global-typography($primary-colors, bold, 12px);
                    display: flex;
                    cursor: pointer;
                    overflow: hidden;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    text-transform: capitalize;
                }
            }
        }

        &__button-next {

            &:disabled {
                cursor: not-allowed;
            }

        }
    }

    &__tabs-and-buttons {
        width: 100%;
        margin-top: 20px;

        &__content-tabs {
            width: 65%;

            &__container {
                height: 60px;
                display: flex;
                max-width: 95%;
                padding: 0 10px;
                overflow-x: auto;
                border-radius: 5px;
                flex-direction: row;
                align-items: center;
                background: $color-athens-gray;
                
                &::-webkit-scrollbar {
                    width: 0px;
                    display: none;
                }
            }

            &__tab-button {
                height: 40px;
                display: flex;
                padding: 10px 20px;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                &__active {
                    height: 40px;
                    display: flex;
                    padding: 10px 20px;
                    border-radius: 5px;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    background: $color-white;
                    @include global-box-shadow(0px, 3px, 20px);
                }

                &__text {

                    &__active {
                        @include global-typography($color-astronaut, bold, 12px);
                    }
    
                    &__inactive {
                        @include global-typography($color-mischka, bold, 12px);
                    }
    
                    &__hover {
                        @include global-typography($color-dodger-blue-hover, bold, 12px);
                    }
                }

                &__icon {
                    width: 20px;
                    height: 20px;
                    margin-right: 2px;
                }
                
            }
        }

        &__content-buttons {
            width: 35%;

            &__button {

                &__save {
                    @include global-button-secondary(35px, 48%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &__publish {
                    display: flex;
                    margin-left: 10px;
                    align-items: center;
                    justify-content: center;
                    @include global-button-primary(35px, 48%);
                }
            }
        }
    }

    &__week-and-button {
        width: 100%;
        margin-top: 20px;

        &__week {
            width: 85%;
        }

        &__button-content {
            width: 15%;

            &__button {
                @include global-button-primary(40px, 80%);
            }
        }
    }

}