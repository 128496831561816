@import "../../../shared/styles/styles.scss";

.opl-wallet-detail {
  padding: 35px;

  &__container {
    padding: 35px;
  }

  &__name {
    margin-left: 10px;
    @include global-typography($color-astronaut, bold, 14px);
  }

  &__content-header {
    height: 76px;

    &__title {
      width: 30%;
    }

    &__link {
      margin-left: 6px;
      @include global-typography($color-dodger-blue, bold, 16px);
    }

    &__totalizers {
      margin-left: 40px;
    }

    &__box {
      padding: 10px;
      border-radius: 5px;
      background: $color-white;
      @include global-box-shadow(0px, 3px, 20px);

      &:last-of-type {
        margin-left: 10px;
      }

      &__padding-box {
        padding: 0 10px;
      }
    }

    &__text {
      @include global-typography($color-astronaut, bold, 10px);
    }

    &__details {
      height: 30px;
      border-radius: 5px;
      background: $color-athens-gray;

      .input-price {
        width: 100%;
        margin: 0px;
        height: 100%;
        cursor: auto;
        box-shadow: none;
        text-align: right;
        border-radius: 5px;
        background: $color-athens-gray;
        border: 1px solid $color-athens-gray;
        @include global-typography($color-astronaut, bold, 14px);

        &::placeholder {
          color: $color-astronaut;
        }
      }

      &__button-download {
        @include global-button-primary(34px, 130px);
      }
    }
  }

  &__content-options {
    width: 100%;
    height: 60px;
    margin-top: 14px;

    &__content-buttons {
      height: 100%;
      padding: 0 30px;
      border-radius: 5px;
      background: $color-athens-gray;

    }

    &__buttons {
      margin: 0;
      width: 145px;
      height: 40px;

      &__active {
        border-radius: 5px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);
        @extend .opl-wallet-detail__content-options__buttons;
      }

      &__text {

        &__active {
          @include global-typography($color-astronaut, bold, 12px);
        }

        &__inactive {
          @include global-typography($color-mischka, bold, 12px);
        }

        &__hover {
          @include global-typography($color-dodger-blue-hover, bold, 12px);
        }
      }
    }

    &__button-save {
      display: flex;
      align-items: center;
      justify-content: center;
      @include global-button-secondary(40px, 190px);
    }

    &__button-publish {
      display: flex;
      margin-left: 10px;
      align-items: center;
      justify-content: center;
      @include global-button-primary(40px, 190px);
    }

    &__icon-button {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }

  }

  &__content-info {
    width: 100%;
    margin-top: 40px;
  }
}