@import "../../../../shared/styles/styles.scss";

.packages {

    &__subtitle {
        @include global-typography($color-astronaut, bold, 12px);
    }

    &__summary {

        &__line {
            height: 1px;
            width: 100%;
            background: $color-mischka;

            &--mod {
                margin: 10px 0;
            }
        }

        &__content {
            overflow-y: scroll;
            max-height: 285px;

            &::-webkit-scrollbar {
                width: 0px;
            }
        }
    }

    &__products {

        &__container {
            padding-top: 10px;
        }

        &__input {
            width: 95%;

            &.ant-input-affix-wrapper {
                
                height: 30px;
                border: 1px solid $color-astronaut;
                background: $color-white;
                padding: 4px;

                &>.ant-input {
                    height: 20px;
                    margin: 0;
                    line-height: 0;
                    box-shadow: none;
                    @include global-typography($color-astronaut, bold, 12px);

                    &::placeholder {
                        @include global-typography($color-astronaut, normal, 12px);
                    }
                }
            }

            &.ant-input-affix-wrapper-disabled {

                height: 30px;
                border: 1px dashed $color-mercury;
                background: $color-white;

                &>.ant-input {
                    height: 20px;
                    margin: 0;
                    line-height: 0;
                    box-shadow: none;
                    @include global-typography($color-mercury, bold, 12px);

                    &::placeholder {
                        color: $color-mercury
                    }
                }

                &:hover {
                    background: $color-white;
                }
            }

            &--mod{
                &.ant-input-affix-wrapper-disabled {

                    height: 30px;
                    border: none;
                    background: $color-athens-gray;
    
                    &>.ant-input {
                        height: 20px;
                        margin: 0;
                        line-height: 0;
                        box-shadow: none;
                        @include global-typography($color-astronaut, bold, 10px);
    
                        &::placeholder {
                            color: $color-mercury
                        }
                    }
    
                    &:hover {
                        background: $color-athens-gray;
                    }
                }
            }
        }

        &__content-input-price {
            width: 100%;
            position: relative;

            .input-price {
                width: 95%;
                margin: 0px;
                height: 30px;
                box-shadow: none;
                text-align: right;
                padding-left: 36px;
                border-radius: 6px;
                background: $color-white;
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, bold, 12px);

                &:focus {
                    box-shadow: none;
                    border: 1px solid $color-astronaut;
                }

                &::placeholder {
                    color: $color-astronaut;
                }

                &:disabled {
                    
                    border: 1px solid $color-astronaut;
                    @include global-typography($color-astronaut, bold, 12px);

                    &:disabled {
                        border: 1px dashed $color-mercury;

                        &::placeholder {
                            color: $color-mercury;
                        }
                    }
                }
                &.solid{
                    border: none;
                    background: $color-athens-gray;

                    &:disabled {
                        border: 1px solid $color-athens-gray;
                    }
                }
            }

            &.ant-input-wrapper {
                height: 30px;
                font-size: 12px;
                padding: 4px;
                background: $color-white;
                border: 1px solid $color-astronaut;

                &>.ant-input {
                    height: 20px;
                    margin: 0;
                    line-height: 0;
                    box-shadow: none;
                    @include global-typography($color-astronaut, bold, 12px);

                    &::placeholder {
                        color: $color-astronaut;
                    }
                }
            }
        }

        &__content-icon-price {
            width: 30px;
            height: 30px;
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;

        }

        &__icon {
            width: 20px;
            height: 20px;
        }
    }

    &__button-income {
        @include global-typography($color-surfie-green, bold, 14px);
        margin-left: 10px;

        &__button-delete {
            margin: 0;
            width: 30px;
            height: 30px;
            border-radius: 5px;
            background: $color-alabaster;
            border: 1px solid $color-astronaut;

            &__icon {
                width: 20px;
                height: 20px;
            }

            &:disabled {
                border: none;
                cursor: not-allowed;
            }
        }
    }
}