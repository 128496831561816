@import "../../shared/styles/styles.scss";

.final-balance {

    &__container {
        padding: 35px;
        height: 100%;
    }
    &__box{
        padding: 20px 0;
    }

    &__summary{

        &__box{
            @include global-box-shadow(0px,3px,20px);
            background: $color-white;
            padding: 10px;
            border-radius: 5px;
            width: 95%;
        }
        &__details{
            background: $color-alabaster;
            border-radius: 5px;
            height: 30px;
        }
        &__text{
            @include global-typography($color-astronaut,bold,10px);
        }

        &__price{
            @include global-typography($color-astronaut,bold,14px);
            padding-right: 5px;
        }

        &__button-file{
            @include global-button-primary(35px, 30%);
            display: flex;
            align-items: center;  
            justify-content: center;
        }

        &__icon-publish {
            height: 20px;
            margin-left: 4px;
        }
    }

    &__content-tables {
        width: 100%;
        margin-top: 30px;
    }

    &__content-title-table {
        width: 98%;
        height: 40px;
        border-radius: 5px;
        background: $color-athens-gray;
    }

    &__title-table {
        @include global-typography($color-astronaut, bold, 18px);
    }
    
    &__button-save {
        @include global-button-secondary(35px, 40%);

        &__icon-save {
            height: 20px;
            margin-right: 3px
        }
    }
}