@import "../../../styles/styles.scss";

.sidenav-container {
    position: relative;
    @include global-box-shadow(3px, 20px, 20px);
    background-color: $color-white;
    height: 100%;

    &__title{
        position: absolute;
        top: 38px;
        @include global-typography($color-astronaut,bold,14px);
        width: 100%;
        text-align: left;
        padding-left: 33px;
    }

    &__sidenav-main {
        height: 80%;
        overflow-y: auto; 
        width: 200px; 
        margin-top: 70px;

        &::-webkit-scrollbar{
            width: 0px;
            display: none;
        }

        &.ant-menu-root.ant-menu-inline.ant-menu-light > .ant-menu-item.ant-menu-item-active:hover > .ant-menu-title-content{
            color: $color-dodger-blue;
        }
        & .ant-menu-item >.ant-menu-title-content {
            @include global-typography($color-astronaut,bold,14px);
        }
        &.ant-menu-inline.ant-menu-root .ant-menu-submenu-title {
            color: $color-astronaut;

            &>.ant-menu-title-content {
                @include global-typography($color-astronaut,bold,14px);
            }
        }
        & .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open > .ant-menu-submenu-title {
            color: $color-dodger-blue;
            background-color: $color-athens-gray;
            border-left: 5px solid $color-dodger-blue;
        }
        & .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-title-content {
            color: $color-dodger-blue;
        }
        
    }
    &__menu-button {
        padding: 15px 0px 15px 34px;
        height: 50px;
        width: 100%;
        @include global-typography($color-astronaut,bold,14px);
        background: $color-white;
        display: flex;
        align-items: center;

        &__icon-logout {
            margin-right: 10px;
        }
    }
}