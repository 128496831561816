@import '../../../shared/styles/styles.scss';

.headquarters {

    &__container {
        padding: 35px;

        &-headquarters {
            height: 550px;
            margin-top: 30px;
            padding: 20px 14px;
            border-radius: 10px;
            background: $color-white;
            @include global-box-shadow(0px, 3px, 20px);
        }
    }
    &__title {
        @include global-typography($color-astronaut, bold, 16px);
        margin-right: 20px;
    }
    &__content-date-picker {
        width: 40%;

        &__title {
            @include global-typography($color-astronaut, bold, 16px);
        }

        &__date {
            width: 240px;
            height: 36px;
            padding: 0px 16px;
            margin-left: 30px;
            border-radius: 5px;
            background: $color-white;
            @include global-box-shadow(0px, 3px, 20px);
        }

        &__arrow {
            transform: rotate(180deg);
        }

        &__month {
            &>.ant-picker-input {
                &>input {
                    display: flex;
                    cursor: pointer;
                    overflow: hidden;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    text-transform: capitalize;
                    @include global-typography($primary-colors, bold, 12px);
                }
            }
        }

        &__button-next {
            &:disabled {
                cursor: not-allowed;
            }
        }
    }
    &__container-search{
        width: 40%;
        margin-bottom: 10px;
    }
    &__content-search {
        width: 100%;
        height: 170px;

        &__input {
            width: 60%;
            margin-right: 20px;

            &.ant-input-affix-wrapper {
                height: 40px;
                padding: 4px;
                box-shadow: none;
                background: $color-white;
                border: 1px solid $color-mischka;

                &>.ant-input {
                    margin: 0;
                    height: 30px;
                    box-shadow: none;
                    background: $color-white;
                    @include global-typography($color-astronaut, bold, 12px);

                    &::placeholder {
                        color: $color-astronaut;
                    }
                }
            }
        }

        &__select-headquarter {
            width: 15%;
            height: 40px;
            border-radius: 6px;
            border: 1px solid $color-mischka;

            &__filter-icon{
                width: 15px;
                height: 15px;

                &__active {
                    transform: rotate(180deg);
                    @extend .headquarters__content-search__select-headquarter__filter-icon;
                }
            }

            &__content-selected {
                width: 100%;
                height: 100%;
                cursor: pointer;
                border-radius: 6px;
            }

            &__text {
                margin-left: 5px;
                @include global-typography($primary-colors, bold, 12px);

                &__hover {
                    color: $color-dodger-blue;
                    @extend .headquarters__content-search__select-headquarter__text;
                }
            }

            &__filter {
                width: 20%;
                text-align: center;
            }
        }
    }
    &__content-result {
        height: 100%;
        padding: 0 10px;

        &__container-title {
            height: 6%;
            width: 100%;
        }

        &__title {
            @include global-typography($color-astronaut, bold, 12px);

            &__content{
                width: 14%;

                &--mod {
                    width: 17%;
                }
                &--mod-second {
                    width: 13%;
                }
            }

            &--mod {
                width: 80%;
                @extend .headquarters__content-result__title;
            }
        }

        &__content-info {
            height: 94%;
            width: 100%;
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 0px;
            }

            &__information-row {
                width: 100%;
                height: 40px;
                border-top: 1px solid $color-mercury;

                &__selected {
                    background: $color-athens-gray;
                    @extend .headquarters__content-result__content-info__information-row
                }
            }

            &__content-fragment {
                width: 14%;

                &--mod {
                    width: 17%;
                }

                &--mod-second {
                    width: 13%;
                }
            }

            &__content-checkbox {
                width: 20%;
            }

            &__checkbox {
                &>.ant-checkbox-wrapper,
                &>.ant-checkbox-wrapper:hover {  
                    &>.ant-checkbox {
                        &>.ant-checkbox-inner {
                            border: 1px solid $primary-colors;
                        }
                        
                        &.ant-checkbox-checked {
                            &>span.ant-checkbox-inner {
                                background: $color-dodger-blue;
                                border: 1px solid $color-dodger-blue;
                            }

                            &::after {
                                border-color: transparent;
                            }
                        }
                    }
                }
            }

            &__content-location {
                width: 70%;

            }

            &__description {
                overflow-x: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @include global-typography($color-astronaut, bold, 12px);

                &--execution {
                    @extend .headquarters__content-result__content-info__description;
                    color: $color-surfie-green;
                }
                &--compliance {
                    @extend .headquarters__content-result__content-info__description;
                    color: $color-carnation;
                }
            }

            &__content-status {
                height: 70%;
            }

            &__box-status {
                width: 70%;
                height: 100%;
                border-radius: 5px;

                &__active {
                    background: #0E7D801A;
                    @include global-typography($color-surfie-green, bold, 12px);
                    @extend .headquarters__content-result__content-info__box-status;
                }

                &__inactive {
                    background: #F85F611A;
                    @include global-typography($color-carnation, bold, 12px);
                    @extend .headquarters__content-result__content-info__box-status;
                }

                &__pin{
                    width: 10px;
                    height: 10px;
                    margin-right: 5px;
                    border-radius: 10px;

                    &__active{
                        background: $color-surfie-green;
                        @extend .headquarters__content-result__content-info__box-status__pin;
                    }

                    &__inactive{
                        background: $color-carnation;
                        @extend .headquarters__content-result__content-info__box-status__pin;
                    }
                }
            }

            &__button {
                margin: 0;
                @include global-typography($color-dodger-blue, bold, 12px);
            }
        }
    }

    &__spinner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__input-percentage{

        &.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled{

            cursor: initial;
            height: 30px;
            border: 1px solid $color-carnation;
            background-color: $color-carnation-light;
            color: $color-carnation;
            width: 85px;
            font-weight: bold;
    
            &>.ant-input.ant-input-disabled{
                cursor: initial;
                height: 20px;
                color: $color-carnation;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: center;
                font-weight: bold;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
            }
    
            &:hover{
                cursor: initial;
                height: 30px;
                border: 1px solid $color-carnation;
                background-color: $color-carnation-light;
                color: $color-carnation;
                width: 85px;
                font-weight: bold;
    
                &>.ant-input{
                    cursor: initial;
                    height: 20px;
                    color: $color-carnation;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    text-align: center;
                    font-weight: bold;
                }
            }
        }

        &__green.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled{
            cursor: initial;
            height: 30px;
            border: 1px solid $color-surfie-green;
            background-color: $color-surfie-green-light;
            color: $color-surfie-green;
            width: 85px;
            font-weight: bold;

            &>input.ant-input.ant-input-disabled{
                cursor: initial;
                height: 20px;
                color: $color-surfie-green;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                text-align: center;
                font-weight: bold;
                box-shadow: none;
                margin: 0;
                background-color: transparent;
            }

            &:hover{
                cursor: initial;
                height: 30px;
                border: 1px solid $color-surfie-green;
                background-color: $color-surfie-green-light;
                color: $color-surfie-green;
                width: 85px;
                font-weight: bold;

                &>.ant-input{
                    cursor: initial;
                    height: 20px;
                    color: $color-surfie-green;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    text-align: center;
                    font-weight: bold;
                }
            }
        }
    }
}