@import "../../../shared/styles/styles.scss";

.edit-or-add-user {

    &__container {
        padding: 35px;
    }

    &__link {
        @include global-typography($color-dodger-blue, bold, 16px);
    }

    &__icon-arrow {
        margin: 0 10px;
    }

    &__name {
        @include global-typography($color-astronaut, bold, 14px);

        &__hover {
            @include global-typography($color-dodger-blue, bold, 14px);
        }     
    }

    &__container-table {
        width: 100%;
        margin-top: 50px;
    }

    &__first-table {
        width: 100%;
        padding: 20px 30px;
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);

        &__content {
            width: 30%;
            margin-right: 20px;
        }

        &__container-profile {
            width: 100%;
            height: 80px;
            padding-left: 12px;
            border-bottom: 1px solid $color-mercury;
        }

        &__icon-text {
            width: 60px;
            height: 60px;
            margin-right: 10px;
            border-radius: 50%;
            background: #cfedfc;

            &__text {
                @include global-typography($color-dodger-blue, bold, 24px);
            }
        }

        &__image {
            width: 60px;
            height: 60px;
            margin-right: 10px;
            border-radius: 50%;
        }

        &__name {
            @include global-typography($color-astronaut, bold, 16px);
        }

        &__occupation {
            @include global-typography($color-astronaut, normal, 12px);
        }

        &__info {
            width: 100%;
            height: 40px;

            &__container {
                width: 100%;
                height: 250px;
                display: flex;
                padding-left: 12px;
                flex-direction: column;
                justify-content: space-evenly;
                border-bottom: 1px solid $color-mercury;
            }

            &__icon {
                width: 22px;
                height: 22px;
                margin-right: 6px;
            }

            &__title {
                @include global-typography($color-astronaut, normal, 12px);
            }

            &__description {
                @include global-typography($color-astronaut, bold, 14px);

                &__disabled {
                    @include global-typography($color-mercury, bold, 14px);
                }
            }
        }

        &__check {

            &>.ant-checkbox-wrapper,
            &>.ant-checkbox-wrapper:hover {
                &>.ant-checkbox {
                    &>.ant-checkbox-inner {
                        border: 1px solid $primary-colors;
                    }

                    &.ant-checkbox-disabled{
                        &>.ant-checkbox-inner {
                            background: $color-white;
                            border: 1px solid $color-mercury;
                        }
                    }

                    &.ant-checkbox-checked {
                        &>span.ant-checkbox-inner {
                            background: $color-dodger-blue;
                            border: 1px solid $color-dodger-blue;
                        }

                        &::after {
                            border-color: transparent;
                        }
                    }
                }
            }

            &__container {
                width: 100%;
                height: 50px;
                padding-left: 12px;
                border-bottom: 1px solid $color-mercury;
            }

            &__title {
                margin-left: 6px;
                @include global-typography($color-astronaut, normal, 12px);

                &__disabled {
                    margin-left: 6px;
                    @include global-typography($color-mercury, normal, 12px);
                }
            }
        }

        &__edit-permission {

            &__container {
                width: 100%;
                height: 60px;
                display: flex;
                padding-left: 12px;
                flex-direction: column;
                justify-content: flex-end;
            }

            &__button {
                height: 40px;
                width: 116px;
                border-radius: 5px;
                background: $color-white;
                @include global-box-shadow(0px, 3px, 20px);

                &:disabled {
                    box-shadow: none;
                    cursor: not-allowed;
                }
            }

            &__title {
                @include global-typography($color-astronaut, bold, 10px);

                &__hover {
                    @include global-typography($color-dodger-blue, bold, 10px);
                }

                &__disabled {
                    @include global-typography($color-mercury, bold, 10px);
                }
            }
        }
    }

    &__second-table {
        width: 100%;
        height: 610px;
        padding: 20px 30px;
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);

        &__content {
            width: 55%;
        }

        &__title {
            @include global-typography($color-astronaut, bold, 16px);
        }

        &__form-container {
            width: 100%;
            margin: 10px 0 30px 0;
        }

        &__content-box {
            width: 100%;
            height: 150px;
        }
        
        &__container-photo {

            &__uploaded {
                width: 49%;
                height: 140px;
                padding: 16px;
                border-radius: 5px;
                border: 1px solid $color-astronaut;
            }

            &__empty {
                width: 49%;
                height: 140px;
                border-radius: 5px;
                border: 1px solid $color-mercury;

                &__container {
                    width: 100%;
                    height: 100%;
                }

                &__upload {
                    width: 100%;
                    height: 100%;

                    &>.ant-upload.ant-upload-select {
                        width: 100%;
                        height: 100%;
                    }

                    &__content {
                        width: 100%;
                        height: 100%;
                    }
                }

                &__icon-text {
                    width: 90px;
                    height: 90px;
                    border-radius: 50%;
                    background: #cfedfc;

                    &__text {
                        @include global-typography($color-dodger-blue, bold, 35px);
                    }
                }

                &__content-text {
                    width: 60%;
                    height: 100%;
                }

                &__title {
                    @include global-typography($color-dodger-blue, bold, 14px);
                }

                &__description {
                    width: 70%;
                    line-height: 12px;
                    @include global-typography($color-astronaut, normal, 10px);
                }
            }
        }

        &__photo-profile {
            width: 90px;
            height: 90px;
            border-radius: 50%;
        }

        &__container-buttons {
            width: 56%;
            height: 100%;
            display: flex;
            padding-left: 8%;
            flex-direction: column;
            justify-content: space-between;
        }

        &__button {
            width: 100%;
            height: 40px;
            border-radius: 5px;
            background: $color-white;
            @include global-box-shadow(0px, 3px, 20px);

            &>.ant-upload.ant-upload-select {
                width: 100%;
                height: 100%;
            }

            &__content {
                width: 100%;
                height: 100%;
            }
        }

        &__text-button {
            margin-left: 5px;
            @include global-typography($color-astronaut, bold, 10px);
        }

        &__text-delete {
            margin-left: 5px;
            @include global-typography($color-carnation, bold, 10px);
        }

        &__container-inputs-names {
            width: 49%;
            height: 100%;
        }

        &__content-input {
            width: 49%;
            height: 70px;

            &__aux {
                width: 100%;
                height: 70px;
            }

            &__label {
                @include global-typography($color-astronaut, bold, 14px);

                &__required {
                    margin-left: 5px;
                    @include global-typography($color-carnation, bold, 14px);
                }

                &__content {
                    margin-bottom: 4px;
                }
            }

            &__input {

                &.ant-input {
                    margin: 0;
                    width: 100%;
                    height: 30px;
                    line-height: 0;
                    box-shadow: none;
                    border-radius: 5px;
                    background: $color-white;
                    border: 1px solid $color-astronaut;
                    @include global-typography($color-astronaut, normal, 14px);

                    &::placeholder {
                        @include global-typography($color-mercury, normal, 14px);
                    }

                    &:focus {
                        box-shadow: none;
                        border: 1px solid $color-astronaut;
                    }

                    &.ant-input-disabled {
                        border: 1px solid $color-mercury;
                        @include global-typography($color-mercury, normal, 14px);

                        &::placeholder {
                            color: $color-mercury
                        }
                    }
                }
            }
            
            &__text-error {
                @include global-typography($color-carnation, normal, 10px);
            }

            &__empty {
                &.ant-input {
                    border: 1px solid $color-mercury;
                }
                @extend .edit-or-add-user__second-table__content-input__input
            }
        }

        &__content-update-password {
            width: 100%;
            height: 90px;
            align-items: flex-end;
            justify-content: space-between;
            border-top: 1px solid $color-mercury;

            &__input-password {

                &.ant-input-password {
                    width: 100%;
                    height: 30px;
                    border: none;
                    box-shadow: none;
                    border-radius: 5px;
                    background-color: $color-athens-gray;

                    &>input {
                        height: 20px;
                        padding: 0 8px;
                        font-size: 14px;
                        box-shadow: none;
                        font-weight: bold;
                        color: $primary-colors;
                        background-color: $color-athens-gray;

                        &:focus {
                            background-color: $color-athens-gray;
                        }
            
                        &::placeholder {
                            color: $primary-colors;
                            font-weight: $global-weight-bold;
                        }
                    }
            
                    .anticon.ant-input-password-icon {
                        color: $primary-colors;
                    }
                }

                &__icon {
                    width: 25px;
                    height: 25px;
                    display: flex;
                    border-radius: 5px;
                    align-items: center;
                    justify-content: center;
                    background: $color-white;
                }

            }
        }

        &__content-button-save {
            width: 100%;
            height: 100px;
            display: flex;
            align-items:flex-end;
            justify-content: flex-end;

            &__button {
                @include global-button-primary(34px, 170px);

                &:disabled{
                    color: $color-white;
                }
            }
        }

        &__spinner {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__permissions {

        &__content {
            width: 100%;
            height: 100%;
        }

        &__title-container {
            width: 100%;
            height: 14%;
        }

        &__title {
            @include global-typography($color-astronaut, bold, 16px);
        }

        &__description {
            @include global-typography($color-astronaut, normal, 12px);
        }

        &__container-list-checks {
            width: 100%;
            height: 74%;
            overflow: scroll;
            padding-left: 15%;
        }

        &__check {
            &>.ant-checkbox-wrapper,
            &>.ant-checkbox-wrapper:hover {
                &>.ant-checkbox {
                    &>.ant-checkbox-inner {
                        border: 1px solid $primary-colors;
                    }

                    &.ant-checkbox-checked {
                        &>span.ant-checkbox-inner {
                            background: $color-dodger-blue;
                            border: 1px solid $color-dodger-blue;
                        }

                        &::after {
                            border-color: transparent;
                        }
                    }
                }
            }

            &__text {
                margin-left: 10px;
                @include global-typography($color-astronaut, normal, 12px);
            }
        }
        
        &__container-tree.ant-tree {
            background: transparent;

            .ant-tree-switcher {
                width: 20px;
            }

            .ant-tree-switcher.ant-tree-switcher_open{
                display: none;
            }

            .ant-tree-indent {
                display: none;
            }

            .ant-tree-treenode.ant-tree-treenode-switcher-open.ant-tree-treenode-leaf-last,
            .ant-tree-treenode.ant-tree-treenode-switcher-open {
                padding: 0;
            }
            
            .ant-tree-checkbox-indeterminate>.ant-tree-checkbox-inner:after {
                background: $color-white;
            }

            .ant-tree-checkbox>.ant-tree-checkbox-inner {
                border: 1px solid $primary-colors;

                &:hover {
                    border: 1px solid $primary-colors;
                }
            }

            .ant-tree-checkbox-checked {
                .ant-tree-checkbox-inner {
                    background: $color-dodger-blue;
                    border: 1px solid $color-dodger-blue;

                    &:hover {
                        border: 1px solid $color-dodger-blue;
                    }
                }
            }
            
            .ant-tree-title {
                @include global-typography($color-astronaut, normal, 12px);
            }
        }

        &__content-button {
            width: 100%;
            height: 12%;
            display: flex;
            align-items:flex-end;
            justify-content: flex-end;
        }

        &__content-button-save {
            width: 100%;
            height: 12%;
            display: flex;
            align-items:flex-end;
            justify-content: flex-end;

            &__button {
                @include global-button-primary(34px, 170px);

                &:disabled{
                    color: $color-white;
                }
            }
        }
    }
}