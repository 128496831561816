@import "../../../../../shared/styles/styles.scss";

.general-adjustment {
    &__container {
        height: 100%;
        padding: 35px;
        width: 100%;
    }

    &__content {
        width: 98%;
        margin-bottom: 20px;
    }

    &__box {
        background: $color-athens-gray;
        width: 98%;
        height: 40px;
        border-radius: 5px;
    }

    &__title{
        @include global-typography($color-astronaut,bold,18px);
    }
}