@import '../../../shared/styles/styles.scss';

.validate-code {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../../assets/shared/icons/icon_logo.png");

    &__main-container {
        padding: 5em;
        height: 100vh;
        display: flex;
        background: $white;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        &__welcome-text {
            width: 400px;
            padding: 40px 0;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: $primary-colors;
            font-weight: $global-weight-bold;
        }
    }

    &__container {
        width: 450px;
        height: 480px;
        display: flex;
        background: $white;
        padding: 30px 30px;
        border-radius: 10px;
        flex-direction: column;
        justify-content: space-between;
        box-shadow: 0px 3px 20px #0000001A;
    }

    &__title {
        font-size: 28px;
        color: $primary-colors;
        font-weight: $global-weight-bold;
    }

    &__text {
        width: 89%;
        font-size: 14px;
        margin-top: 10px;
        line-height: 18px;
        text-align: center;
        color: $primary-colors;
        font-weight: $global-weight-normal;
    }

    &__subtitle {
        @include global-typography($primary-colors, bold, 16px);
    }

    &__pinInput {

        &__container {
            height: 55px;
            display: flex;
            margin-top: 20px;
            align-items: center;
            flex-direction: column;
        }

        &__error {
            font-size: 10px;
            text-align: center;
            color: $alert-colors;
        }
    }

    &__ProgressCountDown {
        @include global-typography($primary-colors, bold, 14px);
    }

    &__content-button {

        &>button {
            border-radius: 5px;
        }

        &__question {
            font-size: 14px;
            margin-bottom: 15px;
            color: $primary-colors;
            font-weight: $global-weight-normal;
        }

        &__button {
            width: 100%;
            height: 49px;
            border: 2px solid $color-astronaut;
            @include global-typography($color-astronaut, bold, 16px);

            &:disabled {
                cursor: not-allowed;
                color: $color-mischka;
                border-color: $color-mischka;
            }
        }

        &__link {
            margin-top: 20px;
            @include global-typography($color-dodger-blue, bold, 14px);
        }
    }

}