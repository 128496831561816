@import "../../../../shared/styles/styles.scss";

.utilities {

    &__content-loading-view {
        width: 100%;
        height: 100%;

        &__text {
            margin-top: 10px;
            @include global-typography($color-dodger-blue, bold, 16px);
        }
    }

    &__container {
        width: 100%;
        height: 100%;
        position: relative;
    }

    &__spinner {
        z-index: 2;
        width: 100%;
        height: 100%;
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;

        &__container {
            z-index: 1;
            width: 100%;
            height: 100%;
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;
            backdrop-filter: blur(10px);
            background-color: rgba(57, 57, 57, 0.712);
        }
    }

}
