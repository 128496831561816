
@import "../../../../shared/styles/styles.scss";

.credits-or-we-owe-treasury {

    &__container {
        width: 98%;
        margin-top: 20px;
        padding: 30px 20px;
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);
    }

    &__table {
        width: 100%;
        border-radius: 5px;
        padding: 0 4px 4px 4px;
        background: $color-athens-gray;
        margin-bottom: 20px;

        &__title {
            @include global-typography($color-astronaut, bold, 18px);

            &__content {
                width: 100%;
                height: 50px;
                padding: 0 6px 0 16px
            }
        }

        &__icon {
            width: 20px;
            height: 20px;
            transition: transform 0.5s ease;

            &.rotate{
                transform: rotate(180deg);
                transition: transform 0.5s ease;
            }
        }

        &__box {
            width: 100%;
            border-radius: 5px;
            background: $color-white;
            padding: 20px 15px 10px 15px;
            max-height: 100vh;
            overflow: scroll;
            transition: max-height 0.5s ease;

            &.collapsed{
                max-height: 0;
                padding: 1px 15px 1px 15px;
                transition: max-height 0.5s ease;
            }
        }

        &__subtitle {
            @include global-typography($color-astronaut, bold, 12px);

            &__content {
                width: 100%;
                height: 30px;
            }
            &__not-reported{
                @include global-typography($color-astronaut, bold, 12px);
                width: 33%;
            }
        }

        &__add {

            &__content {
                width: 100%;
                height: 38px;
            }

            &__input {
                width: 32%;

                &.ant-input-affix-wrapper-disabled {
                    height: 30px;
                    padding: 4px;
                    border: none;
                    font-size: 10px;
                    box-shadow: none;
                    border-radius: 5px;
                    background: $color-athens-gray;

                    &>.ant-input {
                        margin: 0;
                        height: 20px;
                        line-height: 0;
                        box-shadow: none;
                        @include global-typography($color-astronaut, bold, 10px);
                    }
                }
            }

            &__inputs-price {
                width: 49%;
                position: relative;

                .input-price {
                    width: 100%;
                    margin: 0px;
                    height: 30px;
                    box-shadow: none;
                    text-align: right;
                    border-radius: 5px;
                    padding-left: 36px;
                    background: $color-white;
                    border: 1px dashed $color-mercury;
                    @include global-typography($color-astronaut, bold, 12px);

                    &::placeholder {
                        @include global-typography($color-mercury, bold, 12px);
                    }
                }

                &__disabled {
                    width: 32%;

                    .input-price {
                        width: 100%;
                        margin: 0px;
                        height: 30px;
                        border: none;
                        box-shadow: none;
                        text-align: right;
                        border-radius: 5px;
                        padding-left: 36px;
                        background: $color-athens-gray;
                        @include global-typography($color-astronaut, bold, 12px);

                    }
                }
            }
        }

        &__total {

            &__content {
                width: 100%;
                background: $color-white;
                padding: 20px 15px;
            }

            &__text {
                @include global-typography($color-astronaut, bold, 14px);

                &__content {
                    border-top: 2px solid $color-athens-gray;
                    width: 50%;
                    padding-top: 20px;
                    height: 40px;
                }
            }

            &__content-value {
                padding-left: 15px;
                border-top: 2px solid #F3F4F6;
                width: 50%;
                padding-top: 15px;
                height: 40px;

                .input-price {
                    &:disabled {
                        width: 100%;
                        margin: 0px;
                        height: 30px;
                        border: none;
                        box-shadow: none;
                        text-align: right;
                        border-radius: 5px;
                        padding-left: 36px;
                        background: $color-athens-gray;
                        @include global-typography($color-astronaut, bold, 12px);

                        &::placeholder {
                            @include global-typography($color-astronaut, bold, 12px);
                        }
                    }
                }
            }
        }
    }

}