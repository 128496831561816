@import "../../../../shared/styles/styles.scss";

.receipts {

    &__subtitle {
        @include global-typography($color-astronaut, bold, 12px);
    }

    &__content-options {
        width: 100%;
        height: 60px;
        margin-top: 14px;

        &__content-buttons {
            width: 510px;
            height: 100%;
            padding: 0 30px;
            border-radius: 5px;
            background: $color-athens-gray;

        }

        &__buttons {
            margin: 0;
            width: 145px;
            height: 40px;

            &__active {
                border-radius: 5px;
                background: $color-white;
                @include global-box-shadow(0px, 3px, 20px);
                @extend .receipts__content-options__buttons;
            }

            &__text {

                &__active {
                    @include global-typography($color-astronaut, bold, 12px);
                }

                &__inactive {
                    @include global-typography($color-mischka, bold, 12px);
                }

                &__hover {
                    @include global-typography($color-dodger-blue-hover, bold, 12px);
                }
            }
        }

        &__icon-button {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }

    }

    &__summary {

        &__box {
            @include global-box-shadow(0px, 3px, 20px);
            background: $color-white;
            padding: 10px;
            border-radius: 5px;
            width: 95%;
        }

        &__details {

            &--mod {
                width: 150px;
            }
        }

        &__view-cash-closing {
            @include global-typography($color-dodger-blue, bold, 14px);
        }

        &__line {
            height: 1px;
            width: 100%;
            background: $color-mischka;

            &--mod {
                margin: 10px 0;
            }
        }

        &__content {
            overflow-y: scroll;
            max-height: 285px;

            &::-webkit-scrollbar {
                width: 0px;
            }
        }
    }

    &__payments {

        &__container {
            padding-top: 10px;
        }

        &__input {
            width: 100%;

            &.ant-input-affix-wrapper {
                height: 30px;
                border: 1px solid $color-astronaut;
                background: $color-white;
                font-size: 10px;
                padding: 4px;

                &>.ant-input {
                    height: 20px;
                    margin: 0;
                    line-height: 0;
                    box-shadow: none;

                    &::placeholder {
                        @include global-typography($color-astronaut, bold, 10px);
                    }
                }
            }

            &.ant-input-affix-wrapper-disabled {

                height: 30px;
                border: 1px dashed $color-mercury;
                background: $color-white;

                &>.ant-input {
                    height: 20px;
                    margin: 0;
                    line-height: 0;
                    box-shadow: none;
                    @include global-typography($color-mercury, bold, 10px);

                    &::placeholder {
                        color: $color-mercury
                    }
                }

                &:hover {
                    background: $color-white;
                }
            }
        }


        &__select {
            &.ant-select {
                width: 100%;
                height: 30px;

                &>.ant-select-selector {
                    height: 30px;
                    background: $color-white;
                    border: 1px solid $color-astronaut;

                    & .ant-select-selection-item {
                        @include global-typography($color-astronaut, bold, 10px);
                    }

                    &>.ant-select-selection-placeholder {
                        @include global-typography($color-astronaut, bold, 10px);
                    }
                }

                &>.ant-select-arrow {
                    inset-inline-end: 0px;
                }

                &.ant-select-disabled {

                    &>.ant-select-selector {
                        height: 30px;
                        background: $color-white;
                        border: 1px dashed $color-mercury;

                        &>.ant-select-selection-item {
                            @include global-typography($color-mercury, bold, 10px);
                        }

                        &>.ant-select-selection-placeholder {
                            @include global-typography($color-mercury, bold, 10px);
                        }
                    }

                    &>.ant-select-arrow {
                        inset-inline-end: 0px;
                    }
                }

            }

            &--secondary {
                &.ant-select {
                    width: 100%;
                    height: 30px;

                    &>.ant-select-selector {
                        height: 30px;
                        background: $color-white;
                        border: 1px solid $color-astronaut;

                        & .ant-select-selection-item {
                            @include global-typography($color-astronaut, bold, 10px);
                        }

                        &>.ant-select-selection-placeholder {
                            @include global-typography($color-astronaut, bold, 10px);
                        }
                    }

                    &>.ant-select-arrow {
                        inset-inline-end: 0px;
                    }

                    &.ant-select-disabled {

                        &>.ant-select-selector {
                            height: 30px;
                            background: $color-white;
                            border: 1px dashed $color-mercury;

                            &>.ant-select-selection-item {
                                @include global-typography($color-mercury, bold, 10px);
                            }

                            &>.ant-select-selection-placeholder {
                                @include global-typography($color-mercury, bold, 10px);
                            }
                        }

                        &>.ant-select-arrow {
                            inset-inline-end: 0px;
                        }
                    }

                }
            }

            &__icon {
                width: 30px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;

                &__active {
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
            }

            &__icon-option-render {
                width: 20px;
                height: 20px;
                margin-right: 4px;
            }

            &__popup {
                .ant-select-item-option-content {
                    font-size: 10px;
                    font-weight: bold;
                    color: $color-astronaut;
                }
            }
        }

        &__content-input-price {
            width: 100%;
            position: relative;


            .input-price {
                width: 100%;
                margin: 0px;
                height: 30px;
                box-shadow: none;
                text-align: right;
                padding-left: 36px;
                border-radius: 6px;
                background: $color-athens-gray;
                border: 1px solid $color-athens-gray;
                @include global-typography($color-astronaut, bold, 11px);

                &:focus {
                    box-shadow: none;
                    background: $color-athens-gray;
                    border: 1px solid $color-athens-gray;
                }

                &::placeholder {
                    color: $color-astronaut;
                }

                &:disabled {
                    width: 80%;
                    margin: 0px;
                    height: 30px;
                    box-shadow: none;
                    text-align: right;
                    padding-left: 36px;
                    border-radius: 6px;
                    background: $color-white;
                    border: 1px solid $color-astronaut;
                    @include global-typography($color-astronaut, bold, 11px);

                    &:disabled {
                        border: 1px dashed $color-mercury;

                        &::placeholder {
                            color: $color-mercury;
                        }
                    }
                }
            }

            &.ant-input-wrapper {
                height: 30px;
                font-size: 10px;
                padding: 4px;
                background: $color-white;
                border: 1px solid $color-astronaut;

                &>.ant-input {
                    height: 20px;
                    margin: 0;
                    line-height: 0;
                    box-shadow: none;
                    @include global-typography($color-astronaut, bold, 10px);

                    &::placeholder {
                        color: $color-astronaut;
                    }
                }
            }
        }

        &__content-receipts {
            width: 100%;
            position: relative;

            .input-price {
                width: 95%;
                margin: 0px;
                height: 30px;
                box-shadow: none;
                text-align: right;
                padding-left: 36px;
                border-radius: 6px;
                background: $color-white;
                border: 1px solid $color-astronaut;
                @include global-typography($color-astronaut, bold, 11px);

                &:focus {
                    box-shadow: none;
                }

                &::placeholder {
                    color: $color-astronaut;
                }

                &:disabled {
                    width: 95%;
                    margin: 0px;
                    height: 30px;
                    box-shadow: none;
                    text-align: right;
                    padding-left: 36px;
                    border-radius: 6px;
                    border: 1px solid $color-athens-gray;
                    @include global-typography($color-astronaut, bold, 11px);

                    &:disabled {
                        border: 1px dashed $color-mercury;

                        &::placeholder {
                            color: $color-mercury;
                        }
                    }
                }
            }

            &.ant-input-wrapper {
                height: 30px;
                font-size: 10px;
                padding: 4px;
                background: $color-white;
                border: 1px solid $color-astronaut;

                &>.ant-input {
                    height: 20px;
                    margin: 0;
                    line-height: 0;
                    box-shadow: none;
                    @include global-typography($color-astronaut, bold, 10px);

                    &::placeholder {
                        color: $color-astronaut;
                    }
                }
            }

            &--mod{

                .input-price {
    
                    &:disabled {
                        width: 95%;
                        margin: 0px;
                        height: 30px;
                        box-shadow: none;
                        text-align: right;
                        padding-left: 36px;
                        border-radius: 6px;
                        background: $color-athens-gray;
                        border: none;
                        @include global-typography($color-astronaut, bold, 11px);
    
                        &:disabled {
                            border: none;
    
                            &::placeholder {
                                color: $color-mercury;
                            }
                        }
                    }
                }

            }
        }

        &__content-icon-price {
            width: 30px;
            height: 30px;
            display: flex;
            position: absolute;
            align-items: center;
            justify-content: center;

        }

        &__icon {
            width: 20px;
            height: 20px;
        }
    }

    &__button-income {
        @include global-typography($color-surfie-green, bold, 14px);
        margin-left: 10px;
    }

    &__button-delete {
        margin: 0;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        background: $color-alabaster;
        border: 1px solid $color-astronaut;

        &__icon {
            width: 20px;
            height: 20px;
        }

        &:disabled {
            border: none;
            cursor: not-allowed;
        }
    }
}