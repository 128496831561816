@import '../../../../shared/styles/styles.scss';

.tables-of-records {

    &__box {
        width: 98%;
        padding: 30px 20px;
        margin-bottom: 20px;
        border-radius: 10px;
        background: $color-white;
        @include global-box-shadow(0px, 3px, 20px);

        &__icon {

            &__red {
                margin-right: 5px;
                transform: rotate(180deg);
            }

            &__blue {
                margin-right: 5px;
                transform: rotate(270deg);
            }
        }

        &__title {
            @include global-typography($color-astronaut, bold, 14px);
        }

        &__input-total {
            height: 30px;
            width: 150px;
            margin-left: 10px;
            position: relative;

            .input-price {
                width: 100%;
                margin: 0px;
                height: 100%;
                cursor: auto;
                border: none;
                box-shadow: none;
                text-align: right;
                padding-left: 36px;
                border-radius: 6px;
                background: $color-athens-gray;
                @include global-typography($color-astronaut, bold, 14px);

                &::placeholder {
                    color: $color-astronaut;
                }
            }

            &__icon-content {
                width: 30px;
                height: 30px;
                display: flex;
                position: absolute;
                align-items: center;
                justify-content: center;
            }

            &__icon {

                &__red {
                    width: 80%;
                    height: 80%;
                    transform: rotate(180deg);
                }
    
                &__blue {
                    width: 80%;
                    height: 80%;
                    transform: rotate(270deg);
                }
            }
        }

        &__content-subtitles {
            width: 100%;
            margin: 20px 0px 10px 0px;
        }

        &__subtitle {
            @include global-typography($color-astronaut, bold, 12px);

            &__container {
                width: 31%;
            }

            &__content {
                width: calc(93% / 4);
            }
        }

        &__info {
            height: 300px;
            overflow-y: scroll;
            border-bottom: 1px solid $color-mercury;

            &::-webkit-scrollbar {
                width: 0px;
            }
        }

        &__content-label {
            width: 100%;
            height: 38px;
        }

        &__container-inputs {

            &__input {
                width: 31%;
                height: 100%;
            }

            &__content {
                width: calc(93% / 4);
                height: 100%;
            }

            &__deleted {
                width: 7%;
                height: 100%;
            }
        }

        &__input {

            &__datePicker {
                margin: 0;
                padding: 0;
                width: 94%;
                height: 30px;
                box-shadow: none;
                background: $white;
                border-radius: 5px;
                border: 1px solid $color-astronaut;

                &>.ant-picker-input {
                    &>input {
                        height: 20px;
                        cursor: pointer;
                        padding: 0 10px;
                        @include global-typography($color-astronaut, bold, 12px);

                        &::placeholder {
                            @include global-typography($color-astronaut, bold, 12px);
                        }
                    }
                }

                &.ant-picker-focused {
                    border: 1px solid $color-astronaut;
                }

                &:hover {
                    border: 1px solid $color-astronaut;
                }

                &.ant-picker-disabled {
                    background: $white;
                    border: 1px dashed $color-mercury;

                    &>.ant-picker-input {

                        &>input {
                            &::placeholder {
                                @include global-typography($color-mercury, bold, 12px);
                            }
                        }
                    }
                }

                &.ant-picker-outlined.ant-picker-disabled:hover {
                    background: $white;
                }
            }

            &__concept {
                width: 94%;

                &.ant-input-affix-wrapper {
                    height: 30px;
                    padding: 4px;
                    font-size: 10px;
                    box-shadow: none;
                    background: $color-white;
                    border: 1px solid $color-astronaut;

                    &>.ant-input {
                        margin: 0;
                        height: 20px;
                        line-height: 0;
                        box-shadow: none;
                        @include global-typography($color-astronaut, bold, 12px);

                        &::placeholder {
                            @include global-typography($color-astronaut, bold, 12px);
                        }
                    }
                }

                &.ant-input-affix-wrapper-disabled {
                    height: 30px;
                    background: $color-white;
                    border: 1px dashed $color-mercury;

                    &>.ant-input {
                        margin: 0;
                        height: 20px;
                        line-height: 0;
                        box-shadow: none;
                        @include global-typography($color-mercury, bold, 12px);

                        &::placeholder {
                            @include global-typography($color-mercury, bold, 12px);
                        }
                    }

                    &.ant-input-outlined.ant-input-disabled:hover {
                        background: $color-white;
                    }
                }
            }

            &__price {
                width: 100%;
                height: 30px;
                position: relative;

                .input-price {
                    width: 100%;
                    margin: 0px;
                    height: 100%;
                    box-shadow: none;
                    text-align: right;
                    padding-left: 36px;
                    border-radius: 6px;
                    background: $color-white;
                    border: 1px solid $color-astronaut;
                    @include global-typography($color-astronaut, bold, 12px);

                    &:focus {
                        box-shadow: none;
                        background: $color-white;
                        border: 1px solid $color-astronaut;
                    }

                    &::placeholder {
                        color: $color-astronaut;
                        @include global-typography($color-astronaut, bold, 12px);
                    }

                    &:disabled {
                        background: $color-white;
                        border: 1px dashed $color-mercury;
                        @include global-typography($color-mercury, bold, 12px);

                        &::placeholder {
                            @include global-typography($color-mercury, bold, 12px);
                        }
                    }
                }

                &__icon-price {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    position: absolute;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        &__button-delete {
            margin: 0;
            width: 30px;
            height: 30px;
            border-radius: 5px;
            background: $color-athens-gray;
            border: 1px solid $color-astronaut;

            &__icon {
                width: 20px;
                height: 20px;
            }

            &:disabled {
                border: none;
                cursor: not-allowed;
            }
        }

        &__button-add {
            height: 40px;
            margin-top: 10px;

            &__icon {

                &__red {
                    transform: rotate(180deg);
                }

                &__blue {
                    transform: rotate(270deg);
                }
            }

            &__text {

                &__red {
                    margin-left: 6px;
                    @include global-typography($color-carnation, bold, 14px);
                }

                &__blue {
                    margin-left: 6px;
                    @include global-typography($color-dodger-blue, bold, 14px);
                }
            }
        }
    }
}